/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from "yup";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import { siteLocationService } from "../../_services/admin";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class CreateSiteLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      siteLocation: {},
      loading: true,
    };
  }

  componentDidMount() {
    this.props.setUserData(this.state.currentUser);
    this.props.match.params.id &&
      siteLocationService
        .getSiteLocationById(this.props.match.params.id)
        .then((data) => {
          this.setState({ siteLocation: data.Data, loading: false });
        });
    this.setState({ loading: false });
  }

  render() {
    const { siteLocation } = this.state;

    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <SidebarSuperAdmin />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">
                  {!siteLocation._id
                    ? "Create Site Location"
                    : "Edit Site Location"}
                </h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Create Site Location
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="pt-4" />
                <div className="card">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      siteLocationId: siteLocation._id || null,
                      name: siteLocation.name || "",
                      currentUserId: siteLocation.id,
                      isActive: siteLocation.isActive || "",
                      // staffsForReferal:this.state.department?this.state.department.staffsForReferal : []
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required("Site Name is required"),
                    })}
                    onSubmit={(formData, { setStatus, setSubmitting }) => {
                      setStatus();
                      siteLocationService.create(formData).then(
                        () => {
                          const { from } = this.props.location.state || {
                            from: { pathname: "/admin/site-locations" },
                          };
                          this.props.history.push(from);
                          swal(
                            "Site location Added Succesfully!",
                            "",
                            "success",
                          );
                        },
                        (error) => {
                          setSubmitting(false);
                          setStatus(error);
                        },
                      );
                    }}
                    render={({ errors, status, touched, isSubmitting }) => (
                      <Form>
                        <div className="card-body">
                          <div className="row form-material">
                            <div className="col-md-12">
                              <div className="form-group required">
                                <label className="control-label">
                                  Site Location Name
                                </label>
                                <Field
                                  name="name"
                                  type="text"
                                  placeholder="Site Location Name"
                                  className={`form-control${
                                    errors.name && touched.name
                                      ? " is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer accordionfooter d-flex">
                          <div style={{ marginRight: "5px" }}>
                            <Link
                              to="/admin/site-locations"
                              className="btn btn-secondary"
                              style={{
                                backgroundColor: "grey",
                                color: "white",
                              }}
                            >
                              {/* <i className="mdi mdi-chevron-left"></i> */}
                              <span className="">Cancel</span>
                            </Link>
                          </div>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary"
                          >
                            {" "}
                            {!siteLocation._id
                              ? "Save Site Location"
                              : "Update Site Location"}{" "}
                          </button>

                          {isSubmitting && (
                            <img
                              alt=""
                              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                            />
                          )}
                        </div>
                        {status && (
                          <div className="alert alert-danger">{status}</div>
                        )}
                      </Form>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSiteLocation);
