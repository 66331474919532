/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact";
import Loader from "react-loader-spinner";
import moment from "moment";
import swal from "sweetalert";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { setUserData, setLoader } from "../../redux-store/action";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import { Role } from "../../_helpers";
import {
  contentLibraryService,
  userCourseReportService,
} from "../../_services/staff";
import { CSVLink } from "react-csv";
import {
  courseStatusArray,
  courseStatusShown,
  courseTypesArray,
  courseTypesShown,
  organizationNames,
} from "../constants/constant";
import { Field } from "formik";
import { FormGroup, Input, Label } from "reactstrap";
import { getTimeStringBySec } from "../../_helpers/_helperFunctions";

function mapStateToProps(state) {
  return {
    isLoading: state.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

const formatDataForCSV = (data) => {
  const csvData = data.map((user) => {
    const {
      email,
      firstName,
      lastName,
      organization,
      name,
      courseType,
      courseName,
      categoryName,
      status,
      key,
      duration,
    } = user;

    return {
      No: key,
      Organization: organization,
      "First Name": firstName,
      "Last Name": lastName,
      Email: email,
      "Category Name": categoryName,
      "Module Name": courseName,
      "Lesson Name": name,
      "Lesson Type": courseType,
      "Time Spent": duration,
      "Completion Status": status,
    };
  });
  return csvData;
};

class UserCourseReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      users: [],
      loading: true,
      departments: [],
      subDepartments: [],
      selectedUsersObject: {},
      selectedOrganization: "",
      selectedStatus: "",
      selectedCourseType: "",
      selectedCourseId: "",
      courseArray: [],
      csvData: [],
      columns: [
        {
          label: "#",
          field: "key",
          sort: "asc",
          width: 10,
        },
        {
          label: "Organization",
          field: "organization",
          sort: "asc",
          width: 270,
        },
        {
          label: "First Name",
          field: "firstName",
          sort: "asc",
          width: 150,
        },
        {
          label: "Last Name",
          field: "lastName",
          sort: "asc",
          width: 270,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Category Name",
          field: "categoryName",
          sort: "asc",
          width: 270,
        },
        {
          label: "Module Name",
          field: "courseName",
          sort: "asc",
          width: 270,
        },
        {
          label: "Lesson Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Lesson Type",
          field: "courseType",
          sort: "asc",
          width: 270,
        },
        {
          label: "Time Spent",
          field: "duration",
          sort: "asc",
          width: 270,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 270,
        },
      ],
    };
  }

  componentDidMount() {
    this.getAllUsers();
    this.getAllCourses();
  }

  getAllCourses() {
    this.props.setLoader(true);
    contentLibraryService
      .getAllCourses()
      .then((data) => {
        if (data?.Status) {
          const courseArray = data?.Data?.map((course) => ({
            name: course?.name,
            value: course?._id,
          }));
          this.setState({ courseArray });
        }
        this.props.setLoader(false);
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.props.setLoader(false);
        console.log("error getAllCourses: ", error);
        swal(error || "Something went wrong", "", "error");
      });
  }

  getAllUsers(status = "", organization = "", courseType = "", courseId = "") {
    this.props.setLoader(true);
    userCourseReportService
      .getAllData(status, organization, courseType, courseId)
      .then((data) => {
        const newData = data?.Data?.map((userReport) => {
          const { statuses, user, userId } = userReport;
          return { ...statuses, ...user, userId };
        });

        const newDataArray = [];
        let i = 1;

        newData?.forEach((obj) => {
          const { TODO, INPROGRESS, COMPLETED, ...rest } = obj;

          if (TODO) {
            TODO?.forEach((object) => {
              if (Object.keys(object).length > 0) {
                newDataArray.push({
                  ...rest,
                  ...object,
                  status: courseStatusShown.TODO,
                  courseType: courseTypesShown[object?.courseType],
                  key: i,
                  duration: getTimeStringBySec(object?.duration || 0),
                });
                i += 1;
              }
            });
          }
          if (INPROGRESS) {
            INPROGRESS?.forEach((object) => {
              if (Object.keys(object).length > 0) {
                newDataArray.push({
                  ...rest,
                  ...object,
                  status: courseStatusShown.INPROGRESS,
                  courseType: courseTypesShown[object?.courseType],
                  key: i,
                  duration: getTimeStringBySec(object?.duration || 0),
                });
                i += 1;
              }
            });
          }
          if (COMPLETED) {
            COMPLETED?.forEach((object) => {
              if (Object.keys(object).length > 0) {
                newDataArray.push({
                  ...rest,
                  ...object,
                  status: courseStatusShown.COMPLETED,
                  courseType: courseTypesShown[object?.courseType],
                  key: i,
                  duration: getTimeStringBySec(object?.duration || 0),
                });
                i += 1;
              }
            });
          }
        });

        const tableData = {
          columns: this.state.columns,
          rows: newDataArray,
        };
        this.setState({
          users: tableData,
          loading: false,
          csvData: formatDataForCSV(newDataArray),
        });
        this.props.setLoader(false);
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.props.setLoader(false);
        console.log("error: ", error);
        swal(error || "Something went wrong", "", "error");
      });
  }

  render() {
    const { users, courseArray } = this.state;
    const { isLoading } = this.props;

    return (
      <>
        {isLoading && (
          <div id="loaderRoot">
            <div className="loaderPositionsStyle">
              <div className="loaderPositionsStyleSub">
                <Loader
                  type="Rings"
                  sty
                  color="#6f42c1"
                  height={80}
                  width={80}
                />
              </div>
            </div>
          </div>
        )}
        {!isLoading && (
          <div
            id="main-wrapper"
            className="fix-header fix-sidebar card-no-border"
          >
            {this.state.loading && <Preloader />}
            <HeaderAdmin />
            {this.state.currentUser.role === Role.Admin ? (
              <SidebarSuperAdmin />
            ) : (
              <Sidebaar />
            )}
            <div className="page-wrapper">
              <div className="container-fluid">
                <div className="row page-titles">
                  <div className="col-md-5 col-8 align-self-center">
                    <h3 className="text-themecolor m-b-0 m-t-0">
                      User Course Report
                    </h3>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li className="breadcrumb-item active"> Report</li>
                    </ol>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="pt-4">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-row w-75">
                              <i
                                style={{ fontSize: "1.6rem" }}
                                className="mdi mdi-filter-variant mr-2"
                              ></i>
                              <FormGroup className="w-25">
                                <Input
                                  type="select"
                                  name="select"
                                  value={this.state.selectedOrganization}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    this.setState({
                                      selectedOrganization: selectedValue,
                                    });

                                    this.getAllUsers(
                                      this.state.selectedStatus,
                                      selectedValue,
                                      this.state.selectedCourseType,
                                      this.state.selectedCourseId,
                                    );
                                  }}
                                >
                                  <option value="">Select Organization</option>
                                  {organizationNames.map((organization) => (
                                    <option value={organization.value}>
                                      {organization.name}{" "}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                              <FormGroup className="ml-3 w-25">
                                <Input
                                  type="select"
                                  name="select"
                                  value={this.state.selectedStatus}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    this.setState({
                                      selectedStatus: selectedValue,
                                    });

                                    this.getAllUsers(
                                      selectedValue,
                                      this.state.selectedOrganization,
                                      this.state.selectedCourseType,
                                      this.state.selectedCourseId,
                                    );
                                  }}
                                >
                                  <option value="">Select Status</option>
                                  {courseStatusArray.map((courseStatus) => (
                                    <option value={courseStatus.value}>
                                      {courseStatus.name}{" "}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                              <FormGroup className="ml-3 w-25">
                                <Input
                                  type="select"
                                  name="select"
                                  value={this.state.selectedCourseType}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    this.setState({
                                      selectedCourseType: selectedValue,
                                    });

                                    this.getAllUsers(
                                      this.state.selectedStatus,
                                      this.state.selectedOrganization,
                                      selectedValue,
                                      this.state.selectedCourseId,
                                    );
                                  }}
                                >
                                  <option value="">Select Lesson Type</option>
                                  {courseTypesArray.map((courseType) => (
                                    <option value={courseType.value}>
                                      {courseType.name}{" "}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                              <FormGroup className="ml-3 w-25">
                                <Input
                                  type="select"
                                  name="select"
                                  value={this.state.selectedCourseId}
                                  onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    this.setState({
                                      selectedCourseId: selectedValue,
                                    });

                                    this.getAllUsers(
                                      this.state.selectedStatus,
                                      this.state.selectedOrganization,
                                      this.state.selectedCourseType,
                                      selectedValue,
                                    );
                                  }}
                                >
                                  <option value="">Select Course</option>
                                  {courseArray.map((course) => (
                                    <option value={course.value}>
                                      {course.name}{" "}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                              {/* <div className="w-25"> */}
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light py-1 mr-2 mb-4 ml-3 pr-4"
                                onClick={() => {
                                  this.setState({
                                    selectedCourseType: "",
                                    selectedStatus: "",
                                    selectedOrganization: "",
                                    selectedCourseId: "",
                                  });

                                  this.getAllUsers();
                                }}
                              >
                                Clear Filter
                              </button>
                            </div>
                            <CSVLink
                              data={this.state.csvData}
                              filename={"Report.csv"}
                            >
                              Export to CSV
                            </CSVLink>
                          </div>
                          <MDBDataTable
                            responsive
                            exportToCSV={true}
                            striped
                            bordered
                            hover
                            data={users}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterAdmin />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCourseReport);
