/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact";
import Loader from "react-loader-spinner";
import moment from "moment";
import swal from "sweetalert";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { setUserData, setLoader } from "../../redux-store/action";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import { Role } from "../../_helpers";

function mapStateToProps(state) {
  return {
    isLoading: state.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class RegisteredUsers extends Component {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      users: [],
      loading: true,
      departments: [],
      subDepartments: [],
      selectedUsersObject: {},
      columns: [
        {
          label: (
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                id="master_checkbox"
                onClick={(event) => {
                  this.handleSelectAllUsers(event.target.checked);
                }}
              />
              <label
                className="form-check-label"
                htmlFor="master_checkbox"
              ></label>
            </div>
          ),
          field: "checkbox",
          sort: "disabled",
          width: 5,
        },
        {
          label: "#",
          field: "key",
          sort: "asc",
          width: 10,
        },
        {
          label: "First Name",
          field: "firstName",
          sort: "asc",
          width: 150,
        },
        {
          label: "Last Name",
          field: "lastName",
          sort: "asc",
          width: 270,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Organization",
          field: "organization",
          sort: "asc",
          width: 270,
        },
        {
          label: "Department",
          field: "department",
          sort: "asc",
          width: 270,
        },
        {
          label: "Job Title",
          field: "jobTitle",
          sort: "asc",
          width: 270,
        },
        {
          label: "Created At",
          field: "createdAt",
          sort: "asc",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 270,
        },
        {
          label: "Activate Course",
          field: "activeCourse",
          sort: "disabled",
          width: 270,
        },
      ],
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.activateAllUser = this.activateAllUser.bind(this);
    this.handleSelectAllUsers = this.handleSelectAllUsers.bind(this);
  }

  componentDidMount() {
    this.getAllUsers();
  }

  updateCourseActive(ids, isActive) {
    if (ids?.length === 0) {
      swal("Please select at least one User", "", "error");
      return;
    }
    this.setState({
      loading: true,
    });
    const payload = {
      userIds: ids,
      isActive,
    };
    userService
      .activeDeactiveCourseByUserId(payload)
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.getAllUsers();
        swal(
          `${ids?.length > 1 ? "Courses" : "Course"} ${
            isActive ? "Activated" : "Deactivated"
          } Successfully`,
          "",
          "success",
        );
      })
      .catch((error) => {
        swal("Something went wrong", "", "error");
        this.setState({
          loading: false,
        });
      });
  }

  handleCheckboxChange = (userId, isChecked) => {
    this.setState((prevState) => ({
      selectedUsersObject: {
        ...prevState.selectedUsersObject,
        [userId]: isChecked,
      },
    }));
  };

  activateAllUser = () => {
    const selectedUserIds = Object.keys(this.state.selectedUsersObject).filter(
      (key) => this.state.selectedUsersObject[key],
    );

    this.updateCourseActive(selectedUserIds, true);
  };

  deactivateAllUser = () => {
    const selectedUserIds = Object.keys(this.state.selectedUsersObject).filter(
      (key) => this.state.selectedUsersObject[key],
    );

    this.updateCourseActive(selectedUserIds, false);
  };

  handleSelectAllUsers = (check) => {
    this.setState({
      selectedUsersObject: Object.keys(this.state.selectedUsersObject).reduce(
        (obj, key) => {
          return { ...obj, [key]: check };
        },
        {},
      ),
    });
  };

  getAllUsers() {
    this.props.setLoader(true);
    userService
      .getAllUserAndStaffList()
      .then((data) => {
        const rowsArray = [];
        const usersObj = data?.Data?.reduce((obj, user) => {
          return { ...obj, [user?._id]: false };
        }, {});

        this.setState({ selectedUsersObject: usersObj });

        data?.Data?.map((user, i) => {
          rowsArray.push({
            id: user?._id,
            checkbox: (
              <div>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`row_${user?._id}`}
                  onClick={(event) =>
                    this.handleCheckboxChange(user._id, event.target.checked)
                  }
                  defaultValue={this.state.selectedUsersObject[user?._id]}
                  value={this.state.selectedUsersObject[user?._id]}
                />
                <label
                  className="form-check-label"
                  htmlFor={`row_${user?._id}`}
                ></label>
              </div>
            ),
            key: i + 1,
            firstName: user?.firstName || "",
            role:
              user.role === 1
                ? "Admin"
                : user.role === 2
                ? "Staff"
                : "Customer",
            lastName: user?.lastName || "",
            email: user?.email || "",
            gender: user?.gender || "",
            organization: user?.organization || "",
            department: user?.department || "",
            jobTitle: user?.jobTitle || "",
            createdAt: moment(user?.createdAt, "x").format("MM/DD/YYYY"),
            action: (
              <>
                <a
                  onClick={() => document.getElementById(user._id).click()}
                  data-toggle="tooltip"
                  data-original-title="Delete"
                >
                  {" "}
                  <i className="fas fa-trash text-danger" />{" "}
                </a>
                <DeleteConfirmation
                  id={user._id}
                  confirm={() => this.delete(user._id)}
                />
              </>
            ),
            activeCourse: (
              <>
                <div className="switch toggleCourse">
                  <label>
                    <input
                      name="test"
                      type="checkbox"
                      onClick={(e) => {
                        this.updateCourseActive([user?._id], e.target.checked);
                      }}
                      defaultChecked={user?.enableCourses?.length > 0}
                    />
                    <span
                      className="lever switch-col-purple"
                      style={{ width: "25px", height: "8px" }}
                    ></span>
                  </label>
                </div>
              </>
            ),
          });

          return true;
        });
        const tableData = {
          columns: this.state.columns,
          rows: rowsArray,
        };
        this.setState({ users: tableData, loading: false });
        this.props.setLoader(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        swal(error || "Something went wrong", "", "error");
      });
  }

  delete(userId) {
    userService
      .deleteRegisteredUser(userId, this.state.currentUser.id)
      .then((response) => {
        response.Status && this.getAllUsers();
        swal("User Deleted Succesfully!", "", "success");
      })
      .catch((err) => {
        console.log(err);
        swal("Opps Something Went Wrong!", "", "error");
      });
  }

  render() {
    const { users } = this.state;
    const { isLoading } = this.props;

    return (
      <>
        {isLoading && (
          <div id="loaderRoot">
            <div className="loaderPositionsStyle">
              <div className="loaderPositionsStyleSub">
                <Loader
                  type="Rings"
                  sty
                  color="#6f42c1"
                  height={80}
                  width={80}
                />
              </div>
            </div>
          </div>
        )}
        {!isLoading && (
          <div
            id="main-wrapper"
            className="fix-header fix-sidebar card-no-border"
          >
            {this.state.loading && <Preloader />}
            <HeaderAdmin />
            {this.state.currentUser.role === Role.Admin ? (
              <SidebarSuperAdmin />
            ) : (
              <Sidebaar />
            )}
            <div className="page-wrapper">
              <div className="container-fluid">
                <div className="row page-titles">
                  <div className="col-md-5 col-8 align-self-center">
                    <h3 className="text-themecolor m-b-0 m-t-0">
                      Registered Users
                    </h3>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Registered Users
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="pt-4">
                      <div className="card">
                        <div className="card-body">
                          {/* <div className="table-responsive"> */}
                          <div className="d-flex flew-row justify-content-end">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light py-1 mr-2"
                              onClick={this.activateAllUser}
                            >
                              Activate All
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light py-1"
                              onClick={this.deactivateAllUser}
                            >
                              Deactivate All
                            </button>
                          </div>
                          <MDBDataTable
                            responsive
                            striped
                            bordered
                            hover
                            data={users}
                          />
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterAdmin />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredUsers);
