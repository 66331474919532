/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-named-as-default */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Route } from "react-router-dom";
import "./App.css";
import jQuery from "jquery";
import IdleTimer from "react-idle-timer";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ContentLibrary from "./pages/user/ContentLibrary";
import CreateContentLibrary from "./pages/user/CreateContentLibrary";
import CategoryDetail from "./pages/user/CategoryDetail";
import ClientDashboard from "./pages/user/ClientDashboard";
import CounsellorCalender from "./pages/user/CounsellorCalender";
import changePassword from "./pages/user/changePassword";
import Availability from "./pages/user/Availability";
import UserAppointment from "./pages/user/UserAppointment";
import Workshops from "./pages/user/Workshops";
import Events from "./pages/user/Events";
import AddEvent from "./pages/user/AddEvent";
import AddWorkshop from "./pages/user/AddWorkshop";
import WorkshopHistory from "./pages/user/WorkshopHistory";
import CreateUser from "./pages/admin/CreateUser";
import Users from "./pages/admin/User";
import RegisteredUser from "./pages/admin/RegisteredUserList";
import UserAnalytics from "./pages/admin/UserAnalytics";
import ListofSuperAdmin from "./pages/admin/ListofSuperUser";

import { history, Role } from "./_helpers";
import { authenticationService } from "./_services";
import PrivateRoute from "./components/PrivateRoute";
import CreateSiteLocation from "./pages/admin/CreateSiteLocation";
import { updateAppointment } from "./redux-store/action";
import IdleTimeOutModal from "./components/IdleTimeOutModal";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import AdminDashboardLight from "./pages/admin/AdminDashboardLight";
import InitialRoute from "./components/InitialRoute";
import CreateQuestioneries from "./pages/user/CreateQuestioneries";
import CourseCategory from "./pages/user/CourseCategory";
import CreateCourseCategory from "./pages/user/CreateCourseCategory";
import UserCourseReport from "./pages/admin/UserCourseReport";

window.jQuery = jQuery;

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    updateAppointment: (data) => {
      dispatch(updateAppointment(data));
    },
  };
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      isAdmin: false,
      timeout: 1000 * 60 * 20,
      showModal: false,
      isTimedOut: false,
      isConnected: false,
    };
  }

  componentWillMount() {
    if (!localStorage.getItem("isenc")) {
      localStorage.setItem("isenc", "0");
    }
    function keyDownTextField(e) {
      if (e.ctrlKey && e.altKey && e.key === "e") {
        const encMode = localStorage.getItem("isenc")
          ? localStorage.getItem("isenc")
          : "0";
        if (encMode !== "1") {
          localStorage.setItem("isenc", "1");
          alert("Enc Mode On");
        } else {
          localStorage.setItem("isenc", "0");
          alert("Enc Mode Off");
        }
      }
    }
    document.addEventListener("keydown", keyDownTextField, false);

    if (!localStorage.getItem("pemPublic")) {
      fetch("./security/public_client.pem", { mode: "no-cors" })
        .then((response) => response.text())
        .then((data) => {
          fetch("./security/private_client.pem", { mode: "no-cors" })
            .then((response1) => response1.text())
            .then((data1) => {
              localStorage.setItem("pemPrivate", data1);
              localStorage.setItem("pemPublic", data);
            });
        })
        .catch((error) => console.error(error));
    }
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe((user) => {
      if (user && user.id) {
        this.setState({
          currentUser: user,
          isAdmin: user && user.role === Role.Admin,
        });
      }
    });

    const host = window.location.origin;

    const cssFiles = [];
    cssFiles.map((t) => {
      const link = document.createElement("link");
      link.href = host + t;
      link.rel = "stylesheet";
      document.head.appendChild(link);
      return true;
    });
    const scripts = [
      `${host}/assets/plugins/jquery/jquery.min.js`,
      // 'https://cdnjs.cloudflare.com/ajax/libs/jquery.mask/1.14.16/jquery.mask.min.js',
      "https://cdnjs.cloudflare.com/ajax/libs/jquery.inputmask/5.0.4-beta.33/bindings/inputmask.binding.min.js",
      `${host}/assets/plugins/popper/popper.min.js`,
      `${host}/assets/plugins/bootstrap/js/bootstrap.min.js`,
      `${host}/assets/js/jquery.slimscroll.js`,
      `${host}/assets/js/waves.js`,
      `${host}/assets/js/sidebarmenu.js`,
      `${host}/assets/plugins/sticky-kit-master/dist/sticky-kit.min.js`,
      `${host}/assets/plugins/sparkline/jquery.sparkline.min.js`,
      `${host}/assets/plugins/styleswitcher/jQuery.style.switcher.js`,
      `${host}/assets/plugins/bootstrap-datepicker/bootstrap-datepicker.min.js`,
      `${host}/assets/plugins/select2/dist/js/select2.full.min.js`,
      // host + "/assets/js/custom.min.js",
    ];

    scripts.map((t) => {
      const script = document.createElement("script");
      script.src = t;
      script.async = false;
      document.body.appendChild(script);
      return true;
    });
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleLogout = () => {
    this.setState({ showModal: false });
    authenticationService.logout();
    history.push("/login");
  };

  onAction = () => {
    this.setState({ isTimedOut: false });
  };

  onActive = () => {
    this.setState({ isTimedOut: false });
  };

  onIdle = () => {
    const { isTimedOut } = this.state;
    if (isTimedOut) {
      history.push("/login");
    } else if (history.location.pathname !== "/login") {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  };

  render() {
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />

        <Router history={history} onUpdate={(a) => console.log(a)}>
          {/* Common Routes */}
          <Route exact path="/" component={InitialRoute} />
          <Route path="/login" component={LoginPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/reset/:token" component={ResetPasswordPage} />
          {/* <Route path="/verification/:token" component={AccountVerification} /> */}

          {/* Staff's Routes */}
          <PrivateRoute
            exact
            path="/staff"
            roles={{ role: Role.Staff }}
            component={ClientDashboard}
          />
          <PrivateRoute
            exact
            path="/staff/course-categories"
            roles={{ role: Role.Staff }}
            component={CourseCategory}
          />
          <PrivateRoute
            path="/staff/course-category/create"
            roles={{ role: Role.Staff }}
            component={CreateCourseCategory}
          />
          <PrivateRoute
            path="/staff/course-category/edit/:courseCategoryId"
            roles={{ role: Role.Staff }}
            component={CreateCourseCategory}
          />
          <PrivateRoute
            exact
            path="/staff/course-categories/:courseCategoryId/create"
            roles={{ role: Role.Staff }}
            component={CreateContentLibrary}
          />
          <PrivateRoute
            exact
            path="/staff/course-categories/:courseCategoryId/edit/:id"
            roles={{ role: Role.Staff }}
            component={CreateContentLibrary}
          />
          <PrivateRoute
            exact
            path="/staff/course-categories/:courseCategoryId"
            roles={{ role: Role.Staff }}
            component={ContentLibrary}
          />
          <PrivateRoute
            exact
            path="/staff/course-categories/:courseCategoryId/category-detail/:id"
            roles={{ role: Role.Staff }}
            component={CategoryDetail}
          />
          <PrivateRoute
            exact
            path="/staff/course-categories/:courseCategoryId/category-detail/:id/questions/create"
            roles={{ role: Role.Staff }}
            component={CreateQuestioneries}
          />
          <PrivateRoute
            exact
            path="/staff/course-categories/:courseCategoryId/category-detail/:id/questions/edit/:questionerId"
            roles={{ role: Role.Staff }}
            component={CreateQuestioneries}
          />
          <PrivateRoute
            path="/staff/change-password"
            roles={{ role: Role.Staff }}
            component={changePassword}
          />
          <PrivateRoute
            path="/staff/availability/:name/:id"
            roles={{ role: Role.Staff }}
            component={Availability}
          />
          <PrivateRoute
            path="/staff/userAppointment"
            roles={{ role: Role.Staff }}
            component={UserAppointment}
          />
          <PrivateRoute
            path="/staff/analytics"
            roles={{ role: Role.Staff }}
            component={UserAnalytics}
          />
          <PrivateRoute
            path="/staff/appointments"
            roles={{ role: Role.Staff }}
            component={CounsellorCalender}
          />
          <PrivateRoute
            path="/staff/workshops/list"
            roles={{ role: Role.Staff }}
            component={Workshops}
          />
          <PrivateRoute
            path="/staff/events/list"
            roles={{ role: Role.Staff }}
            component={Events}
          />
          <PrivateRoute
            exact
            path="/staff/users"
            roles={{ role: Role.Staff }}
            component={Users}
          />

          <PrivateRoute
            exact
            path="/staff/user/create"
            roles={{ role: Role.Staff }}
            component={CreateUser}
          />
          <PrivateRoute
            exact
            path="/staff/user/edit/:id?"
            roles={{ role: Role.Staff }}
            component={CreateUser}
          />
          <PrivateRoute path="/staff/events/add" component={AddEvent} />
          <PrivateRoute path="/staff/workshops/add" component={AddWorkshop} />
          <PrivateRoute
            path="/staff/workshops/edit/:id"
            component={AddWorkshop}
          />
          <PrivateRoute path="/staff/events/edit/:id" component={AddEvent} />
          <PrivateRoute
            path="/staff/workshops/history/:id"
            component={WorkshopHistory}
          />
          {/* <PrivateRoute path="/staff/counsellorCalender"  component={CounsellorCalender} /> */}

          {/* Admin's Routes */}
          <PrivateRoute
            exact
            path="/admin"
            roles={{ role: Role.Admin }}
            component={AdminDashboardLight}
          />
          <PrivateRoute
            exact
            path="/admin/users"
            roles={{ role: Role.Admin }}
            component={Users}
          />
          <PrivateRoute
            exact
            path="/admin/registered-users"
            roles={{ role: Role.Admin }}
            component={RegisteredUser}
          />
          <PrivateRoute
            exact
            path="/admin/user/create"
            roles={{ role: Role.Admin }}
            component={CreateUser}
          />
          <PrivateRoute
            exact
            path="/admin/user/edit/:id?"
            roles={{ role: Role.Admin }}
            component={CreateUser}
          />
          <PrivateRoute
            path="/admin/site-location/create"
            roles={{ role: Role.Admin }}
            component={CreateSiteLocation}
          />
          <PrivateRoute
            path="/admin/site-location/edit/:id?"
            roles={{ role: Role.Admin }}
            component={CreateSiteLocation}
          />
          <PrivateRoute
            path="/admin/change-password"
            roles={{ role: Role.Admin }}
            component={changePassword}
          />
          <PrivateRoute
            path="/admin/analytics"
            roles={{ role: Role.Admin }}
            component={UserAnalytics}
          />
          <PrivateRoute
            path="/admin/report"
            roles={{ role: Role.Admin }}
            component={UserCourseReport}
          />
          <PrivateRoute
            path="/admin/super-admin"
            roles={{ role: Role.Admin }}
            component={ListofSuperAdmin}
          />
        </Router>
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
