import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { contentLibraryService } from "../../_services/staff";
import { analyticsService } from "../../_services/admin";
import moment from "moment";

class ResourceFileList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      expandedRows: null,
    };
  }

  componentDidMount() {
    contentLibraryService
      .getFilesByCategoryId(this.props.categoryId)
      .then((data) => {
        data && data.Data && this.setState({ fileList: data.Data });
      });
  }

  rowExpansionTemplate(data) {
    return (
      <div className="resource-files-subtable">
        <h5>
          <strong>
            {data?.courseType === "QUESTIONER"
              ? `Quiz statistics for ${data.title}`
              : `User Status for ${data.title}`}
          </strong>
        </h5>
        <FileStats fileId={data._id} courseType={data?.courseType} />
        <h5></h5>
      </div>
    );
  }

  render() {
    return (
      <DataTable
        value={this.state.fileList}
        expandedRows={this.state.expandedRows}
        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
        rowExpansionTemplate={this.rowExpansionTemplate}
        dataKey="_id"
        paginator={this.state?.fileList?.length >= 10}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={10}
        rowsPerPageOptions={[10, 20, 50, 100]}
      >
        <Column
          headerClassName="row-accessories"
          expander
          style={{ width: "3em" }}
        />
        <Column
          headerClassName="row-accessories"
          field="title"
          header="Title"
          sortable
        ></Column>
        <Column
          headerClassName="row-accessories"
          field="ratings"
          header="Avg. Ratings"
          sortable
        ></Column>
        <Column
          headerClassName="row-accessories"
          field="reviews"
          header="Reviews"
          sortable
        ></Column>
      </DataTable>
    );
  }
}

class FileStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewsList: [],
      expandedRows: null,
    };
  }

  componentDidMount() {
    if (this.props?.courseType === "QUESTIONER") {
      analyticsService
        .getResourceFileViewsOfQuestions(this.props.fileId)
        .then((data) => {
          const arr = data.Data?.map((val) => {
            return { ...val, count: val?.attemptCount?.length };
          });
          data && data.Data && this.setState({ viewsList: arr });
        });
    } else {
      analyticsService.getResourceFileViews(this.props.fileId).then((data) => {
        data && data.Data && this.setState({ viewsList: data.Data });
      });
    }
  }

  rowExpansionTemplate(data) {
    return (
      <div className="resource-files-subtable">
        <h5>
          <strong>Quiz Attempt for {data?.userName}</strong>
        </h5>
        <AttemptCount attemptData={data} />
        <h5></h5>
      </div>
    );
  }

  render() {
    const { courseType } = this.props;
    return courseType && courseType === "QUESTIONER" ? (
      <DataTable
        value={this.state.viewsList}
        paginator={this.state?.viewsList?.length > 10 ? true : false}
        expandedRows={this.state.expandedRows}
        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={10}
        dataKey="_id"
        rowExpansionTemplate={this.rowExpansionTemplate}
        rowsPerPageOptions={[10, 20, 50, 100]}
      >
        <Column
          headerClassName="main_header"
          expander
          style={{ width: "3em" }}
        />
        <Column
          headerClassName="user_states"
          field="userName"
          header="User Name"
          sortable
        ></Column>
        <Column
          headerClassName="user_states"
          field="count"
          header="Total Attempts"
          sortable
        ></Column>
      </DataTable>
    ) : (
      <DataTable
        value={this.state.viewsList}
        paginator={this.state?.viewsList?.length > 10 ? true : false}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={10}
        rowsPerPageOptions={[10, 20, 50, 100]}
      >
        <Column
          headerClassName="user_states"
          field="_id.userName"
          header="User Name"
          sortable
        ></Column>
        <Column
          headerClassName="user_states"
          field="dur"
          header="Duration"
          sortable
        ></Column>
        <Column
          headerClassName="user_states"
          field="rating"
          header="Rating"
          sortable
        ></Column>
        <Column
          headerClassName="user_states"
          field="comment"
          header="Comment"
          sortable
        ></Column>
      </DataTable>
    );
  }
}

class AttemptCount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attemptList: [],
    };
  }

  componentDidMount() {
    if (this.props?.attemptData) {
      const arr = this.props?.attemptData?.attemptCount?.map((val) => {
        const time =
          moment(val?.timestamp).format("MM/DD/YYYY hh:mm:ss a") || "";
        return { ...val, time };
      });
      this.setState({
        attemptList: arr,
      });
    }
  }

  render() {
    const { attemptList } = this.state;
    return (
      <DataTable
        value={attemptList}
        paginator={attemptList?.length > 10 ? true : false}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={10}
        rowsPerPageOptions={[10, 20, 50, 100]}
      >
        <Column
          headerClassName="main_header"
          field="status"
          header="Attempt Status"
          sortable
        ></Column>
        <Column
          headerClassName="main_header"
          field="time"
          header="Attempt Time"
          sortable
        ></Column>
      </DataTable>
    );
  }
}

export class ResourceLibraryStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedRows: null,
    };
  }

  resourceLibraryRowToggle = (e) => {
    this.setState({ expandedRows: e.data });
  };

  rowExpansionTemplate(data) {
    return (
      <div className="resource-files-subtable">
        <h5>
          <strong>Files for {data.name}</strong>
        </h5>
        <ResourceFileList categoryId={data._id} />
        <h5></h5>
      </div>
    );
  }

  render() {
    return (
      <DataTable
        value={this.props.data}
        paginator={this.props?.data?.length > 10}
        expandedRows={this.state.expandedRows}
        onRowToggle={this.resourceLibraryRowToggle}
        rowExpansionTemplate={this.rowExpansionTemplate}
        dataKey="_id"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={10}
        rowsPerPageOptions={[10, 20, 50, 100]}
      >
        <Column
          headerClassName="main_header"
          expander
          style={{ width: "3em" }}
        />
        <Column
          headerClassName="main_header"
          field="name"
          header="Name"
          sortable
        />
        <Column
          headerClassName="main_header"
          header="Views"
          field="pageViews"
          sortable
        />
      </DataTable>
    );
  }
}

export default ResourceLibraryStats;
