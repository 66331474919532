import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { userService } from "../../_services/admin";
import MaskedInput from "react-text-mask";
import { authenticationService } from "../../_services";
import { appointmentService } from "../../_services/staff/appointment.service";
import { MDBDataTable } from "mdbreact";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import Select from "react-select";
import { Editor } from "primereact/editor";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
  Progress,
} from "reactstrap";
import { holidayDateList } from "../../_helpers/_helperFunctions";
function mapStateToProps(state) {
  return {
    holidayList: state.holidayList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}
const timing = [
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "1:00",
  "1:30",
  "2:00",
  "2:30",
  "3:00",
  "3:30",
  "4:00",
  "4:30",
  "5:00",
];

function visibilityFilter(counsellorlist, currentUserId, showAllConsellors) {
  if (!showAllConsellors) {
    return counsellorlist.filter((user) => {
      if (user._id === currentUserId) {
        return true;
      }
      return false;
    });
  }
  return counsellorlist;
}

function CounsellorRow(props) {
  const {
    counsellor,
    index,
    toggleDetails,
    setState,
    getById,
    updateBookingCallStatus,
  } = props;

  return (
    <tr>
      <th
        scope="row"
        data-toggle="tooltip"
        data-placement="left"
        title={counsellor.firstName + " " + counsellor.lastName}
      >
        {counsellor.firstName.slice(0, 1) +
          "" +
          counsellor.lastName.slice(0, 1)}
      </th>
      <td>
        <span>{counsellor.counsellorType}</span>
      </td>

      {timing.map((time, timeIndex) => {
        let temp = (
          <td className="availabilityBox" key={counsellor._id + "-" + time}>
            <div
              className="form-control feedCounsellerDetails"
              id="time-"
              style={{
                border: "none",
                backgroundColor: "#e9ecef",
                color: "white",
                height: "5em",
              }}
            ></div>
          </td>
        );
        if (counsellor.availabilityData.length) {
          let availability = counsellor.availabilityData[0];
          if (availability.availability.length) {
            availability.availability.forEach((elmt) => {
              if (elmt.time === time) {
                temp = (
                  <td
                    className="availabilityBox cursor-pointer"
                    key={counsellor._id + "-" + time}
                  >
                    {elmt?.bookingId ? (
                      <div>
                        <input
                          type="text"
                          id="callStatus"
                          name="callStatus"
                          maxlength="10"
                          value={elmt.callStatus}
                          className="inputTextField"
                          onChange={async (e) => {
                            await setState({ callStatus: e.target.value });
                            updateBookingCallStatus(
                              counsellor?.availabilityData[0]?._id,
                              elmt?.bookingId,
                              elmt?._id,
                            );
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      onClick={() => {
                        toggleDetails();
                        setState({
                          userDetails: elmt,
                          counsellorId: counsellor._id,
                        });
                        getById(elmt.bookingId);
                      }}
                      className="form-control feedCounsellerDetails"
                      id={`time-${index}`}
                      style={{
                        border: "none",
                        backgroundColor: getSlotColor(elmt),
                        color: "white",
                        height: "5em",
                      }}
                    >
                      <div>
                        {elmt.codeStatus}-{elmt.name}
                      </div>
                      <div>{elmt.phoneNumber}</div>
                      {/* <div>{availability.availability[i].email}</div>   */}
                    </div>
                  </td>
                );
              }
            });
          }
        }

        return temp;
      })}
    </tr>
  );
}

const getSlotColor = (elem) => {
  if (elem.isNA) {
    return "#dc3545";
  }
  return elem.appointmentType === "in person" ? "#7460EE" : "#00abff";
};
export class CounsellorCalender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      userFromApi: null,
      loading: true,
      appointments: [],
      counsellors: [],
      modal: false,
      rejectModal: false,
      counsellorId: "",
      bookingid: "",
      date: new Date(),
      userModal: false,
      availability: null,
      attend: null,
      note: null,
      userDetails: [],
      counsellorList: [],
      showAllConsellors: false,
      callStatus: "",
      bookStatus: null,
      appointmentType: null,
    };
  }

  timing = [
    "9:00",
    "9:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "1:00",
    "1:30",
    "2:00",
    "2:30",
    "3:00",
    "3:30",
    "4:00",
    "4:30",
    "5:00",
  ];

  options = [
    { value: "EmpoyeementService", label: "ES" },
    { value: "Youth Programs", label: "YJC" },
  ];

  toggleDetails = () => {
    this.setState({
      userModal: !this.state.userModal,
    });
  };

  componentDidMount() {
    const { currentUser } = this.state;
    // userService.getUserById(currentUser.id).then((data) => {
    //   data.Data && this.props.setUserData(data.Data);
    // });
    // console.log(currentUser);

    this.getCounsellorAvailabilityByDate(this.state.date);
    this.getCounsellorsWithAvailabilies(this.state.date);
  }

  getCounsellorAvailabilityByDate = (date) => {
    console.log("date: ", date);
    appointmentService
      .getCounsellorAvailabilityByDate(
        this.state.currentUser.id,
        date.getTime(),
      )
      .then((data) => {
        // console.log("data: ", data);
        data &&
          data.Data &&
          this.setState({
            availability: data.Data,
            loading: false,
          });
      })
      .catch((error) => {
        console.log("error: ", error);
        this.setState({
          availability: null,
          loading: false,
        });
      });
  };

  getCounsellorsWithAvailabilies = (dt) => {
    const { currentUser, showAllConsellors } = this.state;
    appointmentService
      .getCounsellorsWithAvailabilities(dt.getTime())
      .then((data) => {
        if (data && data.Status) {
          this.setState({
            counsellorList: visibilityFilter(
              data.Data,
              currentUser.id,
              showAllConsellors,
            ),
            allCounsellors: data.Data,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log("err getCounsellorsWithAvailabilies: ", err);
      });
  };
  updateBookingCallStatus = async (
    counsellorAvailabilityId,
    bookingId,
    availabilityId,
  ) => {
    let data = {
      counsellorAvailabilityId,
      callStatus: this.state.callStatus,
      bookingId,
      availabilityId,
    };
    // console.log("data: ", data);
    await appointmentService.updateBookingCallStatus(data);
    this.getCounsellorsWithAvailabilies(this.state.date);
  };

  updateBookingNotes = () => {
    let data = {
      bookingId: this.state.userDetails.bookingId,
      notes: this.state.note,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      toAttend: this.state.attend?.label ? this.state.attend?.label : null,
      bookingStatus: this.state.bookStatus?.label
        ? this.state.bookStatus?.label
        : null,
      appointmentType: this.state.appointmentType?.label
        ? this.state.appointmentType?.label
        : null,
    };
    appointmentService.updateBookingNotes(data).then((res) => {
      // console.log(res);
      this.toggleDetails();
      this.setState({
        attend: null,
        note: null,
        bookingStatus: null,
      });
    });
  };

  getById = (id) => {
    appointmentService
      .getBookingById(id)
      .then((res) => {
        let attenedData = {
          label: res.Data.toAttend,
          value: res.Data.toAttend,
        };
        this.setState({
          note: res.Data.notes,
          email: res.Data.email,
          phoneNumber: res.Data.phoneNumber,
          attend: res.Data.toAttend ? attenedData : this.state.toAttend,
          bookStatus: {
            value: res.Data.bookingStatus,
            label: res.Data.bookingStatus,
          },
          appointmentType: {
            value: res.Data.appointmentType,
            label: res.Data.appointmentType,
          },
        });
      })
      .catch((err) => err);
  };

  handleDateChange = (e) => {
    this.setState({ date: e.value });
    this.getCounsellorsWithAvailabilies(e.value);
  };

  toggleShowAllConsellors = () => {
    this.setState((state) => {
      return {
        showAllConsellors: !state.showAllConsellors,
        counsellorList: visibilityFilter(
          state.allCounsellors,
          state.currentUser.id,
          !state.showAllConsellors,
        ),
      };
    });
  };

  render() {
    let { availability, counsellorList } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Appointments</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body horiZonatalScroll">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-calendar-check"></i>{" "}
                          <span>Appointments</span>
                        </div>
                        <div className="switch">
                          <label>
                            Show All
                            <input
                              type="checkbox"
                              checked={this.state.showAllConsellors}
                              onChange={this.toggleShowAllConsellors}
                            />
                            <span className="lever"></span>
                          </label>
                        </div>
                      </div>
                      <hr />
                      {/* <MDBDataTable striped bordered hover data={this.state.appointments} /> */}
                      <Calendar
                        className="calendercls mb-4 with-holiday"
                        id="calenderElem"
                        dateFormat="dd, M yy, DD"
                        selectionMode="single"
                        value={this.state.date}
                        showIcon={true}
                        onChange={this.handleDateChange}
                        disabledDates={holidayDateList(this.props.holidayList)}
                        readOnlyInput
                      ></Calendar>
                      <table className="table mb-0 table-responsive">
                        <thead className="thead-dark  text-center">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>

                            <th scope="col">
                              9:00 <br />
                              am
                            </th>

                            <th scope="col">
                              09:30 <br />
                              am
                            </th>

                            <th scope="col">
                              10:00 <br />
                              am
                            </th>

                            <th scope="col">
                              10:30 <br />
                              am
                            </th>

                            <th scope="col">
                              11:00 <br />
                              am
                            </th>

                            <th scope="col" style={{ display: "none" }}></th>

                            <th scope="col">
                              11:30 <br />
                              pm
                            </th>

                            <th scope="col">
                              01:00 <br />
                              pm
                            </th>

                            <th scope="col">
                              01:30 <br />
                              pm
                            </th>

                            <th scope="col">
                              02:00 <br />
                              pm
                            </th>

                            <th scope="col">
                              02:30 <br />
                              pm
                            </th>

                            <th scope="col">
                              03:00 <br />
                              pm
                            </th>

                            <th scope="col">
                              03:30 <br />
                              pm
                            </th>

                            <th scope="col">
                              04:00 <br />
                              pm
                            </th>

                            <th scope="col">
                              04:30 <br />
                              pm
                            </th>

                            <th scope="col">
                              05:00 <br />
                              pm
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {counsellorList.map((counsellor, index) => {
                            return (
                              <CounsellorRow
                                counsellor={counsellor}
                                index={index}
                                key={index}
                                toggleDetails={this.toggleDetails}
                                setState={(obj) => {
                                  this.setState(obj);
                                }}
                                getById={(id) => this.getById(id)}
                                updateBookingCallStatus={
                                  this.updateBookingCallStatus
                                }
                              />
                            );
                          })}
                        </tbody>
                      </table>

                      <div>
                        <Modal
                          isOpen={this.state.userModal}
                          centered="true"
                          toggle={this.toggleDetails}
                        >
                          <ModalHeader
                            toggle={() => {
                              this.toggleDetails();
                              this.setState({ attend: null, note: null });
                            }}
                          >
                            User Details
                          </ModalHeader>
                          <ModalBody>
                            <div className="mb-3">
                              <strong>UserName :</strong>{" "}
                              {this.state.userDetails.name}
                            </div>
                            <div className="mb-3">
                              <strong>Code :</strong>{" "}
                              {this.state.userDetails.codeStatus}
                            </div>
                            <div className="mb-3">
                              <strong>Phone Number :</strong>{" "}
                              <MaskedInput
                                mask={[
                                  "(",
                                  /[1-9]/,
                                  /\d/,
                                  /\d/,
                                  ")",
                                  " ",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  "-",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                                name="phoneNumber"
                                id="phoneNumber"
                                onChange={(e) => {
                                  this.setState({
                                    phoneNumber: e.target.value,
                                  });
                                }}
                                className={"mt-1 form-control"}
                                placeholder="Phone Number"
                                guide={false}
                                type="text"
                                value={this.state.phoneNumber}
                                render={(ref, props) => (
                                  <input
                                    type="text"
                                    label="Phone Number"
                                    name="phoneNumber"
                                    ref={ref}
                                    value={this.state.phoneNumber}
                                    {...props}
                                  />
                                )}
                              />
                            </div>
                            <div className="mb-3">
                              <strong>Email :</strong>{" "}
                              <input
                                name="email"
                                className="mt-1 form-control"
                                value={this.state.email}
                                onChange={(e) => {
                                  this.setState({
                                    email: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <strong>Status :</strong>{" "}
                            <div className="d-flex row mb-3">
                              <div className="mr-2 col-4">
                                <Select
                                  className="mt-1"
                                  placeholder="Status"
                                  isDisabled={
                                    this.state.currentUser.id ===
                                    this.state.counsellorId
                                      ? false
                                      : true
                                  }
                                  value={this.state.bookStatus}
                                  onChange={(e) => {
                                    this.setState({
                                      bookStatus: e,
                                    });
                                  }}
                                  options={[
                                    {
                                      value: "WNI",
                                      label: "WNI",
                                    },
                                    {
                                      value: "WI",
                                      label: "WI",
                                    },
                                    {
                                      value: "I",
                                      label: "I",
                                    },
                                    {
                                      value: "NI",
                                      label: "NI",
                                    },
                                    {
                                      value: "IJO",
                                      label: "IJO",
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                            <div className="d-flex row mb-3">
                              <div className="mr-2 col-4">
                                <strong>Attended:</strong>
                                <Select
                                  className="mt-1"
                                  placeholder="Attended"
                                  value={this.state.attend}
                                  isDisabled={
                                    this.state.currentUser.id ===
                                    this.state.counsellorId
                                      ? false
                                      : true
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      attend: e,
                                    })
                                  }
                                  options={[
                                    { value: "yes", label: "Yes" },
                                    { value: "no", label: "No" },
                                  ]}
                                />
                              </div>
                            </div>
                            <div className="d-flex row mb-3">
                              <div className="mr-2 col-4">
                                <strong>Appoinment Type :</strong>
                                <Select
                                  className="mt-1"
                                  placeholder="Appointment"
                                  isDisabled={
                                    this.state.currentUser.id ===
                                    this.state.counsellorId
                                      ? false
                                      : true
                                  }
                                  value={this.state.appointmentType}
                                  onChange={(e) => {
                                    this.setState({
                                      appointmentType: e,
                                    });
                                  }}
                                  options={[
                                    {
                                      value: "virtual",
                                      label: "Virtual",
                                    },
                                    {
                                      value: "in person",
                                      label: "In person",
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                            <div className="mb-3">
                              <strong>Note :</strong>
                              <div className="mt-1">
                                <Editor
                                  style={{ height: "120px" }}
                                  value={this.state.note}
                                  onTextChange={(e) =>
                                    this.setState({ note: e.htmlValue })
                                  }
                                  readOnly={
                                    this.state.currentUser.id ===
                                    this.state.counsellorId
                                      ? false
                                      : true
                                  }
                                />
                              </div>
                            </div>
                          </ModalBody>
                          {this.state.currentUser.id ===
                            this.state.counsellorId &&
                          this.state.userDetails.bookingId ? (
                            <ModalFooter>
                              <Button
                                className="disableColor"
                                onClick={this.updateBookingNotes}
                                color="primary"
                              >
                                Save
                              </Button>
                            </ModalFooter>
                          ) : null}
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CounsellorCalender);
