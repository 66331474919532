const LIMIT = 12;
const PAGES_COUNT = 5;
const optionsTypes = {
  MULTIPLE_CHOICE: {
    key: "MULTIPLE_CHOICE",
    name: "Multiple choice",
    value: "radio",
  },
  CHECKBOX: { key: "CHECKBOX", name: "Checkboxes", value: "checkbox" },
};

const optionsTypesArray = Object.keys(optionsTypes).map(
  (key) => optionsTypes[key],
);

const courseTypes = {
  QUESTIONER: "QUESTIONER",
  FILE: "FILE",
  LINK: "LINK",
};

const courseTypesShown = {
  QUESTIONER: "Quiz",
  FILE: "Video/Pdf",
  LINK: "Link",
};

const courseStatus = {
  TODO: "TODO",
  INPROGRESS: "INPROGRESS",
  COMPLETED: "COMPLETED",
};

const courseStatusShown = {
  TODO: "Not Started",
  INPROGRESS: "In Progress",
  COMPLETED: "Completed",
};

const courseStatusArray = [
  {
    name: "Not Started",
    value: courseStatus.TODO,
  },
  {
    name: "In Progress",
    value: courseStatus.INPROGRESS,
  },
  {
    name: "Completed",
    value: courseStatus.COMPLETED,
  },
];

const courseTypesArray = [
  {
    name: "Video/Pdf",
    value: courseTypes.FILE,
  },
  {
    name: "Quiz",
    value: courseTypes.QUESTIONER,
  },
  {
    name: "Link",
    value: courseTypes.LINK,
  },
];

const organizationNames = [
  { name: "Adult Language and Learning", value: "ALAL" },
  {
    name: "Centre Communautaire Francophone Windsor Essex Kent Inc. (CCFWEK)",
    value: "CCFWEK",
  },
  { name: "Collège Boréal", value: "CB" },
  { name: "South Essex Community Council (SECC)", value: "SECC" },
  {
    name: "The Multicultural Council of Windsor and Essex County (MCC)",
    value: "MCC",
  },
  { name: "The Windsor Women Working With Immigrant Women (W5)", value: "W5" },
  { name: "Unemployed Help Centre of Windsor Inc. (UHC)", value: "UHC" },
  { name: "YMCA of Southwestern Ontario", value: "YMCA" },
  {
    name: "Windsor Essex Local Immigration Partnership (WE LIP)",
    value: "WELIP",
  },
  {
    name: "Women’s Enterprise Skills Training of Windsor Inc. (WEST)",
    value: "WEST",
  },
  { name: "New Canadians’ Centre of Excellence Inc.", value: "NCCOEI" },
  {
    name: "Réseau en Immigration Francophone du Centre Sud-Ouest (RIFCSO)",
    value: "RIFCSO",
  },
  {
    name: "Greater Essex County District School Board Language  Assessment & Resource Centre (GECDSB LARC)",
    value: "GECDSBLARC",
  },
];

module.exports = {
  LIMIT,
  PAGES_COUNT,
  optionsTypes,
  optionsTypesArray,
  courseTypes,
  courseStatus,
  organizationNames,
  courseStatusArray,
  courseStatusShown,
  courseTypesArray,
  courseTypesShown,
};
