/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Progress,
} from "reactstrap";
import { FileIcon, defaultStyles } from "react-file-icon";
import ReactPlayer from "react-player";
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import { Card } from "react-bootstrap";
import Swal from "sweetalert";
import slugify from "slugify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import classNames from "classnames";
import {
  removeAllDotsButLast,
  getFileExtension,
} from "../../_helpers/_helperFunctions";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import { contentLibraryService } from "../../_services/staff";
import { authenticationService } from "../../_services";
import { setUserData } from "../../redux-store/action";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import HeaderAdmin from "../../components/HeaderAdmin";
import Preloader from "../../components/Preloader";
import { CustomModal } from "../../components/CustomModal";
import {
  AUDIO_FILES,
  VIDEO_FILES,
  DOCS_AND_IMAGES,
  IMAGE_TYPES,
} from "../../_helpers";
import notFound from "../image/notfound.png";
import { courseTypes } from "../constants/constant";
import { MDBIcon } from "mdbreact";
import swal from "sweetalert";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class CategoryDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      courseCategoryId: this.props?.match?.params?.courseCategoryId,
      loading: true,
      contentCategory: {},
      contentFiles: [],
      staffOptions: [],
      isModalOpen: false,
      isModalOpen2: false,
      search: "",
      contentFilesCopy: [],
      audioModal: false,
      videoModal: false,
      mediaUrl: "",
      link: "",
      updateFileModal: false,
      updateFileModal2: false,
      selectedFileIndex: "",
      isLoading: false,
      filename: "",
      feedback: false,
      downloadOption: false,
      uploadProgress: 0,
      reorderMode: false,
      indexError: "",
    };

    this.toggleAddFileModal = this.toggleAddFileModal.bind(this);
    this.toggleAddLinkModal = this.toggleAddLinkModal.bind(this);
    this.uploadLink = this.uploadLink.bind(this);
  }

  componentDidMount() {
    this.props.setUserData(this.state.currentUser);
    this.getCategroryDetail();
    this.getFilesByCategoryId();
    this.setState({ loading: false });
  }

  getFilesByCategoryId() {
    this.props.match.params.id &&
      contentLibraryService
        .getFilesByCategoryId(this.props.match.params.id)
        .then((data) => {
          this.setState({
            contentFiles: data.Data,
            loading: false,
            contentFilesCopy: data.Data,
          });
        });
  }

  getFileFromKey(key, filename) {
    const requestBody = { key };
    contentLibraryService.getSignedLinkFromKey(requestBody).then((data) => {
      const fileExtension = getFileExtension(key);
      if (AUDIO_FILES.includes(fileExtension)) {
        this.setState({
          mediaUrl: data.Data.url,
          filename,
        });
        this.toggleAudio();
      } else if (VIDEO_FILES.includes(fileExtension)) {
        this.setState({
          mediaUrl: data.Data.url,
          filename,
        });
        this.toggleVideo();
      } else if (DOCS_AND_IMAGES.includes(fileExtension)) {
        this.setState({
          mediaUrl: data.Data.url,
          filename,
          fileType: getFileExtension(key),
          selectedFileType: "file",
        });

        this.togglefileViewer();
      } else {
        window.open(data.Data.url, "_blank");
      }
    });
  }

  getCategroryDetail() {
    this.props.match.params.id &&
      contentLibraryService
        .getContentLibaryCategroryById(this.props.match.params.id)
        .then((data) => {
          this.setState({ contentCategory: data.Data, loading: false });
        });
  }

  toggleAudio = () => {
    this.setState({
      audioModal: !this.state.audioModal,
    });
  };

  toggleVideo = () => {
    this.setState({
      videoModal: !this.state.videoModal,
    });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (
      e.target.name === "index" &&
      (e.target.value > this.state.selectedFileIndex - 1 || e.target.value < 1)
    ) {
      this.setState({
        indexError: "Index limit exceed",
      });
    } else {
      this.setState({
        indexError: "",
      });
    }
  };

  handleCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  toggleUpdateFileModel = (
    fileId,
    title,
    description,
    feedback,
    downloadOption,
    location,
    file,
    index,
  ) => {
    if (!this.state.updateFileModal) {
      const headers = {
        Authorization: `Bearer ${this.state.currentUser?.token}`,
        isenc: localStorage.getItem("isenc")
          ? parseInt(localStorage.getItem("isenc"), 10)
          : 0,
      };
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/get-file-last-index/${this.props.match.params.id}`,
          { headers },
        )
        .then((response) => {
          if (response?.data?.Status) {
            this.setState({
              selectedFileIndex: response?.data?.Data?.Data?.lastIndex + 1,
            });
          }
        });
    }
    this.setState((state) => {
      return {
        updateFileModal: !state.updateFileModal,
        fileId,
        title,
        description,
        feedback,
        downloadOption,
        location,
        file,
        index,
      };
    });
  };

  toggleUpdateLinkModel = (
    fileId,
    title,
    description,
    feedback,
    downloadOption,
    link,
    file,
    index,
  ) => {
    if (!this.state.updateFileModal) {
      const headers = {
        Authorization: `Bearer ${this.state.currentUser?.token}`,
        isenc: localStorage.getItem("isenc")
          ? parseInt(localStorage.getItem("isenc"), 10)
          : 0,
      };
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/get-file-last-index/${this.props.match.params.id}`,
          { headers },
        )
        .then((response) => {
          if (response?.data?.Status) {
            this.setState({
              selectedFileIndex: response?.data?.Data?.Data?.lastIndex + 1,
            });
          }
        });
    }
    this.setState((state) => {
      return {
        updateFileModal2: !state.updateFileModal2,
        fileId,
        title,
        description,
        feedback,
        downloadOption,
        link,
        file,
        index,
      };
    });
  };

  togglefileViewer = () => {
    this.setState((state) => {
      return { fileViewer: !state.fileViewer };
    });
  };

  updateFileDetails = () => {
    const {
      fileId,
      title,
      description,
      feedback,
      downloadOption,
      selectedFiles,
      file,
      index,
    } = this.state;
    if (this.state.indexError === "") {
      if (fileId && title) {
        if (selectedFiles?.[0]) {
          const selectedFile = selectedFiles[0];
          // Split the filename to get the name and type
          const originalname = selectedFiles[0].name.toLowerCase();
          const fileName = `content-library/${
            this.state.contentCategory.name
          }/${removeAllDotsButLast(slugify(originalname, { lower: true }))}`;
          const fileType = selectedFiles?.[0]?.type;
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/get-file-uploadlink`,
              {
                fileName,
                fileType,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.state.currentUser.token}`,
                  isenc: localStorage.getItem("isenc")
                    ? parseInt(localStorage.getItem("isenc"), 10)
                    : 0,
                },
              },
            )
            .then((response) => {
              const returnData = response.data.Data;
              const { signedRequest } = returnData;
              const { url } = returnData;
              // this.setState({ url: url });

              // Put the fileType in the headers for the upload
              const options = {
                headers: {
                  "Content-Type": fileType,
                },
                _onUploadProgress: (ev) => {
                  const progress = parseInt((ev.loaded / ev.total) * 100, 10);
                  this.setState({
                    uploadProgress: progress,
                  });
                },
                get onUploadProgress() {
                  return this._onUploadProgress;
                },
                set onUploadProgress(value) {
                  this._onUploadProgress = value;
                },
              };
              axios
                .put(signedRequest, selectedFile, options)
                .then(() => {
                  const requestData = {
                    fileName,
                    originalname,
                    fileId,
                    fileType,
                    title,
                    description,
                    feedback,
                    downloadOption,
                    currentUserId: this.state.currentUser.id,
                    url,
                    categoryId: file?.category,
                    index: parseInt(index, 10),
                    courseCategoryId: this.state.courseCategoryId,
                  };
                  contentLibraryService
                    .updateFileDetails(requestData)
                    .then((data) => {
                      if (data.Status) {
                        this.toggleUpdateFileModel();
                        this.getFilesByCategoryId();
                        this.setState({
                          isLoading: false,
                          uploadProgress: 0,
                          selectedFileTitle: "",
                          selectedFileDescription: "",
                          selectedFileIndex: "",
                          selectedFiles: "",
                          index: "",
                        });
                      }
                    });
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          const requestData = {
            fileName: file.key,
            originalname: file.originalname,
            fileId,
            fileType: file.contentType,
            title,
            description,
            feedback,
            downloadOption,
            currentUserId: this.state.currentUser.id,
            url: file.location,
            categoryId: file?.category,
            index: parseInt(index, 10),
            courseCategoryId: this.state.courseCategoryId,
          };
          contentLibraryService.updateFileDetails(requestData).then((data) => {
            if (data.Status) {
              this.toggleUpdateFileModel();
              this.getFilesByCategoryId();
            }
          });
        }
      }
    } else {
      let msg = `Please enter position between 1 to ${
        this.state.selectedFileIndex - 1
      }`;
      if (this.state.selectedFileIndex - 1 === 1) {
        msg = "Please enter position 1";
      }
      swal(msg, "", "error");
    }
  };

  updateLinkDetails = () => {
    const {
      fileId,
      title,
      description,
      feedback,
      downloadOption,
      link,
      file,
      index,
    } = this.state;
    if (this.state.indexError === "") {
      if (fileId && title) {
        const requestData = {
          fileId,
          title,
          description,
          feedback,
          downloadOption,
          currentUserId: this.state.currentUser.id,
          link: link,
          categoryId: file?.category,
          index: parseInt(index, 10),
          courseCategoryId: this.state.courseCategoryId,
        };
        contentLibraryService.updateFileDetails(requestData).then((data) => {
          if (data.Status) {
            this.toggleUpdateLinkModel();
            this.getFilesByCategoryId();
          }
        });
      }
    } else {
      let msg = `Please enter position between 1 to ${
        this.state.selectedFileIndex - 1
      }`;
      if (this.state.selectedFileIndex - 1 === 1) {
        msg = "Please enter position 1";
      }
      swal(msg, "", "error");
    }
  };

  onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const fileList = this.state.contentFiles;
    const newFileList = Array.from(fileList);

    newFileList.splice(source.index, 1);
    newFileList.splice(destination.index, 0, fileList[source.index]);

    this.setState({ contentFiles: newFileList });
  };

  reorderFiles = () => {
    const fileList = this.state.contentFiles;
    const categoryId = this.state.contentCategory._id;

    const requestObj = {
      categoryId,
      fileOrder: fileList.map((file, index) => {
        return {
          _id: file._id,
          index,
        };
      }),
    };

    contentLibraryService
      .reorderFiles(requestObj)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  uploadFile = (event) => {
    this.setState({
      isLoading: true,
    });
    if (this.state.contentCategory._id) {
      if (this.state.selectedFiles[0]) {
        const file = this.state.selectedFiles[0];
        // Split the filename to get the name and type
        const originalname = this.state.selectedFiles[0].name.toLowerCase();
        const fileName = `content-library/${
          this.state.contentCategory.name
        }/${removeAllDotsButLast(slugify(originalname, { lower: true }))}`;
        const fileType = this.state.selectedFiles?.[0]?.type;
        const courseType = "FILE";
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/get-file-uploadlink`,
            {
              fileName,
              fileType,
            },
            {
              headers: {
                Authorization: `Bearer ${this.state.currentUser.token}`,
                isenc: localStorage.getItem("isenc")
                  ? parseInt(localStorage.getItem("isenc"), 10)
                  : 0,
              },
            },
          )
          .then((response) => {
            const returnData = response.data.Data;
            const { signedRequest } = returnData;
            const { url } = returnData;
            // this.setState({ url: url });

            // Put the fileType in the headers for the upload
            const options = {
              headers: {
                "Content-Type": fileType,
              },
              _onUploadProgress: (ev) => {
                const progress = parseInt((ev.loaded / ev.total) * 100, 10);
                this.setState({
                  uploadProgress: progress,
                });
              },
              get onUploadProgress() {
                return this._onUploadProgress;
              },
              set onUploadProgress(value) {
                this._onUploadProgress = value;
              },
            };
            axios
              .put(signedRequest, file, options)
              .then(() => {
                // this.setState({ pdfLink: url });
                const requestData = {
                  fileName,
                  originalname,
                  url,
                  courseType,
                  categoryId: this.state.contentCategory._id,
                  currentUserId: this.state.currentUser.id,
                  title: this.state.selectedFileTitle,
                  description: this.state.selectedFileDescription,
                  index: parseInt(this.state.selectedFileIndex, 10),
                  feedback: this.state.feedback.toString(),
                  downloadOption: this.state.downloadOption,
                  fileType,
                  courseType: courseTypes.FILE,
                  courseCategoryId: this.state.courseCategoryId,
                };

                contentLibraryService.saveFileInfo(requestData).then((data) => {
                  if (data && data.Status) {
                    // file info save success.
                    this.toggleAddFileModal();
                    this.getFilesByCategoryId();
                    this.getCategroryDetail();
                    this.setState({
                      isLoading: false,
                      uploadProgress: 0,
                      selectedFileTitle: "",
                      selectedFileDescription: "",
                      selectedFileIndex: "",
                    });
                    Swal("File uploaded successfully", "", "success");
                  } else {
                    // file info save fail
                    Swal(
                      "File cannot be uploaded please try later",
                      "",
                      "error",
                    );
                  }
                });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      this.toggleAddFileModal();
      this.setState({
        uploadProgress: 0,
        isLoading: false,
      });
    }
  };

  uploadLink() {
    {
      const requestData = {
        link: this.state.link,
        courseType: courseTypes.LINK,
        categoryId: this.state.contentCategory._id,
        currentUserId: this.state.currentUser.id,
        title: this.state.selectedFileTitle,
        description: this.state.selectedFileDescription,
        index: parseInt(this.state.selectedFileIndex, 10),
        feedback: this.state.feedback.toString(),
        downloadOption: this.state.downloadOption,
        courseCategoryId: this.state.courseCategoryId,
      };

      contentLibraryService.saveFileInfo(requestData).then((data) => {
        if (data && data.Status) {
          // file info save success.
          this.toggleAddLinkModal();
          this.getFilesByCategoryId();
          this.getCategroryDetail();
          this.setState({
            isLoading: false,
            uploadProgress: 0,
            selectedFileTitle: "",
            selectedFileDescription: "",
            selectedFileIndex: "",
          });
          Swal("Link uploaded successfully", "", "success");
        } else {
          // file info save fail
          Swal("Link cannot be uploaded please try later", "", "error");
        }
      });
    }
  }

  filterCard = (event) => {
    if (event.target.value) {
      const filtered = this.state.contentFilesCopy.filter((value) => {
        return value.title
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      this.setState({
        contentFiles: filtered,
      });
    } else {
      this.setState({
        contentFiles: this.state.contentFilesCopy,
      });
    }
    // this.setState({
    //   search: event.target.value
  };

  onChangeFileHandler = (event) => {
    this.setState({ selectedFiles: event.target.files });
  };

  handleAddFileChanges = (event) => {
    if (event.target.name === "title") {
      this.setState({ selectedFileTitle: event.target.value });
    } else if (event.target.name === "description") {
      this.setState({ selectedFileDescription: event.target.value });
    } else {
      this.setState({ selectedFileFeedback: event.target.value });
    }
  };

  getThumbnail = (file) => {
    if (file?.courseType === courseTypes.LINK) {
      return (
        <Card.Img
          src={window.location.origin + "/assets/images/google-forms.png"}
          alt={"Quiz"}
          className="cursor-pointer cardRatio py-2"
          style={{
            width: "auto",
            maxWidth: "-webkit-fill-available",
          }}
          onClick={() => window.open(file?.link, "_blank")}
        />
      );
    }

    if (file?.courseType === courseTypes.QUESTIONER) {
      return (
        <Card.Img
          src={window.location.origin + "/assets/images/quiz.png"}
          alt={"Quiz"}
          className="cursor-pointer cardRatio"
          style={{
            width: "auto",
            maxWidth: "-webkit-fill-available",
          }}
        />
      );
    }
    const fileExtension = file?.originalname?.split(".");

    if (VIDEO_FILES.includes(fileExtension[1])) {
      return (
        <Card.Img
          src={window.location.origin + "/assets/images/play.png"}
          alt={"Video"}
          className="cursor-pointer cardRatio"
          style={{
            width: "auto",
            maxWidth: "-webkit-fill-available",
          }}
          onClick={() => {
            this.getFileFromKey(
              file.key,
              file.title || file.originalname,
              file._id,
            );
          }}
        />
      );
    }
    if (IMAGE_TYPES.includes(fileExtension[1]?.toLowerCase())) {
      return (
        <Card.Img
          width="100%"
          src={file.location}
          alt={file.title ? file.title : file.originalname}
          className="cursor-pointer cardRatio"
          onClick={() => {
            this.getFileFromKey(
              file.key,
              file.title || file.originalname,
              file._id,
            );
          }}
        />
      );
    }

    return (
      <FileIcon
        extension={fileExtension[1] === "pdf" ? "doc" : fileExtension[1]}
        {...defaultStyles[
          fileExtension[1] === "pdf" ? "doc" : fileExtension[1]
        ]}
      />
    );
  };

  async toggleAddFileModal() {
    if (!this.state.isModalOpen) {
      const headers = {
        Authorization: `Bearer ${this.state.currentUser?.token}`,
        isenc: localStorage.getItem("isenc")
          ? parseInt(localStorage.getItem("isenc"), 10)
          : 0,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/get-file-last-index/${this.props.match.params.id}`,
        { headers },
      );
      if (response?.data?.Status) {
        this.setState({
          selectedFileIndex: response?.data?.Data?.Data?.lastIndex + 1,
        });
      }
    }

    this.setState({
      isModalOpen: !this.state.isModalOpen,
      feedback: false,
      downloadOption: false,
    });
  }

  async toggleAddLinkModal() {
    if (!this.state.isModalOpen2) {
      const headers = {
        Authorization: `Bearer ${this.state.currentUser?.token}`,
        isenc: localStorage.getItem("isenc")
          ? parseInt(localStorage.getItem("isenc"), 10)
          : 0,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/get-file-last-index/${this.props.match.params.id}`,
        { headers },
      );
      if (response?.data?.Status) {
        this.setState({
          selectedFileIndex: response?.data?.Data?.Data?.lastIndex + 1,
        });
      }
    }

    this.setState({
      isModalOpen2: !this.state.isModalOpen2,
      feedback: false,
      downloadOption: false,
    });
  }

  deleteCategrory() {
    if (this.state.contentCategory._id) {
      contentLibraryService
        .deleteContentCategory(
          this.state.contentCategory._id,
          this.state.currentUser.id,
          this.state.courseCategoryId,
        )
        .then(() => {
          const { from } = this.props.location.state || {
            from: { pathname: "/staff/course-categories" },
          };
          this.props.history.push(from);
        });
    }
  }

  deleteFile(fileId) {
    Swal({
      title: "Are you sure!",
      text: "You want to delete this file?",
      buttons: ["No", "Yes"],
      icon: "warning",
      dangerMode: true,
    }).then((result) => {
      if (result) {
        const requestBody = {
          fileId,
          categoryId: this.state.contentCategory._id,
          currentUserId: this.state.currentUser.id,
          courseCategoryId: this.state.courseCategoryId,
        };
        contentLibraryService.deleteFile(requestBody).then(() => {
          this.getFilesByCategoryId();
          this.getCategroryDetail();
        });
      }
    });
  }

  render() {
    const { contentFiles, contentCategory } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading ? <Preloader /> : ""}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">Course Detail</h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>

                  <li
                    className="breadcrumb-item cursor-pointer"
                    onClick={() =>
                      this.props.history.push(`/staff/course-categories`)
                    }
                  >
                    Course Categories
                  </li>
                  <li
                    className="breadcrumb-item cursor-pointer"
                    onClick={() =>
                      this.props.history.push(
                        `/staff/course-categories/${this.state.courseCategoryId}`,
                      )
                    }
                  >
                    Courses
                  </li>
                  <li className="breadcrumb-item active">Course Detail</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <div>
                              <Link
                                to={`/staff/course-categories/${this.state.courseCategoryId}`}
                                className="btn btn-purple"
                              >
                                <i className="mdi mdi-chevron-left" />
                                <span className="hide-menu">Back</span>
                              </Link>
                            </div>
                            <div className="d-flex">
                              <div className="mr-2">
                                <input
                                  type="text"
                                  onChange={this.filterCard}
                                  className="form-control"
                                  placeholder="search file"
                                />
                                <i className="mdi mdi-magnify search-icon" />
                              </div>
                              {this.state.currentUser.role === 2 && (
                                <div className="mr-2">
                                  <button
                                    type="button"
                                    className="btn btn-purple"
                                    onClick={() => this.toggleAddFileModal()}
                                  >
                                    <MDBIcon fas icon="plus" className="mr-2" />
                                    Add Files
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-purple ml-2"
                                    onClick={() => {
                                      if (this.state.contentCategory._id) {
                                        const { from } = this.props.location
                                          .state || {
                                          from: {
                                            pathname: `/staff/course-categories/${this.state.courseCategoryId}/category-detail/${this.props.match.params.id}/questions/create`,
                                          },
                                        };
                                        this.props.history.push(from);
                                      }
                                    }}
                                  >
                                    <MDBIcon fas icon="plus" className="mr-2" />
                                    Add Quiz
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-purple ml-2"
                                    onClick={() => this.toggleAddLinkModal()}
                                  >
                                    <MDBIcon fas icon="plus" className="mr-2" />
                                    Add Quiz Link
                                  </button>

                                  <Modal
                                    isOpen={this.state.isModalOpen}
                                    toggle={this.toggleAddFileModal}
                                  >
                                    {/* {this.state.isLoading && <Preloader/>} */}

                                    <ModalHeader
                                      toggle={this.toggleAddFileModal}
                                    >
                                      Add File
                                    </ModalHeader>

                                    <ModalBody>
                                      <Form>
                                        <FormGroup row>
                                          <Col sm={12}>
                                            <Input
                                              type="file"
                                              name="file"
                                              id="exampleFile"
                                              disabled={
                                                this.state.uploadProgress > 0
                                              }
                                              onChange={
                                                this.onChangeFileHandler
                                              }
                                            />
                                          </Col>
                                          {this.state.uploadProgress > 0 && (
                                            <Col
                                              sm={12}
                                              style={{ marginTop: "10px" }}
                                            >
                                              <Progress
                                                style={{
                                                  height: "17px",
                                                  backgroundColor: "#7460EE",
                                                }}
                                                value={
                                                  this.state.uploadProgress
                                                }
                                              >
                                                <strong>
                                                  {this.state.uploadProgress} %
                                                </strong>
                                              </Progress>
                                            </Col>
                                          )}
                                          <Col sm={12}>
                                            <div className="pt-2">
                                              <Label>Title:</Label>
                                              <Input
                                                type="text"
                                                name="title"
                                                id="title"
                                                value={
                                                  this.state.selectedFileTitle
                                                }
                                                onChange={
                                                  this.handleAddFileChanges
                                                }
                                                disabled={
                                                  this.state.uploadProgress > 0
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col sm={12}>
                                            <div className="pt-2">
                                              <Label>Description:</Label>
                                              <Input
                                                type="textarea"
                                                name="description"
                                                id="description"
                                                value={
                                                  this.state
                                                    .selectedFileDescription
                                                }
                                                onChange={
                                                  this.handleAddFileChanges
                                                }
                                                disabled={
                                                  this.state.uploadProgress > 0
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col sm={12}>
                                            <div className="pt-2">
                                              <Label>Position:</Label>
                                              <Input
                                                type="text"
                                                name="index"
                                                id="index"
                                                value={
                                                  this.state.selectedFileIndex
                                                }
                                                onChange={
                                                  this.handleAddFileChanges
                                                }
                                                disabled={
                                                  this.state.uploadProgress > 0
                                                }
                                              />
                                              <p
                                                style={{ color: "#dc3545" }}
                                                className="mb-1"
                                              >
                                                Updating the index will impact
                                                completed courses on the
                                                customer's end.
                                              </p>
                                            </div>
                                          </Col>
                                          <Col sm={12}>
                                            <div className="pt-2 form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="feedback"
                                                name="feedback"
                                                checked={this.state.feedback}
                                                onChange={
                                                  this.handleCheckboxChange
                                                }
                                                disabled={
                                                  this.state.uploadProgress > 0
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="feedback"
                                              >
                                                Take Feedback
                                              </label>
                                            </div>
                                          </Col>
                                          <Col sm={12}>
                                            <div className="pt-2 form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="downloadOption"
                                                name="downloadOption"
                                                checked={
                                                  this.state.downloadOption
                                                }
                                                onChange={
                                                  this.handleCheckboxChange
                                                }
                                                disabled={
                                                  this.state.uploadProgress > 0
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="downloadOption"
                                              >
                                                Give Download Option
                                              </label>
                                              <small
                                                id="downloadOptionHelp"
                                                className="form-text"
                                              >
                                                Only applicable for video and
                                                audio files.
                                              </small>
                                            </div>
                                          </Col>
                                        </FormGroup>
                                      </Form>
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button
                                        className="btn-purple"
                                        onClick={this.uploadFile}
                                        disabled={this.state.uploadProgress > 0}
                                      >
                                        Upload Files
                                      </Button>{" "}
                                      <Button
                                        color="secondary"
                                        onClick={this.toggleAddFileModal}
                                        disabled={this.state.uploadProgress > 0}
                                      >
                                        Cancel
                                      </Button>
                                    </ModalFooter>
                                  </Modal>

                                  <Modal
                                    isOpen={this.state.isModalOpen2}
                                    toggle={this.toggleAddLinkModal}
                                  >
                                    {/* {this.state.isLoading && <Preloader/>} */}

                                    <ModalHeader
                                      toggle={this.toggleAddLinkModal}
                                    >
                                      Add Quiz Link
                                    </ModalHeader>

                                    <ModalBody>
                                      <Form>
                                        <FormGroup row>
                                          <Col sm={12}>
                                            <Input
                                              type="text"
                                              name="link"
                                              id="exampleLink"
                                              onChange={(event) =>
                                                this.setState({
                                                  link: event.target.value,
                                                })
                                              }
                                            />
                                          </Col>
                                          <Col sm={12}>
                                            <div className="pt-2">
                                              <Label>Title:</Label>
                                              <Input
                                                type="text"
                                                name="title"
                                                id="title"
                                                value={
                                                  this.state.selectedFileTitle
                                                }
                                                onChange={
                                                  this.handleAddFileChanges
                                                }
                                                disabled={
                                                  this.state.uploadProgress > 0
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col sm={12}>
                                            <div className="pt-2">
                                              <Label>Description:</Label>
                                              <Input
                                                type="textarea"
                                                name="description"
                                                id="description"
                                                value={
                                                  this.state
                                                    .selectedFileDescription
                                                }
                                                onChange={
                                                  this.handleAddFileChanges
                                                }
                                                disabled={
                                                  this.state.uploadProgress > 0
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col sm={12}>
                                            <div className="pt-2">
                                              <Label>Position:</Label>
                                              <Input
                                                type="text"
                                                name="index"
                                                id="index"
                                                value={
                                                  this.state.selectedFileIndex
                                                }
                                                onChange={
                                                  this.handleAddFileChanges
                                                }
                                                disabled={
                                                  this.state.uploadProgress > 0
                                                }
                                              />
                                              <p
                                                style={{ color: "#dc3545" }}
                                                className="mb-1"
                                              >
                                                Updating the index will impact
                                                completed courses on the
                                                customer's end.
                                              </p>
                                            </div>
                                          </Col>
                                          <Col sm={12}>
                                            <div className="pt-2 form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="feedback"
                                                name="feedback"
                                                checked={this.state.feedback}
                                                onChange={
                                                  this.handleCheckboxChange
                                                }
                                                disabled={
                                                  this.state.uploadProgress > 0
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="feedback"
                                              >
                                                Take Feedback
                                              </label>
                                            </div>
                                          </Col>
                                          <Col sm={12}>
                                            <div className="pt-2 form-check">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="downloadOption"
                                                name="downloadOption"
                                                checked={
                                                  this.state.downloadOption
                                                }
                                                onChange={
                                                  this.handleCheckboxChange
                                                }
                                                disabled={
                                                  this.state.uploadProgress > 0
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor="downloadOption"
                                              >
                                                Give Download Option
                                              </label>
                                              <small
                                                id="downloadOptionHelp"
                                                className="form-text"
                                              >
                                                Only applicable for video and
                                                audio files.
                                              </small>
                                            </div>
                                          </Col>
                                        </FormGroup>
                                      </Form>
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button
                                        className="btn-purple"
                                        onClick={this.uploadLink}
                                        disabled={this.state.uploadProgress > 0}
                                      >
                                        Upload Link
                                      </Button>{" "}
                                      <Button
                                        color="secondary"
                                        onClick={this.toggleAddLinkModal}
                                        disabled={this.state.uploadProgress > 0}
                                      >
                                        Cancel
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                </div>
                              )}
                              {this.state.currentUser.role === 2 && (
                                <div className="mr-2">
                                  <button
                                    type="button"
                                    className="btn btn-purple"
                                    // onClick={() => this.deleteCategrory()}
                                    onClick={() =>
                                      document
                                        .getElementById(
                                          "deleteConfirmationButton",
                                        )
                                        .click()
                                    }
                                  >
                                    Delete Course
                                  </button>
                                  <DeleteConfirmation
                                    id="deleteConfirmationButton"
                                    confirm={() => this.deleteCategrory()}
                                  />
                                </div>
                              )}

                              {this.state.reorderMode ? (
                                <div className="ml-2">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    // onClick={() => this.deleteCategrory()}
                                    onClick={this.reorderFiles}
                                  >
                                    Save Order
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="d-flex line-background justify-content-between">
                            <span className="">
                              {contentCategory.name ? contentCategory.name : ""}
                            </span>
                            <span className="">
                              {" "}
                              <i className="ti-timer" />{" "}
                              <Moment format="MM/DD/YYYY hh:mm A">
                                {contentCategory?.updatedAt}
                              </Moment>{" "}
                              <strong className="ml-2">Initials - </strong>
                              {contentCategory?.user?.firstName}{" "}
                            </span>
                          </div>

                          <div className="pt-3">
                            <div className="row">
                              {contentFiles.length ? (
                                this.state.reorderMode ? (
                                  <div className="col-md-12">
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                      <Droppable
                                        droppableId={
                                          this.state.contentCategory._id
                                        }
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className={classNames({
                                              "droppable-container": true,
                                              "bg-skyblue":
                                                snapshot.isDraggingOver,
                                              "p-2": true,
                                            })}
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                          >
                                            {contentFiles.map((file, index) => {
                                              return (
                                                <Draggable
                                                  key={file._id}
                                                  draggableId={file._id}
                                                  index={index}
                                                >
                                                  {(provided, snapshop) => (
                                                    <div
                                                      className={classNames({
                                                        draggable: true,
                                                        "mb-2": true,
                                                        "p-3": true,
                                                        "draggable-bg":
                                                          snapshop.isDragging,
                                                      })}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      ref={provided.innerRef}
                                                    >
                                                      {file.title} -{" "}
                                                      {file.contentType}
                                                    </div>
                                                  )}
                                                </Draggable>
                                              );
                                            })}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                  </div>
                                ) : (
                                  contentFiles?.map((file, index) => {
                                    return (
                                      <div className="col-md-3" key={index}>
                                        <Card>
                                          <div
                                            className="image-parent"
                                            style={{ height: "12rem" }}
                                          >
                                            {this.getThumbnail(file)}
                                          </div>
                                          <Card.Body className="p-1 px-2 card-bg-color">
                                            <Card.Text
                                              className="cursor-pointer mb-0 text-truncate"
                                              onClick={() => {
                                                if (
                                                  file?.courseType !==
                                                  courseTypes.LINK
                                                ) {
                                                  this.getFileFromKey(
                                                    file.key,
                                                    file.title ||
                                                      file.originalname,
                                                    file._id,
                                                  );
                                                }
                                              }}
                                            >
                                              {file?.title
                                                ? file?.title
                                                : file?.originalname}
                                            </Card.Text>
                                            <div
                                              className="d-flex"
                                              style={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              {file?.feedback ? (
                                                <ReactStars
                                                  count={5}
                                                  size={20}
                                                  isHalf={false}
                                                  activeColor="#ffd700"
                                                  edit={false}
                                                  value={
                                                    file.ratings
                                                      ? Math.round(file.ratings)
                                                      : 0
                                                  }
                                                />
                                              ) : (
                                                <div></div>
                                              )}
                                              {this.state.currentUser.role ===
                                                2 && (
                                                <div className="editWrapper">
                                                  <i
                                                    className="mdi mdi-pencil cursor-pointer"
                                                    onClick={() => {
                                                      if (
                                                        file?.courseType ===
                                                        courseTypes.FILE
                                                      ) {
                                                        this.toggleUpdateFileModel(
                                                          file._id,
                                                          file.title,
                                                          file.description,
                                                          file.feedback,
                                                          file.downloadOption,
                                                          file.location,
                                                          file,
                                                          file?.index,
                                                        );
                                                      } else if (
                                                        file?.courseType ===
                                                        courseTypes.QUESTIONER
                                                      ) {
                                                        this.props.history.push(
                                                          `/staff/course-categories/${this.state.courseCategoryId}/category-detail/${this.props.match.params.id}/questions/edit/${file._id}`,
                                                        );
                                                      } else if (
                                                        file?.courseType ===
                                                        courseTypes.LINK
                                                      ) {
                                                        this.toggleUpdateLinkModel(
                                                          file._id,
                                                          file.title,
                                                          file.description,
                                                          file.feedback,
                                                          file.downloadOption,
                                                          file.link,
                                                          file,
                                                          file?.index,
                                                        );
                                                      }
                                                    }}
                                                  />
                                                  <i
                                                    className="mdi mdi-delete cursor-pointer ml-2"
                                                    onClick={() =>
                                                      this.deleteFile(file._id)
                                                    }
                                                    style={{ color: "red" }}
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          </Card.Body>
                                          {/* <div
                                            className="middle cursor-pointer"
                                            style={{ height: "80%" }}
                                            onClick={() => {
                                              if (
                                                file?.courseType !==
                                                  courseTypes.QUESTIONER &&
                                                file?.courseType !==
                                                  courseTypes.LINK
                                              ) {
                                                this.getFileFromKey(
                                                  file.key,
                                                  file.title ||
                                                    file.originalname,
                                                  file._id,
                                                  file,
                                                );
                                              }
                                            }}
                                          >
                                            <div className="file-card-description px-2 pt-2">
                                              {file.description
                                                ? file.description
                                                : "No Information Available"}
                                            </div>
                                          </div> */}
                                        </Card>
                                      </div>
                                    );
                                  })
                                )
                              ) : (
                                <div className="d-flex flex-column justify-content-center align-items-center w-100 no-data">
                                  <img src={notFound} alt="" />
                                  <div className="text mt-3">
                                    No files have been uploaded.
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div>
                            <Modal
                              isOpen={this.state.audioModal}
                              centered="true"
                              toggle={this.toggleAudio}
                            >
                              <ModalHeader toggle={this.toggleAudio}>
                                {this.state.filename}
                              </ModalHeader>
                              <ModalBody>
                                <div className="player-wrapper">
                                  <ReactPlayer
                                    className="react-player"
                                    url={this.state.mediaUrl}
                                    controls
                                    width="100%"
                                    height="55px"
                                  />
                                </div>
                              </ModalBody>
                            </Modal>
                          </div>
                          <div>
                            <Modal
                              isOpen={this.state.videoModal}
                              centered
                              toggle={this.toggleVideo}
                              size="lg"
                            >
                              <ModalHeader toggle={this.toggleVideo}>
                                {this.state.filename}
                              </ModalHeader>
                              <ModalBody className="p-0">
                                <ReactPlayer
                                  className="react-player"
                                  url={this.state.mediaUrl}
                                  // light = {true}
                                  controls
                                  width="100%"
                                  height="100%"
                                />
                              </ModalBody>
                              {/* <ModalFooter>
                                <Button
                                  color="primary"
                                  onClick={this.toggleVideo}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter> */}
                            </Modal>
                          </div>
                          <div>
                            <Modal
                              isOpen={this.state.updateFileModal}
                              toggle={this.toggleUpdateFileModel}
                            >
                              <ModalHeader toggle={this.toggleUpdateFileModel}>
                                Edit File Details
                              </ModalHeader>
                              <ModalBody>
                                <Form>
                                  <FormGroup row>
                                    <Col sm={12}>
                                      <Input
                                        type="file"
                                        name="file"
                                        id="exampleFile"
                                        onChange={this.onChangeFileHandler}
                                      />
                                    </Col>
                                    {this.state.uploadProgress > 0 && (
                                      <Col
                                        sm={12}
                                        style={{ marginTop: "10px" }}
                                      >
                                        <Progress
                                          style={{
                                            height: "17px",
                                            backgroundColor: "#007bff",
                                          }}
                                          value={this.state.uploadProgress}
                                        >
                                          <strong>
                                            {this.state.uploadProgress} %
                                          </strong>
                                        </Progress>
                                      </Col>
                                    )}
                                    <Col sm={12}>
                                      <div className="pt-2">
                                        <Label>Title:</Label>
                                        <Input
                                          type="text"
                                          name="title"
                                          id="edit-title"
                                          value={this.state.title}
                                          onChange={this.handleInputChange}
                                        />
                                      </div>
                                    </Col>
                                    <Col sm={12}>
                                      <div className="pt-2">
                                        <Label>Description:</Label>
                                        <Input
                                          type="textarea"
                                          name="description"
                                          id="edit-description"
                                          value={this.state.description}
                                          onChange={this.handleInputChange}
                                        />
                                      </div>
                                    </Col>
                                    <Col sm={12}>
                                      <div className="pt-2">
                                        <Label>Position:</Label>
                                        <Input
                                          type="text"
                                          name="index"
                                          id="edit-index"
                                          value={this.state.index}
                                          onChange={this.handleInputChange}
                                        />
                                        <p
                                          style={{ color: "#dc3545" }}
                                          className="mb-1"
                                        >
                                          Updating the index will impact
                                          completed courses on the customer's
                                          end.
                                        </p>
                                      </div>
                                    </Col>
                                    <Col sm={12}>
                                      <div className="pt-2 form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="feedback"
                                          name="feedback"
                                          checked={this.state.feedback}
                                          onChange={this.handleCheckboxChange}
                                          disabled={
                                            this.state.uploadProgress > 0
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="feedback"
                                        >
                                          Take Feedback
                                        </label>
                                      </div>
                                    </Col>
                                    <Col sm={12}>
                                      <div className="pt-2 form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="downloadOption"
                                          name="downloadOption"
                                          checked={this.state.downloadOption}
                                          onChange={this.handleCheckboxChange}
                                          disabled={
                                            this.state.uploadProgress > 0
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="downloadOption"
                                        >
                                          Give Download Option
                                        </label>
                                        <small
                                          id="downloadOptionHelp"
                                          className="form-text"
                                        >
                                          Only applicable for video and audio
                                          files.
                                        </small>
                                      </div>
                                    </Col>
                                  </FormGroup>
                                </Form>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="primary"
                                  onClick={this.updateFileDetails}
                                >
                                  Save
                                </Button>{" "}
                                <Button
                                  color="secondary"
                                  onClick={this.toggleUpdateFileModel}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </div>
                          <div>
                            <Modal
                              isOpen={this.state.updateFileModal2}
                              toggle={this.toggleUpdateLinkModel}
                            >
                              <ModalHeader toggle={this.toggleUpdateLinkModel}>
                                Edit Link Details
                              </ModalHeader>
                              <ModalBody>
                                <Form>
                                  <FormGroup row>
                                    <Col sm={12}>
                                      <Input
                                        type="text"
                                        name="link"
                                        id="exampleLink"
                                        value={this.state.link}
                                        onChange={(event) =>
                                          this.setState({
                                            link: event.target.value,
                                          })
                                        }
                                      />
                                    </Col>

                                    <Col sm={12}>
                                      <div className="pt-2">
                                        <Label>Title:</Label>
                                        <Input
                                          type="text"
                                          name="title"
                                          id="edit-title"
                                          value={this.state.title}
                                          onChange={this.handleInputChange}
                                        />
                                      </div>
                                    </Col>
                                    <Col sm={12}>
                                      <div className="pt-2">
                                        <Label>Description:</Label>
                                        <Input
                                          type="textarea"
                                          name="description"
                                          id="edit-description"
                                          value={this.state.description}
                                          onChange={this.handleInputChange}
                                        />
                                      </div>
                                    </Col>
                                    <Col sm={12}>
                                      <div className="pt-2">
                                        <Label>Position:</Label>
                                        <Input
                                          type="text"
                                          name="index"
                                          id="edit-index"
                                          value={this.state.index}
                                          onChange={this.handleInputChange}
                                        />
                                        <p
                                          style={{ color: "#dc3545" }}
                                          className="mb-1"
                                        >
                                          Updating the index will impact
                                          completed courses on the customer's
                                          end.
                                        </p>
                                      </div>
                                    </Col>
                                    <Col sm={12}>
                                      <div className="pt-2 form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="feedback"
                                          name="feedback"
                                          checked={this.state.feedback}
                                          onChange={this.handleCheckboxChange}
                                          disabled={
                                            this.state.uploadProgress > 0
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="feedback"
                                        >
                                          Take Feedback
                                        </label>
                                      </div>
                                    </Col>
                                    <Col sm={12}>
                                      <div className="pt-2 form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="downloadOption"
                                          name="downloadOption"
                                          checked={this.state.downloadOption}
                                          onChange={this.handleCheckboxChange}
                                          disabled={
                                            this.state.uploadProgress > 0
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="downloadOption"
                                        >
                                          Give Download Option
                                        </label>
                                        <small
                                          id="downloadOptionHelp"
                                          className="form-text"
                                        >
                                          Only applicable for video and audio
                                          files.
                                        </small>
                                      </div>
                                    </Col>
                                  </FormGroup>
                                </Form>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="primary"
                                  onClick={this.updateLinkDetails}
                                >
                                  Save
                                </Button>{" "}
                                <Button
                                  color="secondary"
                                  onClick={this.toggleUpdateLinkModel}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </div>
                          <div>
                            <CustomModal
                              open={this.state.fileViewer}
                              onCloseModal={this.togglefileViewer}
                              fileType={this.state.fileType}
                              filePath={this.state.mediaUrl}
                              fileName={this.state.filename}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetail);
