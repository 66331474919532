import config from "../../config";
import { authHeader, handleResponse } from "../../_helpers";
import { authenticationService } from "../authentication.service";
import { encData } from "../../_helpers/_helperFunctions";
export const appointmentService = {
  addAvailabilities,
  counsellorAvailabilityByDate,
  getAllBookings,
  approveAppointment,
  rejectAppointment,
  getAllBookingsbyCounsellorId,
  getAvailableCounsellors,
  getAppointmentSlots,
  bookAppointment,
  getCounsellorAvailabilityByDate,
  getAllAvailabilityByDate,
  updateAvailability,
  updateBookingNotes,
  updateBookingCallStatus,
  getBookingById,
  cancelBooking,
  assignWalkinAppointment,
  getAllBookingsHistory,
  setContactStatus,
  getCounsellorAppointmentsStats,
  getCounsellorPersonalStats,
  getCounsellorsWithAvailabilities,
  addBookSlot,
  deleteSelctedSlot,
  pendingAppointment,
  deleteSlotOnLogOut,
  addSlotTypeByBookingId,
  deleteSlotTypeByBookingId,
};

function addAvailabilities(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/addAvailabilities`,
    requestOptions,
  ).then(handleResponse);
}
function addBookSlot(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/check-bookSlot/addBookSlot`,
    requestOptions,
  ).then(handleResponse);
}

function pendingAppointment(time) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/check-bookSlot/getPendingAppointment/${time}`,
    requestOptions,
  ).then(handleResponse);
}

function deleteSelctedSlot(data) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/check-bookSlot/deleteBookedSlot`,
    requestOptions,
  ).then(handleResponse);
}

function deleteSlotOnLogOut(data) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/check-bookSlot/deleteSlotOnLogOut`,
    requestOptions,
  ).then(handleResponse);
}
function updateAvailability(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/updateAvailability`,
    requestOptions,
  ).then(handleResponse);
}

function counsellorAvailabilityByDate(counsellorid, date) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/${counsellorid}/${date}`,
    requestOptions,
  ).then(handleResponse);
}

function getAllAvailabilityByDate(date) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/getAllAvailability/${date}`,
    requestOptions,
  ).then(handleResponse);
}

function getAllBookings() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/getAllBookings`,
    requestOptions,
  ).then(handleResponse);
}

function approveAppointment(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/approve`,
    requestOptions,
  ).then(handleResponse);
}

function rejectAppointment(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/reject`,
    requestOptions,
  ).then(handleResponse);
}

function getAllBookingsbyCounsellorId(counsellor, timestamp, type) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/getAllBookingsbyCounsellorId/${counsellor}/${timestamp}?type=${type}`,
    requestOptions,
  ).then(handleResponse);
}

function getAvailableCounsellors(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/getAvailableCounsellors`,
    requestOptions,
  ).then(handleResponse);
}

function getAppointmentSlots(date) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/getAppointmentSlots/${date}`,
    requestOptions,
  ).then(handleResponse);
}

function bookAppointment(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/bookAppointment`,
    requestOptions,
  ).then(handleResponse);
}

function getCounsellorAvailabilityByDate(counsellor, date) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/getCounsellorAvailabilityByDate/${counsellor}/${date}`,
    requestOptions,
  ).then(handleResponse);
}

function updateBookingNotes(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/updateBookingNotes`,
    requestOptions,
  ).then(handleResponse);
}

function updateBookingCallStatus(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/updateBookingCallStatus`,
    requestOptions,
  ).then(handleResponse);
}

function getBookingById(bookingId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/getBookingById/${bookingId}`,
    requestOptions,
  ).then(handleResponse);
}
function addSlotTypeByBookingId(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/addSlotType`,
    requestOptions,
  ).then(handleResponse);
}
function deleteSlotTypeByBookingId(data) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/deleteSlotTypeByBookingId`,
    requestOptions,
  ).then(handleResponse);
}

function cancelBooking(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/cancel`,
    requestOptions,
  ).then(handleResponse);
}

function assignWalkinAppointment(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/assignWalkinAppointment`,
    requestOptions,
  ).then(handleResponse);
}

function getAllBookingsHistory() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/getAllBookingsHistory`,
    requestOptions,
  ).then(handleResponse);
}

function setContactStatus(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/setContactStatus`,
    requestOptions,
  ).then(handleResponse);
}

function getCounsellorAppointmentsStats(timestamp) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/getCounsellorAppointmentsStats/${timestamp}`,
    requestOptions,
  ).then(handleResponse);
}

function getCounsellorPersonalStats(counsellorId, timestamp) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/getCounsellorPersonalStats/${counsellorId}/${timestamp}`,
    requestOptions,
  ).then(handleResponse);
}

function getCounsellorsWithAvailabilities(timestamp) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/appointments/getCounsellorsWithAvailabilities/${timestamp}`,
    requestOptions,
  ).then(handleResponse);
}
