import EncDec from "../e2e/e2e_functions/index";
import moment from "moment";
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const isEmpty = (value) => {
  return (
    value == null || // From standard.js: Always use === - but obj == null is allowed to check null || undefined
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const encData = function (data) {
  console.log("encData::", data);
  let isenc = localStorage.getItem("isenc")
    ? parseInt(localStorage.getItem("isenc"))
    : 0;
  if (isenc === 1) {
    return JSON.stringify({
      enc_string: EncDec.encryptResponse(JSON.stringify(data)),
    });
  } else {
    return JSON.stringify(data);
  }
};

export const capitalizeAndJoin = (data) => {
  let capitalizedWordsList = data.map((elem) => {
    return capitalize(elem);
  });

  return capitalizedWordsList.join(", ");
};

export const hasDaysPassed = (date, days) => {
  let givenDate = moment(date).add(days, "days");
  let currentDate = moment();
  return currentDate.isAfter(givenDate);
};

// export const holydayList = [
//   "01/01/2021",
//   "02/15/2021",
//   "04/02/2021",
//   "04/05/2021",
//   "05/24/2021",
//   "07/01/2021",
//   "08/02/2021",
//   "09/06/2021",
//   "10/11/2021",
//   "12/24/2021",
//   "12/25/2021",
//   "12/27/2021",
//   "12/28/2021",
//   "12/31/2021",
// ];

export const holidayDateList = (holidayList) => {
  if (holidayList && holidayList.length) {
    return holidayList.map((item) => {
      return moment(item.date, "MM/DD/YYYY").toDate();
    });
  } else {
    return [];
  }
};

export const removeAllDotsButLast = (str) => {
  return str.replace(/[.](?=.*[.])/g, "-");
};

export const extractFileName = (fullPath) => {
  const fullPathArray = fullPath.split("\\");
  const filenameWithExtension = fullPathArray[fullPathArray.length - 1];
  return filenameWithExtension;
};

export const extractFileExtension = (fullPath) => {
  const filenameWithExtension = fullPath.split("\\").pop();
  const extension = filenameWithExtension.split(".").pop();
  return extension;
};

export const getFileExtension = (filename) => {
  return filename.split(".").pop();
};

export const addDaysToDate = (date, days) => {
  return moment(date).add(days, "days").toDate();
};

export const getLastFiveYearsOptionList = () => {
  let list = [];
  let currentMoment = moment();
  list.push({
    label: currentMoment.format("YYYY"),
    value: currentMoment.format("YYYY"),
  });
  for (let i = 0; i < 5; i++) {
    let yearString = currentMoment.subtract(1, "years").format("YYYY");
    list.push({
      label: yearString,
      value: yearString,
    });
  }
  return list;
};

export const getTimeStringBySec = (seconds) => {
  if (seconds < 60) {
    return `${seconds} sec`;
  } else {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes < 60) {
      return `${minutes} min ${remainingSeconds} sec`;
    } else {
      const hrs = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;

      return `${hrs} hr ${remainingMinutes} min`;
    }
  }
};

export const getTimeStringBySecInMin = (seconds) => {
  if (seconds < 60) {
    return `${seconds} sec`;
  } else {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes} min ${remainingSeconds} sec`;
  }
};
