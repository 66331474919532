/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Formik, Field, ErrorMessage } from "formik";
import MaskedInput from "react-text-mask";
import * as Yup from "yup";
import PasswordMask from "react-password-mask";
// import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import { userService } from "../../_services/admin";
// import mime from "mime-types";
import * as _helpers from "../../_helpers";
import { COMPANY_NAME } from "../../_helpers/companyName";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

function validateEmail(value) {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
}

function getAccessModules(modules) {
  if (modules && modules.length) {
    const modulesList = [];
    modules.forEach((elem) => {
      const key = Object.keys(_helpers.ACCESS_MODULES).find(
        (key) => _helpers.ACCESS_MODULES[key] === elem,
      );
      if (key) {
        modulesList.push({ label: key, value: elem });
      }
    });
    return modulesList;
  }
  return [];
}

class CreateUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      user: {},
      department: [],
      subDepartment: [],
      userList: [],
      selectedDepartment: [],
      selectedSubDepartment: [],
      loading: true,
      profilepicture: "",
      url: "",
      success: false,
      imageLoading: false,
      showImgError: false,
    };
  }

  componentDidMount() {
    const scripts = ["/assets/js/custom.min.js"];
    const host = window.location.origin;
    scripts.map((t) => {
      const script = document.createElement("script");
      script.src = host + t;
      script.async = true;
      document.body.appendChild(script);
      return true;
    });
    // this.getAllUsers();
    this.props.setUserData(this.state.currentUser);
    this.props.match.params.id &&
      userService
        .getUserById(this.props.match.params.id)
        .then((data) => {
          if (data.Data.profileImageUrl) {
            this.setState({
              user: data.Data,
              accessModules: getAccessModules(data.Data.accessModules),
              loading: false,
              profilepicture: data.Data.profileImageUrl,
              url: data.Data.profileImageUrl,
            });
          } else {
            this.setState({
              user: data.Data,
              loading: false,
              accessModules: getAccessModules(data?.Data?.accessModules),
              profilepicture: data?.Data?.profileImageUrl,
              url: data?.Data?.profileImageUrl,
            });
          }

          setTimeout(() => {
            const supervisorId = this.state.user.supervisor;
            this.state.userList.forEach((item) => {
              if (item?._id === supervisorId) {
                this.setState({
                  selectedSupervisor: {
                    label: `${item?.firstName} ${item?.lastName}`,
                    value: item?._id,
                  },
                });
              }
            });
          }, 600);
        })
        .catch((error) => console.log("Error", error));
    this.setState({ loading: false });
  }

  // getAllUsers = () => {
  //   userService
  //     .getUserList()
  //     .then((data) => {
  //       if (data.Data) {
  //         this.setState({ userList: data.Data });
  //       }
  //     })
  //     .catch((error) => console.log("Error", error));
  // };

  profileImageEdit = () => {
    this.setState({
      profilepicture: "",
      url: "",
    });
  };

  onChangeFileHandler = (event) => {
    this.setState({
      profilepicture: "",
      url: "",
      imageLoading: true,
      showImgError: false,
    });
    if (event.target.files[0]) {
      document.getElementById("img_url").src = (
        window.URL ? URL : window.webkitURL
      ).createObjectURL(event.target.files[0]);
      const file = event.target.files[0];
      // Split the filename to get the name and type
      const fileName = `user-profile/${event.target.files[0].name}`;
      const fileType = event.target.files[0].type;

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/v1/users/profile-image`,
          {
            fileName,
            fileType,
          },
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`,
              isenc: localStorage.getItem("isenc")
                ? parseInt(localStorage.getItem("isenc"), 10)
                : 0,
            },
          },
        )
        .then((response) => {
          const returnData = response.data.Data;
          const { signedRequest } = returnData;
          const { url } = returnData;
          this.setState({ url });

          // Put the fileType in the headers for the upload
          const options = {
            headers: {
              "Content-Type": fileType,
            },
          };
          axios
            .put(signedRequest, file, options)
            .then(() => {
              this.setState({ success: true, imageLoading: false });
            })
            .catch((error) => {
              alert(`ERROR ${JSON.stringify(error)}`);
            });
        })
        .catch((error) => {
          alert(JSON.stringify(error));
        });
    } else {
      document.getElementById("img_url").src = "";
      this.setState({
        url: "",
        imageLoading: false,
        showImgError: true,
      });
      // document.getElementById("validimage").innerHTML = "Choose Your Image"
    }
  };

  render() {
    const organizationList = ["WEST", "LMS"];
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        {this.state.currentUser.role === _helpers.Role.Admin ? (
          <SidebarSuperAdmin />
        ) : (
          <Sidebaar />
        )}
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">
                  {!this.state.user._id ? "Create Staff" : "Edit Staff"}
                </h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Create Staff</li>
                </ol>
              </div>
            </div>
            <div className="row mt-4row justify-content-center mt-4">
              <div className="col-md-6">
                <div className="card">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      userId: this.state?.user?._id || "",
                      firstName: this.state?.user?.firstName || "",
                      lastName: this.state?.user?.lastName || "",
                      password: this.state?.user?.password || "",
                      email: this.state?.user?.email || "",
                      // currentUserId: this.state.currentUser.id,
                      jobTitle: this.state?.user?.jobTitle || "",
                      organization: "WEST",
                      isActive: this.state?.user?.isActive || 1,
                    }}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string().required("Name is required"),
                      lastName: Yup.string().required("Lastname is required"),
                      password: Yup.string().when({
                        is: () => !!this.state.user._id,
                        then: Yup.string().when({
                          is: (inVal) => inVal?.length > 0,
                          then: Yup.string()
                            .matches(
                              /^(?=.*[a-z])(?=.*[A-Z])/,
                              "Must have both uppercase and lowercase letters",
                            )
                            .matches(/^(?=.*[0-9])/, "Must consist of a number")
                            .matches(
                              /^(?=.{8,})/,
                              "Must be atleast 8 character long",
                            )
                            .required("Password is required"),
                        }),
                        otherwise: Yup.string()
                          .matches(
                            /^(?=.*[a-z])(?=.*[A-Z])/,
                            "Must have both uppercase and lowercase letters",
                          )
                          .matches(/^(?=.*[0-9])/, "Must consist of a number")
                          .matches(
                            /^(?=.{8,})/,
                            "Must be atleast 8 character long",
                          )
                          .required("Password is required"),
                      }),
                      email: Yup.string().required("Email is required"),
                      jobTitle: Yup.string().required("Job Title is required"),
                    })}
                    onSubmit={(formData, { setStatus, setSubmitting }) => {
                      formData.role = 2;
                      if (formData.userId === "") delete formData.userId;

                      userService
                        .create(formData)
                        .then(() => {
                          const { from } = this.props.location.state || {
                            from: { pathname: "/admin/users" },
                          };
                          this.props.history.push(from);
                        })
                        .catch((error) => {
                          setSubmitting(false);
                          setStatus(error);
                        });
                    }}
                    render={({
                      errors,
                      status,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      values,
                      handleChange,
                    }) => (
                      <Form>
                        <div className="card-body">
                          <div className="row form-material">
                            <div className="col-md-12">
                              <div className="form-group required">
                                <label className="control-label">
                                  First Name
                                </label>
                                <Field
                                  name="firstName"
                                  type="text"
                                  placeholder="Name"
                                  className={`form-control${
                                    errors.firstName && touched.firstName
                                      ? " is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group required">
                                <label className="control-label">
                                  {" "}
                                  Last Name
                                </label>
                                <Field
                                  name="lastName"
                                  type="text"
                                  placeholder="Last Name"
                                  className={`form-control${
                                    errors.lastName && touched.lastName
                                      ? " is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="lastName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group required">
                                <label className="control-label">Email</label>
                                <Field
                                  name="email"
                                  type="text"
                                  placeholder="Email"
                                  validate={validateEmail}
                                  className={`form-control${
                                    errors.email && touched.email
                                      ? " is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div
                                className={
                                  !this.state.user._id
                                    ? "form-group required"
                                    : "form-group"
                                }
                              >
                                <label className="control-label">
                                  Password
                                </label>
                                <Field
                                  name="password"
                                  placeholder="Password"
                                  autoComplete="off"
                                  render={({ field }) => (
                                    <PasswordMask
                                      inputStyles={{ border: "none" }}
                                      buttonStyles={{
                                        top: "5px",
                                        backgroundColor: "transparent",
                                      }}
                                      showButtonContent={
                                        <i
                                          className="ti-eye"
                                          style={{
                                            color: "#ccc",
                                            backgroundColor: "transparent",
                                          }}
                                        />
                                      }
                                      hideButtonContent={
                                        <i
                                          className="ti-eye"
                                          style={{
                                            backgroundColor: "transparent",
                                          }}
                                        />
                                      }
                                      onChange={(password) =>
                                        password === null
                                          ? setFieldValue("password", "")
                                          : setFieldValue("password", password)
                                      }
                                      {...field}
                                      className={`form-control${
                                        errors.password && touched.password
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group required">
                                <label className="control-label d-inline-block w-100">
                                  Job Title
                                </label>
                                <Field
                                  name="jobTitle"
                                  type="text"
                                  autoComplete="off"
                                  className={`form-control${
                                    errors.jobTitle && touched.jobTitle
                                      ? " is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name="jobTitle"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer accordionfooter d-flex">
                          <div style={{ marginRight: "5px" }}>
                            <Link
                              to="/admin/users"
                              className="btn btn-secondary"
                              style={{
                                backgroundColor: "grey",
                                color: "white",
                              }}
                            >
                              {/* <i className="mdi mdi-chevron-left"></i> */}
                              <span className="">Cancel</span>
                            </Link>
                          </div>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary"
                          >
                            {" "}
                            {!this.state.user._id
                              ? "Save User"
                              : "Update User"}{" "}
                          </button>
                          {isSubmitting && (
                            <img
                              alt=""
                              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                            />
                          )}
                        </div>
                        {status && (
                          <div className="alert alert-danger">{status}</div>
                        )}
                      </Form>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
