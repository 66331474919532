import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { contentLibraryService } from "../../_services/staff";
import { MDBDataTable, MDBIcon } from "mdbreact";
import moment from "moment";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import { eventService } from "../../_services/staff";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import swal from "sweetalert";
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

export class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      userFromApi: null,
      events: [],
      pastEvents: [],
      days: 15,
      eventDetails: [],
      loading: true,
      modal: false,
    };
  }

  componentDidMount() {
    const { currentUser } = this.state;
    this.getAllEvents();
    this.getAllPastEvents();
  }

  deleteEvent(id, isPastEvent) {
    let data = {
      eventId: id,
      currentUserId: this.state.currentUser.id,
    };
    eventService
      .deleteEvent(data)
      .then((data) => {
        if (isPastEvent) {
          this.getAllPastEvents();
        } else {
          this.getAllEvents();
        }
        swal("Event Deleted Succesfully!", "", "success");
      })
      .catch((error) => {
        console.log("error: ", error);
        swal("Oops Something Went Wrong!", "", "error");
      });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  getDetails = (event) => {
    this.setState({
      eventDetails: event,
    });
    this.toggle();
  };

  getAllEvents() {
    eventService
      .getAllUpcomingEvents(new Date().getTime(), "event")
      .then((data) => {
        let rowsArray = [];
        data &&
          data.Data &&
          data.Data.forEach((item, i) => {
            rowsArray.push({
              key: i + 1,
              eventname: item.name,
              date: moment(
                item.dateTime ? item.dateTime : item.startTime,
              ).format("MM/DD/YYYY"),
              starttime: moment(item.startTime).format("hh:mm A"),
              endtime: moment(item.endTime).format("hh:mm A"),
              action: (
                <React.Fragment>
                  <Link
                    to={"/staff/events/edit/" + item._id}
                    data-toggle="tooltip"
                    data-original-title="Edit"
                  >
                    <i className="fas fa-pencil-alt m-r-20 text-muted"></i>
                  </Link>
                  <a
                    className="cursor_pointer"
                    // onClick={() => this.deleteCategory(item._id)}
                    onClick={() => document.getElementById(item._id).click()}
                    data-toggle="tooltip"
                    data-original-title="Delete"
                  >
                    <i className="fas fa-trash text-muted "></i>
                    <DeleteConfirmation
                      id={item._id}
                      confirm={() => this.deleteEvent(item._id)}
                    ></DeleteConfirmation>
                  </a>
                </React.Fragment>
              ),
            });
          });
        let tableData = {
          columns: [
            // {
            //     label: "No.",
            //     field: "key",
            //     sort: "asc",
            //     width: 270,
            // },
            {
              label: "Event Name",
              field: "eventname",
              sort: "asc",
              width: 150,
            },
            {
              label: "Date",
              field: "date",
              sort: "asc",
              width: 270,
            },
            {
              label: "Start Time",
              field: "starttime",
              sort: "asc",
              width: 150,
            },
            {
              label: "End Time",
              field: "endtime",
              sort: "asc",
              width: 150,
            },
            {
              label: "Action",
              field: "action",
              sort: "disabled",
              width: 270,
            },
          ],
          rows: rowsArray,
        };
        if (this.state.currentUser.accessType == 1) {
          tableData.columns.pop();
          this.setState({ events: tableData, loading: false });
        } else {
          this.setState({ events: tableData, loading: false });
        }
      });
  }

  getAllPastEvents(days) {
    eventService
      .getAllPastEvents(new Date().getTime(), days || this.state.days, "event")
      .then((data) => {
        let rowsArray = [];
        data &&
          data.Data &&
          data.Data.forEach((item, i) => {
            rowsArray.push({
              key: i + 1,
              eventname: item.name,
              date: moment(
                item.dateTime ? item.dateTime : item.startTime,
              ).format("MM/DD/YYYY"),
              starttime: moment(item.startTime).format("hh:mm A"),
              endtime: moment(item.endTime).format("hh:mm A"),
              action: (
                <React.Fragment>
                  <Link
                    to={"/staff/events/edit/" + item._id}
                    data-toggle="tooltip"
                    data-original-title="Edit"
                  >
                    <i className="fas fa-pencil-alt m-r-20 text-muted"></i>
                  </Link>
                  <a
                    className="cursor_pointer"
                    // onClick={() => this.deleteCategory(item._id)}
                    onClick={() => document.getElementById(item._id).click()}
                    data-toggle="tooltip"
                    data-original-title="Delete"
                  >
                    <i className="fas fa-trash text-muted "></i>
                    <DeleteConfirmation
                      id={item._id}
                      confirm={() => this.deleteEvent(item._id, true)}
                    ></DeleteConfirmation>
                  </a>
                </React.Fragment>
              ),
            });
          });
        let tableData = {
          columns: [
            // {
            //     label: "No.",
            //     field: "key",
            //     sort: "asc",
            //     width: 270,
            // },
            {
              label: "Event Name",
              field: "eventname",
              sort: "asc",
              width: 150,
            },
            {
              label: "Date",
              field: "date",
              sort: "asc",
              width: 270,
            },
            {
              label: "Start Time",
              field: "starttime",
              sort: "asc",
              width: 150,
            },
            {
              label: "End Time",
              field: "endtime",
              sort: "asc",
              width: 150,
            },
            {
              label: "Action",
              field: "action",
              sort: "disabled",
              width: 270,
            },
          ],
          rows: rowsArray,
        };
        if (this.state.currentUser.accessType == 1) {
          tableData.columns.pop();
          this.setState({ pastEvents: tableData, loading: false });
        } else {
          this.setState({ pastEvents: tableData, loading: false });
        }
      });
  }

  handleDaysChange = (e) => {
    this.setState({ days: e.target.value });
    this.getAllPastEvents(e.target.value);
  };

  render() {
    const { events, pastEvents } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Events</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card EventList">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-calendar-text mr-2"></i>
                          <span>Events</span>
                        </div>
                        {this.state.currentUser.accessType == 2 && (
                          <div>
                            <Link
                              to="/staff/events/add"
                              className="btn btn-primary"
                            >
                              <MDBIcon fas icon="plus" className="mr-2" />
                              <span className="hide-menu">Add Event</span>
                            </Link>
                          </div>
                        )}
                      </div>
                      <hr />
                      {events.rows?.length ? (
                        <MDBDataTable
                          responsive
                          striped
                          bordered
                          hover
                          data={events}
                        />
                      ) : (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="text-center">
                              <h3>No Upcoming Events</h3>
                            </div>
                          </div>
                        </div>
                      )}
                      <div>
                        <Modal
                          isOpen={this.state.modal}
                          centered={true}
                          toggle={this.toggle}
                        >
                          <ModalHeader toggle={this.toggle}>
                            Details of Event/Workshop
                          </ModalHeader>
                          <ModalBody>
                            {/* {this.state.appointmentDetails.status == 3 ? */}
                            {/* <div className="full_width details_modal d-flex space_between">
                                                            <div className="" >
                                                                <div>Facilitator's Name</div>
                                                                <div>{this.state.eventDetails.facilitatorName}</div>
                                                            </div>
                                                            <div className="" >
                                                                <div>Event/Workshop Name</div>
                                                                <div>{this.state.eventDetails.name}</div>
                                                            </div>

                                                        </div> */}

                            <div className="">
                              {/* <div className="full_width details_modal d-flex space_between">
                                                                <div className="">
                                                                    <div>Date</div>
                                                                    {this.state.eventDetails.date? 
                                                                    this.state.eventDetails.date.map(date => <div>{moment(date).format('MM/DD/YYYY')}</div>):
                                                                    <div>{moment(this.state.eventDetails.startTime).format('MM/DD/YYYY')}</div>
                                                                    }
                                                                    
                                                                </div>
                                                                <div className="" style={{paddingRight: "5.5%"}}>
                                                                    <div >Time</div>
                                                                    <div>{moment(this.state.eventDetails.startTime).format('hh:mm A')+  "-" +
                                                                          moment(this.state.eventDetails.endTime).format('hh:mm A')
                                                                    }</div>
                                                                </div>
                                                            </div> */}
                              <div className="full_width details_modal">
                                <div>Description</div>
                                <div>{this.state.eventDetails.description}</div>
                              </div>
                            </div>

                            <div className="full_width details_modal d-flex space_between">
                              <div className="">
                                <div>Location</div>
                                <div>{this.state.eventDetails.location}</div>
                              </div>
                              {/* <div className="" style={{paddingRight: "3%"}}>
                                                                <div>Participants capacity</div>
                                                                <div>{this.state.eventDetails.participantsCapacity}</div>
                                                            </div> */}
                            </div>
                            <div className="full_width details_modal">
                              <div>Access Link</div>
                              <div>{this.state.eventDetails.accessLink}</div>
                            </div>
                            {/* <div className="full_width details_modal">
                                                                <div>participant Count</div>
                                                                <div>{this.state.eventDetails.participantCount}</div>
                                                            </div> */}
                            {/* } */}
                          </ModalBody>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card pastEventList">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="align-self-center tbl-head">
                          <i className="mdi mdi-calendar-text mr-2"></i>
                          <span>Past Events</span>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-3">
                          <select
                            className="form-control"
                            value={this.state.days}
                            onChange={this.handleDaysChange}
                          >
                            <option value="15">15 days</option>
                            <option value="30">30 days</option>
                            <option value="60">60 days</option>
                            <option value="90">90 days</option>
                          </select>
                        </div>
                      </div>
                      {pastEvents.rows?.length ? (
                        <MDBDataTable
                          responsive
                          striped
                          bordered
                          hover
                          data={pastEvents}
                        />
                      ) : (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="text-center">
                              <h3>No Past Events</h3>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Events);
