import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import { contentLibraryService } from "../../_services/staff";
import Select from "react-select";
import { Calendar } from "primereact/calendar";
import { eventService } from "../../_services/staff";
import * as Yup from "yup";
import moment from "moment";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { addDaysToDate } from "../../_helpers/_helperFunctions";
import { Editor } from "primereact/editor";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class AddWorkshop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      event: {},
      loading: true,
      datetime: null,
      staffMembers: [],
      name: "",
      eventname: "",
      description: "",
      location: "",
      accesslink: "",
      capacity: "",
      starttime: "",
      endtime: "",
      eventid: null,
      learn: "",
    };
  }

  componentDidMount() {
    this.props.setUserData(this.state.currentUser);
    this.props.match.params.id &&
      eventService.getEventById(this.props.match.params.id).then((data) => {
        let res = {
          label: data.Data.facilitatorName,
          value: data.Data.facilitatorId,
          image: data.Data.facilitatorImage,
        };
        //   this.setState({ event: data.Data, loading: false });
        this.setState({
          event: data.Data,
          eventid: data.Data._id,
          name: res,
          eventname: data.Data.name,
          description: data.Data.description,
          location: data.Data.location,
          accesslink: data.Data.accessLink,
          capacity: data.Data.participantsCapacity,
          date: new Date(data.Data.dateTime),
          starttime: moment(data.Data.startTime),
          endtime: moment(data.Data.endTime),
          recurrence: data.Data.recurrence,
          recurrenceEndDate: new Date(data.Data.recurrenceEndDate),
          recurrenceFrequency: data.Data.recurrenceFrequency,
          learn: data.Data.whatWillYouLearn,
          loading: false,
        });
      });
    this.getStaff();
    this.setState({ loading: false });
  }

  handleChange = (selectedOption) => {
    this.setState({ name: selectedOption });
  };

  getStaff = () => {
    eventService.getAllStaffFacilitator().then((data) => {
      this.setState({
        staffMembers: data.Data,
      });
    });
  };

  render() {
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {/* <Preloader /> */}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">
                  {!this.state.event._id ? "Add Workshop" : "Edit Workshop"}
                </h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Workshops</li>
                  <li className="breadcrumb-item active">Add Workshop</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="pt-4">
                  <div className="card">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        name: this.state.name || "",
                        eventname: this.state.eventname || "",
                        description: this.state.description || "",
                        capacity: this.state.capacity || "",
                        location: this.state.location || "",
                        accesslink: this.state.accesslink || "",
                        currentUserId: this.state.currentUser.id,
                        date: this.state.date || null,
                        starttime: this.state.starttime || null,
                        endtime: this.state.endtime || null,
                        recurrence: this.state.recurrence || false,
                        recurrenceEndDate: this.state.recurrenceEndDate,
                        recurrenceFrequency:
                          this.state.recurrenceFrequency || "",
                        learn: this.state.learn || "",
                        recurrenceEdit: false,
                        recurrenceGroupId:
                          this.state.event.recurrenceGroupId || "",
                      }}
                      validationSchema={Yup.object().shape({
                        name: Yup.string().required(
                          "Facilitator's Name is required"
                        ),
                        eventname: Yup.string().required(
                          "Workshop Name is required"
                        ),
                        description: Yup.string().required(
                          "Description is required"
                        ),
                        //location: Yup.string().required("Location is required"),
                        accesslink: Yup.string().required(
                          "Access Link is required"
                        ),
                        //capacity: Yup.string().required("Capacity is required"),
                        date: Yup.string().required("Date is required"),
                        starttime: Yup.string().required(
                          "Start Time is required"
                        ),
                        endtime: Yup.string().required("End Time is required"),
                        learn: Yup.string().required(
                          "What you will learn is required"
                        ),
                      })}
                      onSubmit={(formData, { setStatus, setSubmitting }) => {
                        let startMomentObj = moment(
                          moment(formData.date).format("MM/DD/YYYY") +
                            " " +
                            moment(formData.starttime).format("HH:mm"),
                          "MM/DD/YYYY HH:mm"
                        );
                        let endMomentObj = moment(
                          moment(formData.date).format("MM/DD/YYYY") +
                            " " +
                            moment(formData.endtime).format("HH:mm"),
                          "MM/DD/YYYY HH:mm"
                        );
                        let startMilisecOfMoment = startMomentObj.valueOf();
                        let endMilisecOfMoment = endMomentObj.valueOf();

                        let data = {
                          facilitatorId: this.state.name.value,
                          facilitatorName: this.state.name.label,
                          facilitatorImage: this.state.name.image,
                          name: formData.eventname,
                          description: formData.description,
                          location: formData.location,
                          accessLink: formData.accesslink,
                          participantsCapacity: formData.capacity,
                          dateTime: moment(
                            moment(formData.date).format("MM/DD/YYYY") +
                              " " +
                              moment(formData.starttime).format("HH:mm"),
                            "MM/DD/YYYY HH:mm"
                          ).valueOf(),
                          startTime: startMilisecOfMoment,
                          endTime: endMilisecOfMoment,
                          whatWillYouLearn: formData.learn,
                          recurrence: formData.recurrenceFrequency
                            ? true
                            : false,
                          recurrenceEndDate: formData.recurrenceFrequency
                            ? formData.recurrenceEndDate
                            : null,
                          recurrenceFrequency: formData.recurrenceFrequency,
                          recurrenceGroupId: formData.recurrenceGroupId,
                          recurrenceEdit: formData.recurrenceEdit,
                          currentUserId: formData.currentUserId,
                          eventId: this.state.eventid,
                          type: "workshop",
                        };
                        console.log("event form data: ", data);
                        // setStatus();

                        eventService.createUpdateEvent(data).then(
                          (data) => {
                            this.setState({
                              datetime: null,
                              staffMembers: [],
                              name: "",
                              eventname: "",
                              description: "",
                              location: "",
                              accesslink: "",
                              capacity: "",
                              starttime: "",
                              endtime: "",
                              eventid: null,
                              learn: "",
                            });
                            const { from } = this.props.location.state || {
                              from: { pathname: "/staff/workshops/list" },
                            };
                            this.props.history.push(from);
                            swal("Workshop Added Succesfully!", "", "success");
                            setSubmitting(false);
                          },
                          (error) => {
                            setSubmitting(false);
                            setStatus(error);
                          }
                        );
                      }}
                      render={({
                        errors,
                        status,
                        touched,
                        isSubmitting,
                        values,
                        setFieldValue,
                      }) => (
                        <Form>
                          <div className="card-body">
                            <div className="row form-material">
                              <div className="col-md-12">
                                <div className="form-group required">
                                  <label
                                    className="control-label"
                                    style={{ marginBottom: "8px" }}
                                  >
                                    Facilitator's Name
                                  </label>
                                  <Field
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    className={
                                      "form-control" +
                                      (errors.name && touched.name
                                        ? " is-invalid"
                                        : "")
                                    }
                                    render={({ field }) => (
                                      <Select
                                        value={this.state.name}
                                        onChange={this.handleChange}
                                        options={this.state.staffMembers?.map(
                                          (member, index) => ({
                                            label:
                                              member.firstName +
                                              " " +
                                              member.lastName,
                                            value: member._id,
                                            image: member.profileImageUrl,
                                          })
                                        )}
                                        isSearchable
                                        className={
                                          "form-control" +
                                          (errors.name && touched.name
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Select Facilitator"
                                      />
                                    )}
                                  />

                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="form-group required">
                                  <label className="control-label">
                                    Workshop Name
                                  </label>
                                  <Field
                                    name="eventname"
                                    type="text"
                                    placeholder="Workshop Name"
                                    className={
                                      "form-control" +
                                      (errors.eventname && touched.eventname
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="eventname"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="form-group required">
                                  <label className="control-label">
                                    Description
                                  </label>
                                  <Editor
                                    style={{
                                      height: "120px",
                                    }}
                                    name="description"
                                    value={values.description}
                                    onTextChange={(e) =>
                                      setFieldValue("description", e.htmlValue)
                                    }
                                  />
                                  <ErrorMessage
                                    name="description"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="form-group">
                                  <label className="control-label">
                                    Location Details
                                  </label>
                                  <Field
                                    name="location"
                                    type="text"
                                    rows={5}
                                    placeholder="Location Details"
                                    className={
                                      "form-control" +
                                      (errors.location && touched.location
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="location"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="form-group required">
                                  <label className="control-label">
                                    Access Link
                                  </label>
                                  <Field
                                    name="accesslink"
                                    type="text"
                                    rows={5}
                                    placeholder="Access Link"
                                    className={
                                      "form-control" +
                                      (errors.accesslink && touched.accesslink
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="accesslink"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="form-group">
                                  <label className="control-label">
                                    Participants Capacity
                                  </label>
                                  <Field
                                    name="capacity"
                                    type="number"
                                    min={0}
                                    // rows={5}
                                    placeholder="Participants Capacity"
                                    className={
                                      "form-control" +
                                      (errors.capacity && touched.capacity
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="capacity"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="form-group required">
                                  <label className="control-label">
                                    What You Will Learn
                                  </label>
                                  <Editor
                                    style={{
                                      height: "120px",
                                    }}
                                    name="learn"
                                    value={values.learn}
                                    onTextChange={(e) =>
                                      setFieldValue("learn", e.htmlValue)
                                    }
                                  />

                                  <ErrorMessage
                                    name="learn"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="d-flex">
                                  <div className="form-group required">
                                    <label className="control-label">
                                      Select Date
                                    </label>
                                    <div
                                      style={{ marginTop: "8px" }}
                                      className={
                                        //   "form-control" +
                                        errors.datetime && touched.datetime
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Calendar
                                        required={true}
                                        showIcon={true}
                                        hourFormat="12"
                                        value={values.date}
                                        minDate={new Date()}
                                        // onChange={(e) => this.setState({ datetime: e.value })}
                                        onChange={(e) =>
                                          setFieldValue("date", e.value)
                                        }
                                      ></Calendar>
                                    </div>
                                    <ErrorMessage
                                      name="date"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                  <div className="form-group required">
                                    <label className="control-label">
                                      Recurrence
                                    </label>
                                    <Field
                                      style={{ marginTop: "8px" }}
                                      name="recurrenceFrequency"
                                      type="text"
                                      as="select"
                                      className={
                                        "form-control" +
                                        (errors.recurrenceFrequency &&
                                        touched.recurrenceFrequency
                                          ? " is-invalid"
                                          : "")
                                      }
                                      placeholder="Select recurrence frequency"
                                    >
                                      <option value="">No</option>
                                      <option value="daily">Daily</option>
                                      <option value="weekly">Weekly</option>
                                      <option value="monthly">Monthly</option>
                                    </Field>
                                    <ErrorMessage
                                      name="recurrenceFrequency"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </div>
                                {values.recurrenceFrequency === "" ? (
                                  ""
                                ) : (
                                  <div className="form-group required">
                                    <label className="control-label">
                                      Recurrence End Date
                                    </label>
                                    <div
                                      style={{ marginTop: "8px" }}
                                      className={
                                        //   "form-control" +
                                        errors.datetime && touched.datetime
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Calendar
                                        required={true}
                                        showIcon={true}
                                        hourFormat="12"
                                        value={values.recurrenceEndDate}
                                        minDate={addDaysToDate(values.date, 1)}
                                        // onChange={(e) => this.setState({ datetime: e.value })}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "recurrenceEndDate",
                                            e.value
                                          )
                                        }
                                      ></Calendar>
                                    </div>
                                    <ErrorMessage
                                      name="recurrenceEndDate"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                )}

                                <div className="d-flex">
                                  <div className="form-group required">
                                    <label className="control-label">
                                      Start Time
                                    </label>
                                    <div
                                      style={{ marginTop: "8px" }}
                                      className={
                                        //   "form-control" +
                                        errors.starttime && touched.starttime
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <TimePicker
                                        showSecond={false}
                                        defaultValue={moment()}
                                        value={values.starttime}
                                        className=""
                                        onChange={(value) => {
                                          console.log(value);
                                          setFieldValue("starttime", value);
                                        }}
                                        format={"hh:mm A"}
                                        use12Hours
                                        disabledHours={() => [12]}
                                        hideDisabledOptions={true}
                                      />
                                    </div>
                                    <ErrorMessage
                                      name="starttime"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>

                                  <div className="form-group required">
                                    <label className="control-label">
                                      End Time
                                    </label>
                                    <div
                                      style={{ marginTop: "8px" }}
                                      className={
                                        //   "form-control" +
                                        errors.endtime && touched.endtime
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <TimePicker
                                        showSecond={false}
                                        defaultValue={moment()}
                                        value={values.endtime}
                                        className=""
                                        onChange={(value) => {
                                          console.log(value);
                                          setFieldValue("endtime", value);
                                        }}
                                        format={"hh:mm A"}
                                        use12Hours
                                      />
                                    </div>
                                    <ErrorMessage
                                      name="endtime"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </div>

                                {values.recurrenceFrequency !== "" &&
                                this.state.eventid ? (
                                  <div className="form-group">
                                    <div className="switch">
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={values.recurrenceEdit}
                                          onChange={(e) =>
                                            setFieldValue(
                                              "recurrenceEdit",
                                              !values.recurrenceEdit
                                            )
                                          }
                                        />
                                        <span className="lever"></span>
                                        Update all recurring workshops
                                      </label>
                                    </div>
                                  </div>
                                ) : null}
                                {values.recurrenceEdit ? (
                                  <div className="alert alert-info">
                                    <p>
                                      The following fields will get updated:
                                    </p>
                                    <ul>
                                      <li>Facilitator's Name</li>
                                      <li>Workshop Name</li>
                                      <li>Description</li>
                                      <li>Location Details</li>
                                      <li>Access Link</li>
                                      <li>Participants Capacity</li>
                                      <li>What You Will Learn</li>
                                      <li>Start Time</li>
                                      <li>End Time</li>
                                    </ul>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="card-footer accordionfooter d-flex flex-row-reverse">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary"
                            >
                              {/* {!this.state.department._id */}
                              {/* ?  */}
                              Save
                              {/* : "Update Course"}{" "} */}
                            </button>

                            <div style={{ marginRight: "5px" }}>
                              <Link
                                to="/staff/workshops/list"
                                className="btn btn-secondary"
                                style={{
                                  backgroundColor: "grey",
                                  color: "white",
                                }}
                              >
                                {/* <i className="mdi mdi-chevron-left"></i> */}
                                <span className="hide-menu">Cancel</span>
                              </Link>
                            </div>
                            {isSubmitting && (
                              <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            )}
                          </div>
                          {status && (
                            <div className={"alert alert-danger"}>{status}</div>
                          )}
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddWorkshop);
