import config from "../../config";
import { authHeader, handleResponse } from "../../_helpers";
import { encData } from "../../_helpers/_helperFunctions";

export const siteLocationService = {
  getAll,
  getSiteLocationById,
  create,
  update,
  deleteSiteLocation,
};

function getAll() {
  const requestOptions = { method: "GET", headers: authHeader() };
  console.log("requestOptions: ", requestOptions);
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/site-locations`,
    requestOptions,
  ).then(handleResponse);
}

function getSiteLocationById(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/site-locations/${id}`,
    requestOptions,
  ).then(handleResponse);
}

function create(data) {
  const requestOptions = {
    method: "POST",
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify(data)
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/site-locations/create`,
    requestOptions,
  ).then(handleResponse);
}

function update(data) {
  const requestOptions = {
    method: "POST",
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify(data)
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/site-locations/create`,
    requestOptions,
  ).then(handleResponse);
}

function deleteSiteLocation(siteLocationId, currentUserId) {
  const requestOptions = {
    method: "POST",
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({siteLocationId,currentUserId})
    headers: authHeader(),
    body: encData({ siteLocationId, currentUserId }),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/site-locations/delete`,
    requestOptions,
  ).then(handleResponse);
}
