/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { Role } from "../_helpers";

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps() {
  return {};
}

class InitialRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: sessionStorage.getItem("currentUser")
        ? JSON.parse(sessionStorage.getItem("currentUser"))
        : {},
    };
  }

  render() {
    return (
      <Route
        render={(props) => {
          //   const currentUser = authenticationService.currentUserValue;
          const { user: currentUser } = this.state;
          console.log("currentUser: ", currentUser);
          if (!currentUser) {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            );
          }
          if (currentUser?.role === Role.Admin) {
            return <Redirect to={{ pathname: "/admin" }} />;
          }
          if (currentUser?.role === Role.Staff) {
            return <Redirect to={{ pathname: "/staff" }} />;
          }

          return <Redirect to={{ pathname: "/login" }} />;
        }}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InitialRoute);
