import { authHeader, handleResponse } from "../../_helpers";

function getPageViews() {
  const requestOptions = { method: "GET", headers: authHeader() };
  console.log("requestOptions: ", requestOptions);
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/analytics/getPageViews`,
    requestOptions,
  ).then(handleResponse);
}

function getAllSessions() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/analytics/getAllSessions`,
    requestOptions,
  ).then(handleResponse);
}

function getVideoAudioStats() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/analytics/getVideoAudioStats`,
    requestOptions,
  ).then(handleResponse);
}

function getUserAnalyticsStats() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/analytics/getUserAnalyticsStats`,
    requestOptions,
  ).then(handleResponse);
}

function getResouceLibraryViews() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/analytics/getResouceLibraryViews`,
    requestOptions,
  ).then(handleResponse);
}

function getResourceFileViews(fileId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/analytics/getResourceFileViews/${fileId}`,
    requestOptions,
  ).then(handleResponse);
}

function getResourceFileViewsOfQuestions(fileId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/analytics/getResourceFileViewsQuestions/${fileId}`,
    requestOptions,
  ).then(handleResponse);
}

function genderVariationStats() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/analytics/genderVariationStats`,
    requestOptions,
  ).then(handleResponse);
}

function ageTrendView() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/analytics/ageTrendView`,
    requestOptions,
  ).then(handleResponse);
}

function monthlyAppointmentRecords(year) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/analytics/monthlyAppointmentRecords/${year}`,
    requestOptions,
  ).then(handleResponse);
}

function noShowsStats(year, month) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/analytics/noShowsStats/${year}/${month}`,
    requestOptions,
  ).then(handleResponse);
}

function getClientLocationStats() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/analytics/client-location`,
    requestOptions,
  ).then(handleResponse);
}

function getEventsPartcipantsStats() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/analytics/event-participants-stats`,
    requestOptions,
  ).then(handleResponse);
}

function getAppointmentRequestStats() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/analytics/appointment-request-stats`,
    requestOptions,
  ).then(handleResponse);
}

export const analyticsService = {
  getPageViews,
  getVideoAudioStats,
  getAllSessions,
  getUserAnalyticsStats,
  getResouceLibraryViews,
  getResourceFileViews,
  genderVariationStats,
  ageTrendView,
  monthlyAppointmentRecords,
  noShowsStats,
  getClientLocationStats,
  getEventsPartcipantsStats,
  getAppointmentRequestStats,
  getResourceFileViewsOfQuestions,
};
