import { authHeader, handleResponse } from "../../_helpers";
import { encData } from "../../_helpers/_helperFunctions";

function getAll() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/course-category`,
    requestOptions,
  ).then(handleResponse);
}

function getCourseCategoryById(courseCategoryId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/course-category/${courseCategoryId}`,
    requestOptions,
  ).then(handleResponse);
}

function create(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/course-category`,
    requestOptions,
  ).then(handleResponse);
}

function update(data, courseCategoryId) {
  const requestOptions = {
    method: "PATCH",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/course-category/${courseCategoryId}`,
    requestOptions,
  ).then(handleResponse);
}

function deleteCourseCategory(courseCategoryId, currentUserId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: encData({ currentUserId }),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/course-category/${courseCategoryId}`,
    requestOptions,
  ).then(handleResponse);
}

export const courseCategoryService = {
  getAll,
  getCourseCategoryById,
  create,
  update,
  deleteCourseCategory,
};
