import { BehaviorSubject } from "rxjs";
import { handleResponse, history } from "../_helpers";
import EncDec from "../e2e/e2e_functions/index";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(sessionStorage.getItem("currentUser")),
);
const currentDepartmentSubject = new BehaviorSubject(
  sessionStorage.getItem("currentDepartment"),
);
const currentDepartmentNameSubject = new BehaviorSubject(
  sessionStorage.getItem("currentDepartmentName"),
);

function setDepartmentName(departmentName) {
  currentDepartmentNameSubject.next(departmentName);
  sessionStorage.setItem("currentDepartmentName", departmentName);
}

function changeDepartment(departmentId, departmentName) {
  sessionStorage.setItem("currentDepartment", departmentId);
  currentDepartmentSubject.next(departmentId);
  setDepartmentName(departmentName);
  history.push("/");
}

function encData(data) {
  const isenc = localStorage.getItem("isenc")
    ? parseInt(localStorage.getItem("isenc"), 10)
    : 0;
  if (isenc === 1) {
    return JSON.stringify({
      enc_string: EncDec.encryptResponse(JSON.stringify(data)),
    });
  }
  return JSON.stringify(data);
}

function login(email, password, captcha, role) {
  const requestOptions = {
    method: "POST",
    // headers: { 'Content-Type': 'application/json' },
    headers: {
      "Content-Type": "application/json",
      isenc: localStorage.getItem("isenc") || 0,
    },

    // body: JSON.stringify({ email, password })
    body: encData({ email, password, captcha, role }),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/authenticate`,
    requestOptions,
  )
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      if (user && user.Data) {
        sessionStorage.setItem("currentUser", JSON.stringify(user.Data));
        currentUserSubject.next(user.Data);
        // store department selection default1
        sessionStorage.setItem("currentDepartment", user.Data?.departments[0]);
        currentDepartmentSubject.next(user.Data?.departments[0]);
      }
      return user.Data;
    });
}

function forgotPassword(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      isenc: localStorage.getItem("isenc")
        ? parseInt(localStorage.getItem("isenc"), 10)
        : 0,
    },
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/forgotpassword`,
    requestOptions,
  ).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  sessionStorage.removeItem("currentUser");
  sessionStorage.removeItem("currentDepartment");
  sessionStorage.removeItem("currentDepartmentName");
  currentUserSubject.next(null);
  currentDepartmentSubject.next(null);
  currentDepartmentNameSubject.next(null);
}

function syncLogout() {
  currentUserSubject.next(null);
  currentDepartmentSubject.next(null);
  currentDepartmentNameSubject.next(null);
}

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  currentDepartment: currentDepartmentSubject.asObservable(),
  get currentDepartmentValue() {
    return currentDepartmentSubject.value;
  },
  get currentDepartmentNameValue() {
    return currentDepartmentNameSubject.value;
  },
  changeDepartment,
  setDepartmentName,
  syncLogout,
  forgotPassword,
};
