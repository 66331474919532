/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import { contentLibraryService } from "../../_services/staff";
import {
  removeAllDotsButLast,
  getFileExtension,
  extractFileName,
  extractFileExtension,
} from "../../_helpers/_helperFunctions";
import axios from "axios";
import slugify from "slugify";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class CreateContentLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      courseCategoryId: this.props?.match?.params?.courseCategoryId,
      loading: true,
      staffOptions: [],
      selectedFile: null,
      uploadProgress: 0,
      position: 0,
      uploadFileError: "",
      contentLibrary: {},
    };
    console.log(
      "this.props?.match?.params?.courseCategoryId: ",
      this.props?.match?.params?.courseCategoryId,
    );
  }

  async componentDidMount() {
    this.props.setUserData(this.state.currentUser);

    this.props.match.params.id &&
      contentLibraryService
        .getContentLibaryCategroryById(this.props.match.params.id)
        .then((data) => {
          console.log("data: ", data);
          this.setState({ contentLibrary: data.Data, loading: false });
        });

    const headers = {
      Authorization: `Bearer ${this.state.currentUser?.token}`,
      isenc: localStorage.getItem("isenc")
        ? parseInt(localStorage.getItem("isenc"), 10)
        : 0,
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/get-last-index/${this.state.courseCategoryId}`,
      { headers },
    );
    console.log("response", response);
    if (response?.data?.Status) {
      this.setState({ position: response?.data?.Data?.Data?.lastIndex + 1 });
    }
    this.setState({ loading: false });
  }

  onChangeHandler = (event) => {
    // Get the selected file from the input element
    const file = event.target.files[0];
    console.log("file: ", file);
    // Set the state with the selected file
    this.setState({ selectedFile: file, uploadFile: "" });
  };

  render() {
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {/* <Preloader /> */}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">
                  {!this.state.contentLibrary._id
                    ? "Add Course"
                    : "Edit Course"}
                </h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li
                    className="breadcrumb-item cursor-pointer"
                    onClick={() =>
                      this.props.history.push(`/staff/course-categories`)
                    }
                  >
                    Course Categories
                  </li>
                  <li
                    className="breadcrumb-item cursor-pointer"
                    onClick={() =>
                      this.props.history.push(
                        `/staff/course-categories/${this.state.courseCategoryId}`,
                      )
                    }
                  >
                    Course
                  </li>
                  <li className="breadcrumb-item active">Add Course</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="pt-4">
                  <div className="card">
                    {!this.state.loading ? (
                      <Formik
                        enableReinitialize
                        initialValues={{
                          name: this.state.contentLibrary.name || "",
                          description:
                            this.state.contentLibrary.description || "",
                          currentUserId: this.state.currentUser.id,
                          categoryId: this.state.contentLibrary._id || "",
                          uploadFile: "", // Initialize uploadFile
                          position:
                            this.state.contentLibrary.index ||
                            this.state.position ||
                            "",
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required(
                            "Course Name is required",
                          ),

                          description: Yup.string().required(
                            "Description is required",
                          ),
                          // uploadFile: Yup.mixed().required("Upload File is required"), // Validation for uploadFile
                          position: Yup.string().required(
                            "Position is required",
                          ),
                        })}
                        onSubmit={(formData, { setStatus, setSubmitting }) => {
                          console.log("---------onSubmit--------: ", formData);
                          const Data = new FormData();
                          Data.append("name", formData.name);
                          Data.append("description", formData.description);
                          Data.append("currentUserId", formData.currentUserId);
                          Data.append("categoryId", formData.categoryId);
                          Data.append("index", `${formData?.position}`);
                          Data.append(
                            "courseCategoryId",
                            `${this.state.courseCategoryId}`,
                          );

                          console.log("Data: ", Data);

                          if (this.state.selectedFile) {
                            const originalname =
                              this.state.selectedFile.name.toLowerCase();
                            const fileName = `content-library/create/${
                              formData.name
                            }/${removeAllDotsButLast(
                              slugify(originalname, { lower: true }),
                            )}`;
                            const fileType = this.state.selectedFile?.type;
                            Data.append("originalname", originalname);
                            Data.append("contentType", fileType);
                            Data.append("key", fileName);

                            axios
                              .post(
                                `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/get-file-uploadlink`,
                                {
                                  fileName,
                                  fileType,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${this.state.currentUser.token}`,
                                    isenc: localStorage.getItem("isenc")
                                      ? parseInt(
                                          localStorage.getItem("isenc"),
                                          10,
                                        )
                                      : 0,
                                  },
                                },
                              )
                              .then((response) => {
                                console.log("---------onSubmit FIRST--------");
                                console.log("response: ", response);
                                const returnData = response.data.Data;
                                const { signedRequest } = returnData;
                                console.log("signedRequest: ", signedRequest);
                                const { url } = returnData;
                                console.log("url: ", url);
                                // this.setState({ url: url });

                                Data.append("location", url);
                                // Put the fileType in the headers for the upload
                                const options = {
                                  headers: {
                                    "Content-Type": fileType,
                                  },
                                  _onUploadProgress: (ev) => {
                                    const progress = parseInt(
                                      (ev.loaded / ev.total) * 100,
                                      10,
                                    );
                                    this.setState({
                                      uploadProgress: progress,
                                    });
                                  },
                                  get onUploadProgress() {
                                    return this._onUploadProgress;
                                  },
                                  set onUploadProgress(value) {
                                    this._onUploadProgress = value;
                                  },
                                };
                                axios
                                  .put(
                                    signedRequest,
                                    this.state.selectedFile,
                                    options,
                                  )
                                  .then(() => {
                                    setStatus();
                                    console.log(
                                      "---------onSubmit SECOND--------",
                                    );
                                    contentLibraryService.create(Data).then(
                                      () => {
                                        console.log("Data: ", Data);
                                        const { from } = this.props.location
                                          .state || {
                                          from: {
                                            pathname: `/staff/course-categories/${this.state.courseCategoryId}`,
                                          },
                                        };
                                        this.props.history.push(from);
                                      },
                                      (error) => {
                                        setSubmitting(false);
                                        setStatus(error);
                                      },
                                    );
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          } else {
                            Data.append(
                              "location",
                              this.state.contentLibrary.location,
                            );
                            Data.append(
                              "originalname",
                              this.state.contentLibrary.originalname,
                            );
                            Data.append(
                              "contentType",
                              this.state.contentLibrary.contentType,
                            );
                            Data.append("key", this.state.contentLibrary.key);

                            contentLibraryService.create(Data).then(
                              () => {
                                console.log("Data: ", Data);
                                const { from } = this.props.location.state || {
                                  from: {
                                    pathname: `/staff/course-categories/${this.state.courseCategoryId}`,
                                  },
                                };
                                this.props.history.push(from);
                              },
                              (error) => {
                                setSubmitting(false);
                                setStatus(error);
                              },
                            );
                          }
                        }}
                        render={({
                          values,
                          errors,
                          status,
                          touched,
                          isSubmitting,
                          setFieldValue,
                        }) => (
                          <Form>
                            {console.log("values: ", values)}
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group required">
                                    <label className="control-label">
                                      Position:
                                    </label>
                                    <Field
                                      name="position"
                                      type="text"
                                      disabled={true}
                                      // value={values.position}
                                      // onChange={(e) => this.setState({ position: e.target.value })}
                                      className={`form-control${
                                        errors.position && touched.position
                                          ? " field-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="position"
                                      component="div"
                                      className="invalid-feedback invalid-feedback-custom"
                                    />
                                  </div>
                                  <div className="form-group required">
                                    <label className="control-label">
                                      Course Name
                                    </label>
                                    <Field
                                      name="name"
                                      type="text"
                                      placeholder="Course Name"
                                      className={`form-control${
                                        errors.name && touched.name
                                          ? " field-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="name"
                                      component="div"
                                      className="invalid-feedback invalid-feedback-custom"
                                    />
                                  </div>
                                  <div className="form-group required">
                                    <label className="control-label">
                                      Description
                                    </label>
                                    <Field
                                      name="description"
                                      component="textarea"
                                      rows={5}
                                      placeholder="Description"
                                      className={`form-control${
                                        errors.description &&
                                        touched.description
                                          ? " field-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="description"
                                      component="div"
                                      className="invalid-feedback invalid-feedback-custom"
                                    />
                                  </div>
                                  {/* <div className="form-group required">
                                  <label className="control-label">
                                    Upload File
                                  </label>
                                  <Field
                                    name="uploadFile"
                                    type="file"
                                    className={`form-control${
                                      errors.uploadFile && touched.uploadFile
                                        ? " field-invalid"
                                        : ""
                                    }`}
                                  />
                                  <ErrorMessage
                                    name="uploadFile"
                                    component="div"
                                    className="invalid-feedback invalid-feedback-custom"
                                  />
                                </div> */}
                                  <div className="form-group required">
                                    <label className="control-label">
                                      Upload Files
                                    </label>
                                    <div>
                                      <label className="custom-file-upload mt-1">
                                        <input
                                          name="uploadFile"
                                          type="file"
                                          className={`d-none form-control${
                                            errors.uploadFile &&
                                            touched.uploadFile
                                              ? "field-invalid"
                                              : ""
                                          }`}
                                          onChange={this.onChangeHandler}
                                        />
                                        Attach File
                                      </label>
                                      {this.state.uploadFileError ? (
                                        <span>
                                          {this.state.uploadFileError}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div>
                                      <span>
                                        {this.state.selectedFile?.name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer accordionfooter d-flex flex-row-reverse">
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-purple"
                              >
                                {/* {!this.state.department._id */}
                                {/* ?  */}
                                Save Course
                                {/* : "Update Course"}{" "} */}
                              </button>

                              <div style={{ marginRight: "5px" }}>
                                <Link
                                  to={`/staff/course-categories/${this.state.courseCategoryId}`}
                                  className="btn btn-secondary"
                                  style={{
                                    backgroundColor: "grey",
                                    color: "white",
                                  }}
                                >
                                  {/* <i className="mdi mdi-chevron-left"></i> */}
                                  <span className="hide-menu">Cancel</span>
                                </Link>
                              </div>

                              {isSubmitting && (
                                <img
                                  alt=""
                                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                                />
                              )}
                            </div>
                            {status && (
                              <div className="alert alert-danger">{status}</div>
                            )}
                          </Form>
                        )}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateContentLibrary);
