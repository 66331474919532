import { authHeader, handleResponse } from "../../_helpers";

function getAllData(status, organization, courseType, courseId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/course-user-status/report?${
      status ? "status=" + status : ""
    }&${organization ? "organization=" + organization : ""}&${
      courseType ? "courseType=" + courseType : ""
    }&${courseId ? "courseId=" + courseId : ""}`,
    requestOptions,
  ).then(handleResponse);
}

export const userCourseReportService = { getAllData };
