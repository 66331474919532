import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { authenticationService } from "../../_services";
import { setUserData } from "../../redux-store/action";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class AdminDashboardLight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      department: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.props.setUserData(this.state.currentUser);
  }

  render() {
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <SidebarSuperAdmin />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
              <h3 className="text-themecolor m-b-0 m-t-0">Admin Dashboard</h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="welcome-card card">
                    <div className="card-body text-center">
                      <h3>Welcome to Women's Enterprise Skills Learning...</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminDashboardLight);
