import { authHeader, handleResponse } from "../../_helpers";
import { encData } from "../../_helpers/_helperFunctions";

function getAll(currentUserId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData({ currentUserId }),
  };
  console.log("requestOptions: ", requestOptions);
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users`,
    requestOptions,
  ).then(handleResponse);
}

function getAllWestUser(currentUserId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData({ currentUserId }),
  };
  console.log("requestOptions: ", requestOptions);
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/westUser`,
    requestOptions,
  ).then(handleResponse);
}

function getAllSuperUsers() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  console.log("requestOptions: ", requestOptions);
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/get-all-superadmin`,
    requestOptions,
  ).then(handleResponse);
}

function getUserById(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/${id}`,
    requestOptions,
  ).then(handleResponse);
}

function changePassword(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/changepassword`,
    requestOptions,
  ).then(handleResponse);
}

function create(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/create`,
    requestOptions,
  ).then(handleResponse);
}

function update(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/create`,
    requestOptions,
  ).then(handleResponse);
}

function deleteUser(userId, currentUserId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData({ userId, currentUserId }),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/delete`,
    requestOptions,
  ).then(handleResponse);
}

function deleteRegisteredUser(userId, currentUserId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData({ userId, currentUserId }),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/delete`,
    requestOptions,
  ).then(handleResponse);
}

function getUsersByDepartmentId(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/getByDepartmentId/${id}`,
    requestOptions,
  ).then(handleResponse);
}

function updateCounsellorType(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/counsellor-type`,
    requestOptions,
  ).then(handleResponse);
}

function getUsersByDepartmentName(name) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/getByDepartmentName/${name}`,
    requestOptions,
  ).then(handleResponse);
}

function getUserList() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users`,
    requestOptions,
  ).then(handleResponse);
}

function setPassword(data, token) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      isenc: localStorage.getItem("isenc")
        ? parseInt(localStorage.getItem("isenc"), 10)
        : 0,
    },
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/setpassword/${token}`,
    requestOptions,
  ).then(handleResponse);
}

function getRegisteredUserList() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/get-all-registered-user`,
    requestOptions,
  ).then(handleResponse);
}

function getAllUserAndStaffList() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/get-all-user-staff`,
    requestOptions,
  ).then(handleResponse);
}

function activeDeactiveCourseByUserId(data, token) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/users/active-deactive-course`,
    requestOptions,
  ).then(handleResponse);
}

export const userService = {
  getAll,
  getUserById,
  changePassword,
  create,
  update,
  deleteUser,
  getUsersByDepartmentId,
  updateCounsellorType,
  getUsersByDepartmentName,
  getUserList,
  getAllSuperUsers,
  setPassword,
  getRegisteredUserList,
  deleteRegisteredUser,
  getAllWestUser,
  getAllUserAndStaffList,
  activeDeactiveCourseByUserId,
};
