import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function DeleteConfirmation2(props) {
  const { className, show, cancel: toggle } = props;

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Delete Confirmation</ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          Are you sure, you want to delete ?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            No
          </Button>{" "}
          <Button
            color="primary"
            onClick={() => {
              props.confirm();
              toggle();
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DeleteConfirmation2;
