import React, { Component } from "react";
import { connect } from "react-redux";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { Link } from "react-router-dom";
import { userService, analyticsService } from "../../_services/admin";
import { eventService } from "../../_services/staff";
import { authenticationService } from "./../../_services";
import { setUserData } from "../../redux-store/action";
import { MDBDataTable } from "mdbreact";
import { Nav, NavItem, NavLink } from "reactstrap";
import { setLoader } from "../../redux-store/action";
import Loader from "react-loader-spinner";
import moment from "moment";
import ResourceLibraryStats from "../../components/user/ResourceLibraryStats";
import EventStats from "../../components/user/EventsStats";
import { Role } from "../../_helpers";
import { getTimeStringBySecInMin } from "../../_helpers/_helperFunctions";

function mapStateToProps(state) {
  return {
    isLoading: state.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class UserAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      loading: true,
      pageViews: [],
      selectedTab: 1,
      sessions: [],
      resourceLibrary: [],
      eventsData: [],
      fileViewCount: null,
      sessionCount: null,
      avgSessionTime: null,
      totalEventRegistrationCount: null,
    };
  }
  componentDidMount() {
    this.getUserAnalyticsStats();
    this.getPageViews();
    this.getSessions();
    this.getResourceLibary();
    // this.getEventStats();
    // this.getTotalEventRegistrationCount();
  }

  getPageViews() {
    analyticsService
      .getPageViews()
      .then((data) => {
        let rowsArray = [];
        data &&
          data.Data &&
          data.Data.forEach((pageView, i) => {
            rowsArray.push({
              pageName: pageView._id,
              viewCount: pageView.count,
            });
          });
        const tableData = {
          columns: [
            {
              label: "Page Name",
              field: "pageName",
              sort: "asc",
            },
            {
              label: "View Count",
              field: "viewCount",
            },
          ],
          rows: rowsArray,
        };
        this.setState({ pageViews: tableData, loading: false });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log("Error", error);
      });
  }

  getSessions() {
    analyticsService
      .getAllSessions()
      .then((data) => {
        let rowsArray = [];
        data &&
          data.Data &&
          data.Data.forEach((session, i) => {
            rowsArray.push({
              ipAddress: session.ipAddress,
              sessionStart: moment(session.timestamp).format(
                "MM/DD/YYYY hh:mm a",
              ),
              sessionEnd: moment(
                session.timestamp + (session.session_duration || 0) * 1000,
              ).format("MM/DD/YYYY hh:mm a"),
              username: session.user_details?.name,
            });
          });

        rowsArray.sort((a, b) => {
          return b.sessionStart.localeCompare(a.sessionStart);
        });

        const tableData = {
          columns: [
            {
              label: "User Name",
              field: "username",
            },
            {
              label: "Session Start",
              field: "sessionStart",
            },
            {
              label: "Session End",
              field: "sessionEnd",
            },
            {
              label: "Ip Address",
              field: "ipAddress",
            },
          ],
          rows: rowsArray,
        };
        this.setState({ sessions: tableData, loading: false });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log("Error", error);
      });
  }

  getResourceLibary() {
    analyticsService
      .getResouceLibraryViews()
      .then((data) => {
        data &&
          data.Data &&
          this.setState({ resourceLibrary: data.Data, loading: false });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log("Error", error);
      });
  }

  getUserAnalyticsStats() {
    analyticsService
      .getUserAnalyticsStats()
      .then((data) => {
        if (data && data.Data) {
          console.log("data: ", data);
          this.setState({
            fileViewCount: data?.Data?.fileViewCount,
            sessionCount: data?.Data?.sessionCount,
            avgSessionTime: data?.Data?.avgSessionTime?.[0]?.averageSessionTime,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log("Error", error);
      });
  }

  // getEventStats() {
  //   eventService
  //     .getEventsStats()
  //     .then((data) => {
  //       if (data && data.Data) {
  //         this.setState({
  //           eventsData: data.Data,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         loading: false,
  //       });
  //       console.log("Error", error);
  //     });
  // }

  // getTotalEventRegistrationCount() {
  //   eventService
  //     .getTotalRegistrationCount()
  //     .then((data) => {
  //       if (data && data.Data) {
  //         this.setState({
  //           totalEventRegistrationCount: data.Data.eventRegistrationCount,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         loading: false,
  //       });
  //       console.log("Error", error);
  //     });
  // }

  toggleTab(tabNo) {
    this.setState({ selectedTab: tabNo });
  }

  render() {
    const {
      pageViews,
      selectedTab,
      resourceLibrary,
      sessions,
      fileViewCount,
      sessionCount,
      avgSessionTime,
      eventsData,
      totalEventRegistrationCount,
    } = this.state;
    const { isLoading } = this.props;
    return (
      <React.Fragment>
        <div
          id="main-wrapper"
          className="fix-header fix-sidebar card-no-border"
        >
          {this.state.loading && <Preloader />}
          <HeaderAdmin />
          {this.state.currentUser.role === Role.Admin ? (
            <SidebarSuperAdmin />
          ) : (
            <Sidebaar />
          )}
          <div className="page-wrapper">
            <div className="container-fluid">
              <div className="row page-titles">
                <div className="col-md-5 col-8 align-self-center">
                  <h3 className="text-themecolor m-b-0 m-t-0">
                    User Analytics
                  </h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">User Analytics</li>
                  </ol>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pt-3"></div>
                <div className="col-lg-3 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-row">
                        <div
                          style={{ background: "#ffb22b" }}
                          className="round round-lg align-self-center round-info"
                        >
                          <i className="ti-blackboard"></i>
                        </div>
                        <div className="m-l-10 align-self-center">
                          <h3 className="m-b-0 font-light">
                            {sessionCount ? sessionCount : "Calculating..."}
                          </h3>
                          <h5 className="text-muted m-b-0">Total Sessions</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-row">
                        <div
                          style={{ background: "#fc4b6c" }}
                          className="round round-lg align-self-center round-info"
                        >
                          <i className="ti-timer"></i>
                        </div>
                        <div className="m-l-10 align-self-center">
                          <h3 className="m-b-0 font-light">
                            {avgSessionTime
                              ? `${getTimeStringBySecInMin(avgSessionTime)}`
                              : "Calculating..."}
                          </h3>
                          <h5 className="text-muted m-b-0">Avg Session Time</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-row">
                        <div
                          style={{ background: "#26c6da" }}
                          className="round round-lg align-self-center round-info"
                        >
                          <i className="ti-eye"></i>
                        </div>
                        <div className="m-l-10 align-self-center">
                          <h3 className="m-b-0 font-light">
                            {fileViewCount || "Calculating..."}
                          </h3>
                          <h5 className="text-muted m-b-0">
                            Total Files Views
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <Nav pills>
                        <NavItem>
                          <NavLink
                            href="#"
                            active={selectedTab === 1}
                            onClick={() => {
                              this.toggleTab(1);
                            }}
                          >
                            Page Views
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            active={selectedTab === 2}
                            onClick={() => {
                              this.toggleTab(2);
                            }}
                          >
                            Sessions
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            active={selectedTab === 3}
                            onClick={() => {
                              this.toggleTab(3);
                            }}
                          >
                            Courses
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  {selectedTab === 1 && (
                    <div className="card">
                      <div className="card-body">
                        <h4>Page Views</h4>
                        <MDBDataTable
                          responsive
                          className="pageviews"
                          striped
                          bordered
                          hover
                          data={pageViews}
                        />
                      </div>
                    </div>
                  )}

                  {selectedTab === 2 && (
                    <div className="card">
                      <div className="card-body">
                        <h4>Sessions</h4>
                        <MDBDataTable
                          responsive
                          className="pageviews"
                          striped
                          bordered
                          hover
                          data={sessions}
                        />
                      </div>
                    </div>
                  )}

                  {selectedTab === 3 && (
                    <div className="card">
                      <div className="card-body">
                        <h4>Courses</h4>
                        <ResourceLibraryStats data={resourceLibrary} />
                      </div>
                    </div>
                  )}

                  {selectedTab === 4 && (
                    <div className="card">
                      <div className="card-body">
                        <h4>Workshops</h4>
                        <div className="table-responsive">
                          <EventStats data={eventsData} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <FooterAdmin />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAnalytics);
