/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from "yup";
import PasswordMask from "react-password-mask";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { authenticationService } from "../../_services";
import { userService } from "../../_services/admin";
import { Role } from "../../_helpers";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

class changePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      user: {},
      department: [],
      loading: true,
    };
  }

  componentDidMount() {
    const scripts = ["/assets/js/custom.min.js"];
    const host = window.location.origin;
    scripts.map((t) => {
      const script = document.createElement("script");
      script.src = host + t;
      script.async = true;
      document.body.appendChild(script);
      return true;
    });
    this.props.setUserData(this.state.currentUser);
    this.props.match.params.id &&
      userService.getUserById(this.props.match.params.id).then((data) => {
        this.setState({ user: data.Data, loading: false });
      });
    this.setState({ loading: false });
  }

  render() {
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        {this.state.currentUser.role === Role.Admin ? (
          <SidebarSuperAdmin />
        ) : (
          <Sidebaar />
        )}
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">Change Password</h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Change Password</li>
                </ol>
              </div>
            </div>
            <div className="row mt-4row justify-content-center mt-5">
              <div className="col-md-6">
                <div className="card">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      currentpassword: "",
                      newpassword: "",
                      confirmpassword: "",
                      currentUserId: this.state.currentUser.id,
                    }}
                    validationSchema={Yup.object().shape({
                      currentpassword: Yup.string().required(
                        "Current password is required",
                      ),
                      // newpassword: Yup.string().required('New password is required'),
                      newpassword: Yup.string()
                        .matches(
                          /^(?=.*[a-z])(?=.*[A-Z])/,
                          "Must have both uppercase and lowercase letters",
                        )
                        .matches(/^(?=.*[0-9])/, "Must consist of a number")
                        .matches(
                          /^(?=.{8,})/,
                          "Must be atleast 8 character long",
                        )
                        .required("Password is required")
                        .when("currentpassword", {
                          is: (val) => val?.length > 0,
                          then: Yup.string().notOneOf(
                            [Yup.ref("currentpassword")],
                            "Current Password and New Password cannot be same",
                          ),
                        }),
                      // confirmpassword: Yup.string().required('Confirm password is required'),
                      confirmpassword: Yup.string()
                        .required("Confirm password is required")
                        .when("newpassword", {
                          is: (val) => val?.length > 0,
                          then: Yup.string().oneOf(
                            [Yup.ref("newpassword")],
                            "Both password need to be the same",
                          ),
                        }),
                    })}
                    onSubmit={(formData, { setStatus, setSubmitting }) => {
                      setStatus();
                      userService.changePassword(formData).then(
                        (user) => {
                          swal(user.Data.Message, "", "success");
                          const { from } = this.props.location.state || {
                            from: { pathname: "/" },
                          };
                          this.props.history.push(from);
                        },
                        (error) => {
                          setSubmitting(false);
                          setStatus(error);
                        },
                      );
                    }}
                    render={({
                      errors,
                      status,
                      touched,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <Form>
                        <div className="card-body pb-0">
                          <div className="row form-material">
                            <div className="col-md-12">
                              <div className="form-group required">
                                <label className="control-label">
                                  Current Password
                                </label>
                                <Field
                                  name="currentpassword"
                                  placeholder="currentpassword"
                                  autoComplete="off"
                                  render={({ field }) => (
                                    <PasswordMask
                                      inputStyles={{ border: "none" }}
                                      buttonStyles={{
                                        top: "5px",
                                        backgroundColor: "transparent",
                                      }}
                                      showButtonContent={
                                        <i
                                          className="ti-eye"
                                          style={{
                                            color: "#ccc",
                                            backgroundColor: "transparent",
                                          }}
                                        />
                                      }
                                      hideButtonContent={
                                        <i
                                          className="ti-eye"
                                          style={{
                                            backgroundColor: "transparent",
                                          }}
                                        />
                                      }
                                      onChange={(password) =>
                                        password === null
                                          ? setFieldValue("currentpassword", "")
                                          : setFieldValue(
                                              "currentpassword",
                                              password,
                                            )
                                      }
                                      {...field}
                                      className={`form-control${
                                        errors.currentpassword &&
                                        touched.currentpassword
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="currentpassword"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group required">
                                <label className="control-label">
                                  New Password
                                </label>
                                <Field
                                  name="newpassword"
                                  placeholder="newpassword"
                                  autoComplete="off"
                                  render={({ field }) => (
                                    <PasswordMask
                                      inputStyles={{ border: "none" }}
                                      buttonStyles={{
                                        top: "5px",
                                        backgroundColor: "transparent",
                                      }}
                                      showButtonContent={
                                        <i
                                          className="ti-eye"
                                          style={{
                                            color: "#ccc",
                                            backgroundColor: "transparent",
                                          }}
                                        />
                                      }
                                      hideButtonContent={
                                        <i
                                          className="ti-eye"
                                          style={{
                                            backgroundColor: "transparent",
                                          }}
                                        />
                                      }
                                      onChange={(password) =>
                                        password === null
                                          ? setFieldValue("newpassword", "")
                                          : setFieldValue(
                                              "newpassword",
                                              password,
                                            )
                                      }
                                      {...field}
                                      className={`form-control${
                                        errors.newpassword &&
                                        touched.newpassword
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="newpassword"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group required">
                                <label className="control-label">
                                  Confirm Password
                                </label>
                                <Field
                                  name="confirmpassword"
                                  placeholder="confirmpassword"
                                  autoComplete="off"
                                  render={({ field }) => (
                                    <PasswordMask
                                      inputStyles={{ border: "none" }}
                                      buttonStyles={{
                                        top: "5px",
                                        backgroundColor: "transparent",
                                      }}
                                      showButtonContent={
                                        <i
                                          className="ti-eye"
                                          style={{
                                            color: "#ccc",
                                            backgroundColor: "transparent",
                                          }}
                                        />
                                      }
                                      hideButtonContent={
                                        <i
                                          className="ti-eye"
                                          style={{
                                            backgroundColor: "transparent",
                                          }}
                                        />
                                      }
                                      onChange={(password) =>
                                        password === null
                                          ? setFieldValue("confirmpassword", "")
                                          : setFieldValue(
                                              "confirmpassword",
                                              password,
                                            )
                                      }
                                      {...field}
                                      className={`form-control${
                                        errors.confirmpassword &&
                                        touched.confirmpassword
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  name="confirmpassword"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer border-0 accordionfooter text-right">
                          <Link
                            to="/"
                            className="btn btn-outline-secondary mr-2"
                          >
                            Cancel
                          </Link>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary"
                          >
                            {" "}
                            Change Password{" "}
                          </button>
                          {isSubmitting && (
                            <img
                              alt=""
                              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                            />
                          )}
                        </div>
                        {status && (
                          <div className="alert alert-danger">{status}</div>
                        )}
                      </Form>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(changePassword);
