/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { authenticationService } from "../_services";
import { history, Role } from "../_helpers";

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
function mapDispatchToProps() {
  return {};
}

const defaultUserImage = "/assets/images/avatar.png";
class HeaderAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      currentDepartment: authenticationService.currentDepartmentValue,
      departments: [],
      currentDepartmentName: "No Department",
      searchResult: [],
      imageLoadError: false,
    };

    this.handleImageError = this.handleImageError.bind(this);
  }

  setCurrentDepartment(departmentId, currentDepartmentName) {
    if (departmentId === this.state.currentDepartment) {
      return false;
    }
    authenticationService.changeDepartment(departmentId, currentDepartmentName);
    this.props.setSelectedDepartment(currentDepartmentName);
    this.setState({ currentDepartmentName, currentDepartment: departmentId });
  }

  handleImageError = () => {
    this.setState({ imageLoadError: true });
  };

  toggleSidebar = (event) => {
    event.preventDefault();
    return window.jQuery("body").hasClass("mini-sidebar")
      ? (window.jQuery("body").trigger("resize"),
        window
          .jQuery(".scroll-sidebar, .slimScrollDiv")
          .css({ overflow: "hidden" })
          .parent()
          .css("overflow", "visible"),
        window.jQuery("body").removeClass("mini-sidebar"),
        window.jQuery(".navbar-brand span").show())
      : (window.jQuery("body").trigger("resize"),
        window
          .jQuery(".scroll-sidebar, .slimScrollDiv")
          .css("overflow-x", "visible")
          .parent()
          .css("overflow", "visible"),
        window.jQuery("body").addClass("mini-sidebar"),
        window.jQuery(".navbar-brand span").hide());
  };

  async logout() {
    authenticationService.logout();
    history.push("/login");
  }

  render() {
    const { currentUser, currentDepartmentName, departments } = this.state;

    return (
      <header className="topbar">
        <nav className="navbar top-navbar navbar-expand-md navbar-light">
          <div className="navbar-header header-color">
            <Link className="navbar-brand" to="/">
              <b  style={{verticalAlign:"middle"}}>
                <img
                  src={`${window.location.origin}/assets/images/WEST-logo.png`}
                  alt="logo"
                  className="dark-logo"
                />
              </b>
              <div className="ml-1" style={{display:"inline-block"}}>
                <img
                  src={`${window.location.origin}/assets/images/WEST-logo.png`}
                  alt="Logo text"
                  className="light-logo"
                />
              </div>
              <span>
                <img
                  src={`${window.location.origin}/assets/images/WEST-text-logo.png`}
                  alt="Logo text"
                  className="light-logo"
                />
              </span>
            </Link>
          </div>

          <div className="navbar-collapse">
            <ul className="navbar-nav mr-auto mt-md-0">
              <li className="nav-item">
                {" "}
                <a
                  className="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark"
                  href="#"
                  onClick={this.toggleSidebar}
                >
                  <i className="mdi mdi-menu" />
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a
                  className="nav-link hidden-sm-down text-muted waves-effect waves-dark"
                  href="#"
                  onClick={this.toggleSidebar}
                >
                  <i className="ti-menu" />
                </a>{" "}
              </li>
              {currentUser?.role === Role.User && (
                <>
                  <li className="nav-item searching_li" />{" "}
                </>
              )}
            </ul>
            <ul className="navbar-nav my-lg-0">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="mr-2">{`Welcome, ${currentUser?.firstName}`}</span>
                  <img
                    src={
                      this.state.imageLoadError
                        ? defaultUserImage
                        : currentUser?.profileImageUrl
                    }
                    alt="user"
                    className="profile-pic"
                    onError={this.handleImageError}
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right scale-up">
                  <ul className="dropdown-user">
                    <li>
                      <div className="dw-user-box text-center">
                        <div className="u-img">
                          <img
                            src={
                              this.state.imageLoadError
                                ? defaultUserImage
                                : currentUser?.profileImageUrl
                            }
                            alt="user"
                            onError={this.handleImageError}
                          />
                        </div>
                        <div className="u-text mt-2">
                          <h4>{`${currentUser?.firstName} ${currentUser?.lastName}`}</h4>
                          <p className="text-muted">{currentUser?.email}</p>
                        </div>
                      </div>
                    </li>
                    <li role="separator" className="divider" />
                    <li>
                      <Link
                        to={
                          currentUser?.role === Role.Admin
                            ? "/admin/change-password"
                            : "/staff/change-password"
                        }
                        className="waves-effect waves-dark"
                      >
                        <i className="ti-lock mr-1" />
                        Change Password
                      </Link>
                    </li>
                    <li role="separator" className="divider" />
                    <li>
                      <a onClick={() => this.logout()}>
                        <i className="fa fa-power-off" /> Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="flag-icon flag-icon-ca"></i>
                </a>
              </li>

              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  <i className="flag-icon flag-icon-ca" />
                </a>
              </li> */}
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAdmin);
