import React, { Component } from "react";
import { connect } from "react-redux";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setLoader, setUserData } from "../../redux-store/action";
import { Input, Label } from "reactstrap";
import {
  courseTypes,
  optionsTypes,
  optionsTypesArray,
} from "../constants/constant";
import { MDBIcon } from "mdbreact";
import * as Yup from "yup";
import { contentLibraryService } from "../../_services/staff";
import Swal from "sweetalert";
import { authenticationService } from "../../_services";
import axios from "axios";
import { Link } from "react-router-dom";

function mapStateToProps(state) {
  return {
    isLoading: state.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string(),
  questions: Yup.array()
    .of(
      Yup.object().shape({
        question: Yup.string().required("Question is required"),
        optionType: Yup.string()
          .required("Option type is required")
          .oneOf(
            ["CHECKBOX", "MULTIPLE_CHOICE"],
            "Option type must be CHECKBOX or MULTIPLE_CHOICE",
          ),
        options: Yup.array()
          .of(
            Yup.object().shape({
              option: Yup.string().required("Option is required"),
              isAnswer: Yup.boolean().required("isAnswer is required"),
            }),
          )
          .min(1, "At least one option is required"),
      }),
    )
    .min(1, "At least one question is required"),
});

const courseType = courseTypes.QUESTIONER;

class CreateQuestioneries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      title: "Untitled Quiz",
      description: "description",
      questions: [],
      categoryId: this.props.match.params.id,
      courseCategoryId: this.props.match.params.courseCategoryId,
      lastIndex: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.addOption = this.addOption.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.deleteOption = this.deleteOption.bind(this);
    this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
    this.handleChangeOptionType = this.handleChangeOptionType.bind(this);
    this.handleOptionValueChange = this.handleOptionValueChange.bind(this);
    this.handleIsAnswer = this.handleIsAnswer.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    console.log("this.props.match.params.id = ", this.props.match.params.id);
    console.log("this.state.currentUser: ", this.state.currentUser);
  }

  async componentDidMount() {
    this.props.match.params.questionerId &&
      contentLibraryService
        .getFileDetailsById(this.props.match.params.questionerId)
        .then((data) => {
          console.log("data: ", data);
          if (data?.Status) {
            const { questions, title, description, index } = data.Data;
            this.setState({ questions, title, description, lastIndex: index });
          }
        });

    const headers = {
      Authorization: `Bearer ${this.state.currentUser?.token}`,
      isenc: localStorage.getItem("isenc")
        ? parseInt(localStorage.getItem("isenc"), 10)
        : 0,
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/content-library-categories/get-file-last-index/${this.props.match.params.id}`,
      { headers },
    );
    console.log("response", response);
    if (response?.data?.Status) {
      this.setState({
        lastIndex: response?.data?.Data?.Data?.lastIndex + 1,
      });
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  addQuestion() {
    this.setState({
      questions: [
        ...this.state.questions,
        {
          question: "Question",
          optionType: optionsTypes.MULTIPLE_CHOICE.key,
          options: [{ option: "Option 1", isAnswer: false }],
        },
      ],
    });
  }

  deleteQuestion(questionIndex) {
    const updatedQuestions = [...this.state.questions];
    updatedQuestions?.splice(questionIndex, 1);
    this.setState({
      questions: updatedQuestions,
    });
  }

  addOption(questionIndex) {
    const updatedQuestions = [...this.state.questions];
    updatedQuestions[questionIndex].options.push({
      option: "Option",
      isAnswer: false,
    });
    this.setState({ questions: updatedQuestions });
  }

  deleteOption(questionIndex, optionIndex) {
    const updatedQuestions = [...this.state.questions];
    updatedQuestions[questionIndex].options.splice(optionIndex, 1);
    this.setState({ questions: updatedQuestions });
  }

  handleChangeOptionType(event, questionIndex) {
    const updatedQuestions = [...this.state.questions];
    updatedQuestions[questionIndex].optionType = event.target.value;
    this.setState({ questions: updatedQuestions });
  }

  handleChangeQuestion(event, questionIndex) {
    const updatedQuestions = [...this.state.questions];
    updatedQuestions[questionIndex].question = event.target.value;
    this.setState({ questions: updatedQuestions });
  }

  handleOptionValueChange(event, questionIndex, optionIndex) {
    const updatedQuestions = [...this.state.questions];
    updatedQuestions[questionIndex].options[optionIndex]["option"] =
      event.target.value;
    this.setState({ questions: updatedQuestions });
  }

  handleIsAnswer(event, questionIndex, optionIndex, optionType) {
    const updatedQuestions = [...this.state.questions];
    if (optionType === optionsTypes.MULTIPLE_CHOICE.key) {
      const options = updatedQuestions[questionIndex].options?.map(
        (option, index) =>
          optionIndex === index
            ? { ...option, isAnswer: true }
            : { ...option, isAnswer: false },
      );
      updatedQuestions[questionIndex].options = options;
    } else if (optionType === optionsTypes.CHECKBOX.key) {
      updatedQuestions[questionIndex].options[optionIndex]["isAnswer"] =
        event.target.checked;
    }
    this.setState({ questions: updatedQuestions });
  }

  async handleSubmit() {
    try {
      const {
        title,
        description,
        questions,
        categoryId,
        currentUser,
        lastIndex,
      } = this.state;

      const requestData = {
        title,
        description,
        // courseType,
        questions,
        categoryId,
        // currentUserId: currentUser?.id,
        feedback: "false",
        downloadOption: false,
        index: lastIndex,
        courseCategoryId: this.state.courseCategoryId,
      };

      // Validate the data using the schema
      await validationSchema.validate(requestData, { abortEarly: false });
      // Validation passed
      this.props.setLoader(true);

      if (this.props.match.params?.questionerId) {
        requestData["fileId"] = this.props.match.params?.questionerId;
        requestData["updatedBy"] = currentUser?.id;
      } else {
        requestData["currentUserId"] = currentUser?.id;
        requestData["courseType"] = courseType;
      }
      this.props.match.params.questionerId
        ? contentLibraryService.updateFileDetails(requestData).then((data) => {
            if (data.Status) {
              Swal("Quiz updated successfully", "", "success");
            } else {
              Swal("Quiz updated successfully", "", "error");
            }
          })
        : contentLibraryService.saveFileInfo(requestData).then((data) => {
            if (data && data.Status) {
              // file info save success.
              this.props.setLoader(false);
              this.setState({
                title: "Untitled form",
                description: "",
                questions: [],
              });
              Swal("Quiz uploaded successfully", "", "success");
              this.props.history.push(
                `/staff/course-categories/${this.state.courseCategoryId}/category-detail/${this.state.categoryId}`,
              );
            } else {
              // file info save fail
              this.props.setLoader(false);
              Swal("Quiz cannot be uploaded please try later", "", "error");
            }
          });

      return {};
    } catch (error) {
      // Validation failed
      const errors = [];
      error?.inner?.forEach((e) => {
        errors.push(e?.message | "");
      });
      console.log("errors: ", errors);
      console.log("error: ", error);
      Swal(
        error?.errors[0] || error?.message || "Validation failed",
        "",
        "error",
      );
      return errors;
    }
  }

  render() {
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">
                  {this.props.match.params.questionerId
                    ? "Edit Quiz"
                    : "Create Quiz"}
                </h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>

                  <li
                    className="breadcrumb-item cursor-pointer"
                    onClick={() =>
                      this.props.history.push(`/staff/course-categories`)
                    }
                  >
                    Course Categories
                  </li>
                  <li
                    className="breadcrumb-item cursor-pointer"
                    onClick={() =>
                      this.props.history.push(
                        `/staff/course-categories/${this.state.courseCategoryId}`,
                      )
                    }
                  >
                    Courses
                  </li>
                  <li className="breadcrumb-item active">
                    {this.props.match.params.questionerId
                      ? "Edit Quiz"
                      : "Create Quiz"}
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="d-flex justify-content-between mb-3">
                    <Link
                      to={`/staff/course-categories/${this.state.courseCategoryId}/category-detail/${this.state.categoryId}`}
                      className="btn btn-primary"
                    >
                      <i className="mdi mdi-chevron-left"></i>
                      <span className="hide-menu">Back</span>
                    </Link>
                    <button
                      type="button"
                      className="btn btn-purple ml-3"
                      onClick={this.handleSubmit}
                    >
                      {this.props.match.params.questionerId
                        ? "Update Quiz"
                        : "Create Quiz"}
                    </button>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="pt-2">
                            <Input
                              type="text"
                              name="title"
                              placeholder="Quiz Title"
                              className="text-dark"
                              value={this.state.title}
                              style={{ fontSize: "1.4rem" }}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="pt-2">
                            <Input
                              type="text"
                              name="description"
                              className="text-dark"
                              placeholder="Quiz description"
                              value={this.state.description}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.questions?.map((item, index) => (
                        <>
                          <div className="card">
                            <div className="card-body">
                              <div className="form-group mb-1">
                                <div className="d-flex justify-content-end mb-2">
                                  <MDBIcon
                                    far
                                    icon="times-circle"
                                    className="cursor-p"
                                    style={{ color: "red" }}
                                    size="lg"
                                    onClick={() => this.deleteQuestion(index)}
                                  />
                                </div>
                                <div className="d-flex flex-row align-items-center pt-2 mb-3">
                                  <h4 className="m-auto" style={{ flex: 1 }}>
                                    {index + 1}.
                                  </h4>
                                  <Input
                                    type="text"
                                    name="question"
                                    className="ml-2 text-dark"
                                    style={{ flex: 40 }}
                                    placeholder="Question"
                                    value={item?.question}
                                    onChange={(event) =>
                                      this.handleChangeQuestion(event, index)
                                    }
                                  />
                                  <Input
                                    type="select"
                                    name="select"
                                    id="exampleSelect"
                                    className="ml-3 text-dark"
                                    style={{ flex: 10 }}
                                    onChange={(event) =>
                                      this.handleChangeOptionType(event, index)
                                    }
                                  >
                                    {optionsTypesArray.map((optionsType) => (
                                      <option
                                        key={optionsType?.key}
                                        value={optionsType?.key}
                                        selected={
                                          optionsType?.key === item?.optionType
                                        }
                                      >
                                        {optionsType?.name}
                                      </option>
                                    ))}
                                  </Input>
                                </div>
                                {item?.options?.map((val, optionIndex) => (
                                  <div className="p-t-0 d-flex align-items-center mb-3">
                                    <input
                                      type={
                                        optionsTypes[item?.optionType]?.value ||
                                        "radio"
                                      }
                                      id={`question_${index}_${optionIndex}`}
                                      name={
                                        optionsTypes[item?.optionType]
                                          ?.value === optionsTypes.CHECKBOX.key
                                          ? `question_${index}_${optionIndex}`
                                          : `question_${index}`
                                      }
                                      style={{ flex: 1 }}
                                      onClick={(event) =>
                                        this.handleIsAnswer(
                                          event,
                                          index,
                                          optionIndex,
                                          item?.optionType,
                                        )
                                      }
                                      checked={val?.isAnswer}
                                    />
                                    <label
                                      className="mb-1 text-dark w-100 mr-2"
                                      htmlFor={`question_${index}_${optionIndex}`}
                                    >
                                      <input
                                        type="text"
                                        className="form-control"
                                        style={{
                                          marginBottom: "0.5rem",
                                          fontSize: "0.9rem",
                                        }}
                                        placeholder="Option"
                                        name="option"
                                        value={val?.option}
                                        onChange={(event) =>
                                          this.handleOptionValueChange(
                                            event,
                                            index,
                                            optionIndex,
                                            "option",
                                          )
                                        }
                                      />
                                    </label>

                                    <MDBIcon
                                      far
                                      icon="times-circle"
                                      className="text-dark cursor-p"
                                      style={{ flex: 1 }}
                                      onClick={() =>
                                        this.deleteOption(index, optionIndex)
                                      }
                                    />
                                  </div>
                                ))}
                                <div className="p-t-0 d-flex justify-content-end">
                                  <label
                                    htmlFor={`question_${index}_add_option`}
                                    className="hover-underline text-dark mt-2"
                                    onClick={() => this.addOption(index)}
                                  >
                                    Add Option
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          className="btn btn-purple"
                          onClick={this.addQuestion}
                        >
                          <MDBIcon fas icon="plus" className="mr-2" />
                          Add Question
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateQuestioneries);
