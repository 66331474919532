/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function mapStateToProps() {
  return {};
}

class Sidebaar extends Component {
  render() {
    return (
      <aside className="left-sidebar">
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              <li>
                <Link to="/admin" className="waves-effect waves-dark disabled">
                  <i className="mdi mdi-view-dashboard" />{" "}
                  <span className="hide-menu">Dashboard</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/admin/departments"
                  className="waves-effect waves-dark disabled"
                >
                  <i className="mdi mdi-book-multiple" />{" "}
                  <span className="hide-menu">Departments</span>
                </Link>
              </li> */}
              <li>
                <Link
                  to="/admin/users"
                  className="waves-effect waves-dark disabled"
                >
                  <i className="mdi mdi-account-multiple" />{" "}
                  <span className="hide-menu">WEST Users</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/registered-users"
                  className="waves-effect waves-dark disabled"
                >
                  <i className="mdi mdi-account-multiple" />{" "}
                  <span className="hide-menu">Registered Users</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/analytics"
                  className="waves-effect waves-dark disabled"
                >
                  <i className="mdi mdi-chart-bar"></i>{" "}
                  <span className="hide-menu">Analytics</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/admin/report"
                  className="waves-effect waves-dark disabled"
                >
                  <i className="mdi mdi-file-document"></i>{" "}
                  <span className="hide-menu">Report</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/admin/super-admin"
                  className="waves-effect waves-dark disabled"
                >
                  <i className="mdi mdi-account-multiple" />{" "}
                  <span className="hide-menu">Super Admins</span>
                </Link>
              </li> */}
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}

export default connect(mapStateToProps)(Sidebaar);
