import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// import { history } from "../../_helpers";
import { authenticationService } from "../../_services";

function mapStateToProps(state) {
  return { currentDepartmentName: state.selectedDepartment };
}

class Sidebaar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
    };
  }

  render() {
    return (
      <aside className="left-sidebar">
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              <li>
                <Link
                  to="/staff/course-categories"
                  className="waves-effect waves-dark"
                >
                  <i className="mdi mdi-view-dashboard" />{" "}
                  <span className="hide-menu">Categories</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="sidebar-footer">
          {/* <a href="#" className="link" data-toggle="tooltip" title="Settings"><i className="ti-settings"></i></a>

                <a href="#" className="link" data-toggle="tooltip" title="Email"><i className="mdi mdi-gmail"></i></a>

                <a onClick={this.logout} className="link" data-toggle="tooltip" title="Logout"><i className="mdi mdi-power"></i></a> */}
        </div>
      </aside>
    );
  }
}

export default connect(mapStateToProps)(Sidebaar);
