/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
// import Loader from "react-loader-spinner";
import moment from "moment";
import swal from "sweetalert";
// import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import SidebarSuperAdmin from "../../components/admin/Sidebaar";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { setUserData, setLoader } from "../../redux-store/action";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import { Role } from "../../_helpers";

function mapStateToProps(state) {
  return {
    isLoading: state.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoader: (data) => {
      dispatch(setLoader(data));
    },
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
  };
}

// function isPermitedToView(currentUser, givenUser) {
//   if (currentUser.role === 1) {
//     return true;
//   }
//   if (givenUser.role === 2) {
//     return true;
//   }
//   return false;
// }

class Users extends Component {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      users: [],
      loading: true,
      departments: [],
      subDepartments: [],
    };
  }

  componentDidMount() {
    this.getAllUsers();
    this.props.setUserData(this.state.currentUser);
  }

  getUserRole() {
    return this.state.currentUser.role === 1 ? "admin" : "staff";
  }

  getAllUsers() {
    this.props.setLoader(true);
    userService.getAllWestUser(this.state.currentUser.id).then((data) => {
      const rowsArray = [];
      // eslint-disable-next-line no-unused-expressions
      data &&
        data.Data &&
        data.Data.map((user, i) => {
          rowsArray.push({
            key: i + 1,
            firstName: user?.firstName || "",
            lastName: user?.lastName || "",
            email: user.email,
            role:
              user.role === 1
                ? "Admin"
                : user.role === 2
                ? "Staff"
                : "Customer",
            jobTitle: user?.jobTitle || "",
            createdAt: moment(user.createdAt, "x").format("MM/DD/YYYY"),

            action: (
              <>
                <Link
                  to={`/${this.getUserRole()}/user/edit/${user._id}`}
                  data-toggle="tooltip"
                  data-original-title="Edit"
                >
                  {" "}
                  <i className="fas fa-pencil-alt text-inverse m-r-10" />{" "}
                </Link>
                <a
                  onClick={() => document.getElementById(user._id).click()}
                  data-toggle="tooltip"
                  data-original-title="Delete"
                >
                  {" "}
                  <i className="fas fa-trash text-danger" />{" "}
                </a>
                <DeleteConfirmation
                  id={user._id}
                  confirm={() => this.delete(user._id)}
                />
              </>
            ),
          });
          return true;
        });
      const tableData = {
        columns: [
          {
            label: "#",
            field: "key",
            sort: "asc",
            width: 270,
          },
          {
            label: "First Name",
            field: "firstName",
            sort: "asc",
            width: 150,
          },
          {
            label: "Last Name",
            field: "lastName",
            sort: "asc",
            width: 150,
          },
          {
            label: "Email",
            field: "email",
            sort: "asc",
            width: 150,
          },
          {
            label: "Role",
            field: "role",
            sort: "asc",
            width: 270,
          },
          {
            label: "Job Title",
            field: "jobTitle",
            sort: "asc",
            width: 270,
          },
          // {
          //     label: 'Sub Department',
          //     field: 'subDepartmentName',
          //     sort: 'asc',
          //     width: 270
          // },
          // {
          //     label: 'Date Of Birth',
          //     field: 'dob',
          //     sort: 'asc',
          //     width: 270
          // },
          {
            label: "Created At",
            field: "createdAt",
            sort: "asc",
            width: 270,
          },
          {
            label: "Action",
            field: "action",
            sort: "disabled",
            width: 270,
          },
        ],
        rows: rowsArray,
      };
      this.setState({ users: tableData, loading: false });
      this.props.setLoader(false);
    });
  }

  delete(userId) {
    userService
      .deleteUser(userId, this.state.currentUser.id)
      .then((response) => {
        response.Status && this.getAllUsers();
        swal("User Deleted Succesfully!", "", "success");
      })
      .catch((err) => {
        console.log(err);
        swal("Opps Something Went Wrong!", "", "error");
      });
  }

  render() {
    const { users, currentUser } = this.state;
    // const { isLoading } = this.props;
    const role = currentUser.role === 1 ? "admin" : "staff";
    return (
      <>
        {/* {isLoading && (
          <div id="loaderRoot">
            <div className="loaderPositionsStyle">
              <div className="loaderPositionsStyleSub">
                <Loader
                  type="Rings"
                  sty
                  color="#6f42c1"
                  height={80}
                  width={80}
                />
              </div>
            </div>
          </div>
        )} */}
        {/* {!isLoading && ( */}
        <div
          id="main-wrapper"
          className="fix-header fix-sidebar card-no-border"
        >
          {/* {this.state.loading && <Preloader />} */}
          <HeaderAdmin />
          {this.state.currentUser.role === Role.Admin ? (
            <SidebarSuperAdmin />
          ) : (
            <Sidebaar />
          )}
          <div className="page-wrapper">
            <div className="container-fluid">
              <div className="row page-titles">
                <div className="col-md-5 col-8 align-self-center">
                  <h3 className="text-themecolor m-b-0 m-t-0">WEST User</h3>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active">WEST User</li>
                  </ol>
                </div>
                <div className="col-md-7 col-4 align-self-center text-right">
                  <Link to={`/${role}/user/create`} className="btn btn-primary">
                    <i className="mdi mdi-account-plus" />{" "}
                    <span className="hide-menu">Add Staff</span>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="pt-4">
                    <div className="card">
                      <div className="card-body">
                        {/* <div className="table-responsive"> */}
                        <MDBDataTable
                          responsive
                          striped
                          bordered
                          hover
                          data={users}
                        />
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FooterAdmin />
          </div>
        </div>
        {/* )} */}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
