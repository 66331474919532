/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { Col, Row } from "react-bootstrap";
import Preloader from "../../components/Preloader";
import HeaderAdmin from "../../components/HeaderAdmin";
import Sidebaar from "../../components/user/Sidebaar";
import FooterAdmin from "../../components/FooterAdmin";
import { setUserData } from "../../redux-store/action";
import { userService } from "../../_services/admin";
import { authenticationService } from "../../_services";
import { contentLibraryService } from "../../_services/staff";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import Pagination from "../../shared/Pagination";
import notFound from "../image/notfound.png";
import DeleteConfirmation2 from "../../shared/DeleteConfirmation2";
import { LIMIT } from "../constants/constant";
import { MDBIcon } from "mdbreact";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (name) => {
      dispatch(setUserData(name));
    },
  };
}

const defaultUserImage = "/assets/images/college-project.png";

class ContentLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      courseCategoryId: this.props?.match?.params?.courseCategoryId,
      userFromApi: null,
      contentCategories: [],
      loading: true,
      page: 1,
      selectedDeleteItemId: "",
      deleteConfirmationModal: false,
    };

    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
  }

  componentDidMount() {
    const { currentUser } = this.state;
    userService.getUserById(currentUser.id).then((data) => {
      data.Data && this.props.setUserData(data.Data);
    });
    this.getAllContentCategories();
  }

  handlePageClick(e) {
    this.setState({ page: e.selected + 1 });
  }

  handleImageError = (event) => {
    event.target.src = defaultUserImage;
  };

  getAllContentCategories() {
    console.log("this.state.courseCategoryId: ", this.state.courseCategoryId);
    if (this.state.courseCategoryId) {
      contentLibraryService.getAll(this.state.courseCategoryId).then((data) => {
        const rowsArray = [];
        data &&
          data.Data &&
          data.Data.forEach((item, i) => {
            rowsArray.push({
              key: i + 1,
              _id: item?._id,
              category: item.name,
              description: item.description,
              location: item.location,
              view: (
                <Link
                  style={{ backgroundColor: "#00abff" }}
                  to={`/staff/course-categories/${this.state.courseCategoryId}/category-detail/${item._id}`}
                  className="btn btn-sm btn-primary"
                >
                  <span className="hide-menu">View Files</span>
                </Link>
              ),
              action: (
                <>
                  <Link
                    to={`/staff/course-categories/${this.state.courseCategoryId}/edit/${item._id}`}
                    data-toggle="tooltip"
                    data-original-title="Edit"
                  >
                    <i className="fas fa-pencil-alt m-r-20 text-muted" />
                  </Link>
                  <a
                    // onClick={() => this.deleteCategory(item._id)}
                    onClick={() => document.getElementById(item._id).click()}
                    data-toggle="tooltip"
                    data-original-title="Delete"
                  >
                    <i className="fas fa-trash text-muted " />
                    <DeleteConfirmation
                      id={item._id}
                      confirm={() => this.deleteCategory(item._id)}
                    />
                  </a>
                </>
              ),
            });
          });

        const tableData = {
          columns: [
            // {
            //   label: "No.",
            //   field: "key",
            //   sort: "asc",
            //   width: 270,
            // },
            {
              label: "Course",
              field: "category",
              sort: "asc",
              width: 150,
            },
            {
              label: "Description",
              field: "description",
              sort: "asc",
              width: 150,
            },
            {
              label: "View",
              field: "view",
              sort: "disabled",
              width: 270,
            },
            {
              label: "Action",
              field: "action",
              sort: "disabled",
              width: 270,
            },
          ],
          rows: rowsArray,
        };
        if (this.state.currentUser.accessType === 1) {
          tableData.columns.pop();
          this.setState({ contentCategories: tableData, loading: false });
        } else {
          this.setState({ contentCategories: tableData, loading: false });
        }
      });
    } else {
      swal("Course Category not found", "", "error");
      this.setState({ loading: false });
    }
  }

  deleteCategory(id) {
    contentLibraryService
      .deleteContentCategory(
        id,
        this.state.currentUser.id,
        this.state.courseCategoryId,
      )
      .then(() => {
        this.getAllContentCategories();
        swal("Resource Library Deleted Succesfully!", "", "success");
      })
      .catch((error) => {
        console.log("error: ", error);
        swal("Oops Something Went Wrong!", "", "error");
      });
  }

  render() {
    const { contentCategories } = this.state;
    return (
      <div id="main-wrapper" className="fix-header fix-sidebar card-no-border">
        {this.state.loading && <Preloader />}
        <HeaderAdmin />
        <Sidebaar />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row page-titles">
              <div className="col-md-5 col-8 align-self-center">
                <h3 className="text-themecolor m-b-0 m-t-0">Courses</h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li
                    className="breadcrumb-item cursor-pointer"
                    onClick={() =>
                      this.props.history.push(`/staff/course-categories`)
                    }
                  >
                    Course Categories
                  </li>
                  <li className="breadcrumb-item active">Courses</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="pt-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        {/* <div className="align-self-center tbl-head">
                          <i className="mdi mdi-view-dashboard mr-2" />
                          <span>COURSES</span>
                        </div> */}
                        <div>
                          <Link
                            to="/staff/course-categories"
                            className="btn btn-purple"
                          >
                            <i className="mdi mdi-chevron-left" />
                            <span className="hide-menu">Back</span>
                          </Link>
                        </div>
                        {this.state.currentUser.role === 2 && (
                          <div>
                            <Link
                              to={`/staff/course-categories/${this.state.courseCategoryId}/create`}
                              className="btn btn-purple"
                            >
                              <MDBIcon fas icon="plus" className="mr-2" />
                              <span className="hide-menu">Add New Course</span>
                            </Link>
                          </div>
                        )}
                      </div>
                      <hr />
                      <Row className="mt-4">
                        {contentCategories?.rows &&
                        contentCategories?.rows?.length !== 0 ? (
                          contentCategories?.rows?.map((item, index) => {
                            const { page } = this.state;
                            const start = (page - 1) * LIMIT;
                            const end = page * LIMIT;
                            if (index >= start && index < end) {
                              return (
                                <Col
                                  sm={4}
                                  md={4}
                                  lg={3}
                                  xl={2}
                                  key={`${new Date().getTime()}${
                                    item?.category
                                  }`}
                                  className="pb-3"
                                >
                                  <div className="resource-library-card rounded mb-4">
                                    <div className="card-top">
                                      <div className="card-image">
                                        <img
                                          src={
                                            item?.location
                                              ? item?.location
                                              : defaultUserImage
                                          }
                                          style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            width: "100%",
                                            height: "250px",
                                          }}
                                          alt="Card Image"
                                          onError={this.handleImageError}
                                        />
                                      </div>
                                    </div>
                                    <div className="resource-library-card-body">
                                      <div className="card-top">
                                        <div
                                          className="document-content"
                                          role="button"
                                          tabIndex="0"
                                        >
                                          <h4 className="text-truncate">
                                            {item.category}
                                          </h4>
                                          <p className="mb-2 course-description rounded pb-3 text-white">
                                            {item.description}
                                          </p>
                                        </div>
                                      </div>

                                      <div
                                        className="d-flex mb-3"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Link
                                          style={{ backgroundColor: "#00abff" }}
                                          to={`/staff/course-categories/${this.state.courseCategoryId}/category-detail/${item._id}`}
                                          className="btn btn-sm btn-purple"
                                        >
                                          <span className="hide-menu">
                                            View Files
                                          </span>
                                        </Link>
                                        <div>
                                          <Link
                                            to={`/staff/course-categories/${this.state.courseCategoryId}/edit/${item._id}`}
                                            data-toggle="tooltip"
                                            data-original-title="Edit"
                                          >
                                            <i className="fas fa-pencil-alt m-r-20 text-muted" />
                                          </Link>
                                          <a
                                            onClick={() => {
                                              this.setState({
                                                selectedDeleteItemId: item._id,
                                                deleteConfirmationModal: true,
                                              });
                                            }}
                                            data-toggle="tooltip"
                                            data-original-title="Delete"
                                          >
                                            <i className="fas fa-trash text-muted" />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              );
                            }

                            return "";
                          })
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center w-100 no-data">
                            <img src={notFound} alt="" />
                            <div className="text mt-3">No Courses Found</div>
                          </div>
                        )}
                      </Row>

                      {contentCategories?.rows &&
                      contentCategories?.rows?.length !== 0 ? (
                        <Pagination
                          totalCount={contentCategories?.rows?.length}
                          currentPage={this.state.page - 1}
                          handlePageClick={this.handlePageClick}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin />
        </div>

        <DeleteConfirmation2
          show={this.state.deleteConfirmationModal}
          id={this.state.selectedDeleteItemId}
          confirm={() => {
            this.deleteCategory(this.state.selectedDeleteItemId);
            this.setState({
              selectedDeleteItemId: "",
              deleteConfirmationModal: false,
            });
          }}
          cancel={() =>
            this.setState({
              selectedDeleteItemId: "",
              deleteConfirmationModal: false,
            })
          }
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentLibrary);
